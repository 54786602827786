import React from "react";
import { TableRow, TableCell, IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import TabTwoToneIcon from "@material-ui/icons/TabTwoTone";

import "../common/styles.scss";

const VideoRows = (props) => {
  const { title, description, type, mediaUrl } = props.document;
  const options = [" ", "SDGs", "TRANSFORMER TALKS", "E-BOOKS", "BLOGS"];
  return (
    <TableRow>
      <TableCell>
        {/*
          {title}{" "}
          <Tooltip title="Click to View">
            <IconButton className="del-btn">
              <VisibilityOutlinedIcon />
            </IconButton>
          </Tooltip>
        */}
        <Tooltip title="Click to View">
          <Typography>
            {title}{" "}
            <IconButton href={mediaUrl} target="blank">
              <TabTwoToneIcon />
            </IconButton>
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>{options[type]}</TableCell>
      <TableCell>{description}</TableCell>
      {props.canEdit && (
        <TableCell>
          <Tooltip title="Edit This Document">
            <IconButton className="del-btn" onClick={props.onEditHandler}>
              <EditIcon />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Delete This Document">
            <IconButton className="del-btn" onClick={props.onDeleteHandler}>
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default VideoRows;
