import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  LinearProgress,
  Stepper,
  Step,
  StepLabel,
  ButtonGroup,
  Chip,
  Input,
} from "@material-ui/core";
import Sidemenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import { withStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "@material-ui/core/Select";
import Organization from "../Organization/Organization";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createDonationRequest,
  updateDonationRequest,
  getUserData,
  getDonationRequest,
} from "../../utils/api";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddPhotos from "../common/AddPhotos";
import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { HOME_PAGE, ROLE_MEMBER, validateUrl } from "../../utils/utils";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Img from "react-cool-img";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
// import "../TALLeaders/TalLeaders.scss";
import styles from "./ScholarshipRequest.module.scss";
import "./ScholarshipRequest.scss";
import { faRupeeSign, faDollarSign } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },

  backButton: {
    marginRight: theme.spacing(1),
    padding: "14px 15px",
    width: "20%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none",
    padding: theme.spacing(2, 4, 3),
    paddingTop: 25,
    borderRadius: 4,
    width: "60%",
  },

  header: {
    padding: "15px 10px",
    background: "linear-gradient(325.68deg, #283e4a -51.95%, #466c81 67.26%)",
    borderRadius: " 4px",
  },
  fieldlength: {},
}));
const theme2 = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: 181,
        color: "#000",
        font: "400 12px Roboto",
        backgroundColor: "#f5f5f5",
        position: "relative",
        bottom: 100,
      },
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(course, eligibleCourse, theme) {
  return {
    fontWeight:
      eligibleCourse.indexOf(course) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function getSteps() {
  return ["Courses Offered", "Eligibility Criteria", "Almost Done"];
}
const ScholarshipRequest = (props) => {
  const { authUser, region, regions, setRegion, orgList } =
    useContext(appContext);
  const scholarshipTypes = [
    "School",
    "Under Grad",
    "Graduation",
    "Engineering",
    "Medicine",
    "Diploma",
    "B.Pharmacy",
    "LLB",
    "Integrated 5-Year Dual-Degree M.Tech",
    "3 Year Degree",
    "Masters",
    "MBA",
    "PhD",
    "MTech",
  ];
  const gradCoursesDetail = [
    "Bachelor of Architecture (B.Arch)",
    "Degree in Agriculture B.Sc.",
    "Degree in B.Pharma",
    "Degree in Dentistry (B.D.S.)",
    "Degree in Engineering (B.E./ B.Tech, 4-Year Degree in Engineering)",
    "Degree in Medicine (M.B.B.S.)",
    "Degree in Nursing B.Sc.",
    "Degree in Veterinary Medicine (B.V.Sc)",
    "Diploma in Engineering (Polytechnic/ 3-Year Diploma)",
    "Lateral Entry in B.Pharma",
    "Lateral Entry to Degree in Engineering (B.E./B.Tech)",
    "Lateral Entry to Degree in Medicine (M.B.B.S.)",
    "Others",
  ];
  const gradCourses = [
    "Engineering",
    "Medicine",
    "Diploma",
    "B.Pharmacy",
    "LLB",
    "Integrated 5-Year Dual-Degree M.Tech",
    "3 Year Degree ",
  ];
  const theme = useTheme();
  const initialState = {
    scholarshipName: "",
    cashUnit: "INR",
    scholarshipType: "",
    description: "",
    firstName: "",
    lastName: "",
    email: "",
    linkedInProfileUrl: "",
    fundsRecipient: "",
    highestEducation: "",
    gpa: "",
    pitchDeckVideoUrl: "",
  };
  const [closeSideMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [orgEdit, setOrgEdit] = useState(false);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const classes = useStyles();
  const phoneRef = useRef(phone);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [term, setTerm] = useState(false);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");
  const [availableScholarships, setAvailableScholarships] = useState(0);
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(0);
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [otherCourse, setOtherCourse] = useState("");
  const handleTerm = (event) => {
    if (event.target.checked) {
      setTerm(true);
    } else {
      setTerm(false);
    }
  };
  const [eligibleCourse, setEligibleCourse] = useState([]);
  const [scholarshipType, setScholarShipType] = useState("");
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [scholarshipDate, setScholarShipDate] = useState(null);
  const [id, setId] = useState("");
  const [targetImpact, setTargetImpact] = useState("");
  const [imageUpload, setImageUpload] = useState(false);
  const [toolTipStatus, setToolTipStatus] = useState({
    orgHelpIcon: false,
    topicHelpIcon: false,
    HostHelpIcon: false,
    DateHelpIcon: false,
    descripitionHelpIcon: false,
    locationHelpIcon: false,
    languageHelpIcon: false,
    coverphotoHelpIcon: false,
    linkedHelpIcon: false,
    FunctionHelpIcon: false,
    // skillsHelpIcon:false,
    // stipendHelpIcon:false,
    // educationHelpIcon:false,
    // AddPhotoHelpIcon:false,
  });
  const [isOrgMember, setIsOrgMember] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const handleClick = () => {
    setShowCalendar(true);
  };

  const handleClose = () => {
    setShowCalendar(false);
  };
  const changeTargetImpactHandler = (e, editor) => {
    setTargetImpact(editor.getData());
  };
  const changeEligibleCourse = (e) => {
    setEligibleCourse(e.target.value);
    if (!eligibleCourse.includes("Others")) {
      setOtherCourse("");
    }
  };
  const changeOtherCourse = (e) => {
    setOtherCourse(e.target.value);
  };
  const tooTipClose = () => {
    Object.keys(toolTipStatus).forEach((item) => {
      setToolTipStatus((previousValue) => ({
        ...previousValue,
        [item]: false,
      }));
    });
  };
  const handleTooltipClose = (event) => {
    let toolTipId = Object.keys(toolTipStatus).filter(
      (item) => item === event.target.id
    );
    if (!toolTipId.length > 0) {
      tooTipClose();
    }
  };
  const handleChangeScholarShipType = (event) => {
    setScholarShipType(event.target.value);
  };
  const handleTooltipOpen = (e) => {
    e.stopPropagation();
    Object.keys(toolTipStatus).forEach((item) => {
      if (item === e.currentTarget.id) {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: true,
        }));
      } else {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: false,
        }));
      }
    });
  };

  const handleInScholarship = () => {
    if (availableScholarships === "e") setAvailableScholarships(0);
    else setAvailableScholarships(availableScholarships + 1);
  };
  const handleDeScholarship = () => {
    if (availableScholarships === "e") setAvailableScholarships(0);
    if (availableScholarships <= 0) setAvailableScholarships(0);
    else
      setAvailableScholarships(parseInt(parseInt(availableScholarships) - 1));
  };
  const handleInMinAge = () => {
    if (minAge === "e") setMinAge(0);
    else setMinAge(minAge + 1);
  };
  const handleDeMinAge = () => {
    if (minAge === "e") setMinAge(0);
    if (minAge <= 0) setMinAge(0);
    else setMinAge(parseInt(parseInt(minAge) - 1));
  };
  const handleInMaxAge = () => {
    if (maxAge === "e") setMaxAge(0);
    else setMaxAge(maxAge + 1);
  };
  const handleDeMaxAge = () => {
    if (maxAge === "e") setMaxAge(0);
    if (maxAge <= 0) setMaxAge(0);
    else setMaxAge(parseInt(parseInt(maxAge) - 1));
  };

  const updateDefaultImageUrl = (url) => {
    setDefaultImageUrl(url);
  };

  const saveDonationRequest = (defaultImageUrl) => {
    const finalValues = {
      defaultImageUrl: defaultImageUrl,
      user_id: authUser && authUser.unique_id,
      pitchDeckVideoUrl: values.pitchDeckVideoUrl,
      status: 0,
    };
    if (
      authUser &&
      authUser.unique_id &&
      donationRequest &&
      donationRequest.user_info &&
      authUser.unique_id == donationRequest.user_info._id
    ) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    } else if (!editMode) {
      isOrgMember ? (finalValues.status = 3) : (finalValues.status = 0);
    }
    updateDonationRequest(finalValues, id)
      .then(() => {
        setImageUpload(true);
        toastr.success(
          "Default profile Image is set for this Scholarship request."
        );
        toastr.success(
          "Your Scholarship is successfully" +
            (props.match.params.id ? " updated" : " created ")
        );
        props.history.push(`/donationRequest/${name}`);
      })
      .catch((error) => {
        toastr.error(
          "Error while setting default profile image for the Scholarship request. " +
            error.message
        );
      });
  };

  const onCloseAddPhoto = () => {
    setShowingAddPhotoModal(false);
  };

  const handleChangeScholarShipDate = (date) => {
    const roundedDate = date && moment(date.getTime()).toDate();
    setScholarShipDate(roundedDate);
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };
  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };
  const createScholarshipRequest = (e) => {
    e.preventDefault();
    const errors = {};
    if (activeStep === 0) {
      const errors = {};
      const finalValues = {};
      if (!values.fundsRecipient) {
        errors.fundsRecipient = "Please Select or Add an Organization";
      }
      if (values.scholarshipName.length === 0) {
        errors.scholarshipName = "Please enter Scholarship Title";
      } else if (values.scholarshipName && values.scholarshipName.length > 50) {
        errors.scholarshipName =
          "Please enter Scholarship Title less than or equal to 50 characters";
      }
      if (scholarshipType.length === 0) {
        errors.scholarshipType = "Please select the Scholarship Type";
      }
      if (description.length === 0) {
        errors.description = "Please enter Description";
      }
      if (eligibleCourse.length === 0) {
        errors.eligibleCourses = "Please select the Eligible Courses";
      }
      if (eligibleCourse.includes("Others") && otherCourse.length === 0) {
        errors.eligibleCourses = "Please enter Eligible Course";
      }
      if (scholarshipDate === null) {
        errors.scholarshipDate = "Please select Scholarship Deadline";
      }
      if (minAmount.length === 0 || !minAmount) {
        errors.minAmount = "Please enter Minimum Amount";
      }
      if (minAmount <= 0) {
        errors.minAmount = "Please enter Minimum amount greater than 0";
      }
      if (maxAmount && maxAmount <= 0) {
        errors.maxAmount = "Please enter Maximum amount greater than 0";
      }
      if (
        Number(minAmount > 0) &&
        Number(maxAmount) > 0 &&
        Number(maxAmount) < Number(minAmount)
      ) {
        errors.minAmount =
          "Please enter Minimum Amount less than Maximum Amount";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      finalValues.request_type = "scholarship";
      finalValues.sourceOfCreation = "talscouts";
      finalValues.requested_for = scholarshipType;
      finalValues.title = values.scholarshipName;
      finalValues.units = values.cashUnit;
      finalValues.description = description;
      finalValues.user_id = authUser.unique_id;
      finalValues.skills =
        eligibleCourse.includes("Others") &&
        !eligibleCourse.includes(otherCourse)
          ? [...eligibleCourse, otherCourse]
          : eligibleCourse.includes(otherCourse)
          ? eligibleCourse
          : eligibleCourse;
      finalValues.size = availableScholarships;
      if (donationRequest.due_date === scholarshipDate) {
        finalValues.due_date = scholarshipDate;
      } else {
        finalValues.due_date = scholarshipDate.getTime();
      }
      finalValues.quantity = minAmount;
      finalValues.maxQuantity = maxAmount;
      finalValues.region = values && values.cashUnit === "INR" ? "IN" : "US";
      finalValues.city = address.city;
      finalValues.state = address.state;
      finalValues.country = address.country;
      finalValues.shipping_address = address;
      if (values.fundsRecipient && values.fundsRecipient !== "self") {
        finalValues.orgId = values.fundsRecipient;
      } else {
        finalValues.fundsRecipient = values.fundsRecipient;
        finalValues.orgId = null;
      }
      if (authUser && orgList && orgList.length > 0) {
        orgList.forEach((org) => {
          if (org && org.orgId && org.orgId._id == values.fundsRecipient) {
            if (org.role === ROLE_MEMBER) {
              setIsOrgMember(true);
            }
          }
        });
      }
      if (Object.keys(errors).length == 0) {
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Scholarship request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
              // setActiveStep(0);
            });
        }
      }
    } else if (activeStep === 1) {
      const errors = {};
      const finalValues = {};
      if (!minAge) {
        errors.minAge = "Please enter Minimum Age";
      }
      if (minAge && (Number(minAge) <= 0 || Number(minAge) > 50)) {
        errors.minAge = "Please enter Minimum age between 0 and 50";
      }
      if (!maxAge) {
        errors.maxAge = "Please enter Maximum age";
      }
      if (maxAge && (Number(maxAge) <= 0 || Number(maxAge) > 50)) {
        errors.maxAge = "Please enter Maximum age between 0 and  50";
      }
      if (Number(minAge) && Number(maxAge) && Number(maxAge) < Number(minAge)) {
        errors.maxAge = "Please enter valid Maximum Age and Minimum Age";
      }
      if (values.highestEducation.length === 0) {
        errors.highestEducation = "Please enter Highest Education";
      }
      if (targetImpact.length === 0) {
        errors.targetImpact = "Please enter who can apply";
      }
      setErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        toastr.error("Please enter the mandatory fields ");
        return;
      }
      const additionalInfo = {
        minAge: minAge,
        maxAge: maxAge,
        highestEducation: values.highestEducation,
        yearOfStudy: values.yearOfStudy,
        gpa: values.gpa,
        eligibilityCriteria: values.eligibilityCriteria,
      };
      finalValues.user_id = authUser.unique_id;
      finalValues.additionalInfo = additionalInfo;
      finalValues.targetImpact = targetImpact;
      if (Object.keys(errors).length == 0) {
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(
                "Error while setting values for the Scholarship request. " +
                  error.message
              );
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setActiveStep(activeStep + 1);
                setId(response.data._id);
                setName(response.data.name);
                setErrors({});
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
            });
        }
      }
    } else if (activeStep === 2) {
      if (values.pitchDeckVideoUrl.length === 0) {
        errors.pitchDeckVideoUrl = "Please enter Application Form Link";
      }
      if (
        values.pitchDeckVideoUrl.length > 0 &&
        !validateUrl(values.pitchDeckVideoUrl)
      ) {
        errors.pitchDeckVideoUrl = "Please enter valid URL";
      }
      if (!defaultImageUrl) {
        errors.image = "Please upload at least one image";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        return;
      } else {
        setErrors({});
        saveDonationRequest(defaultImageUrl);
      }
    }
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, handleNext);

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1);
  };
  const handleNext = () => {
    saveDonationRequest();
  };
  const handleOrganization = () => {
    setOpenOrgModal(true);
  };
  const onSavingOrg = () => {
    setOpenOrgModal(false);
    setRefresh(!refresh);
  };

  const onCloseModal = () => {
    setOpenOrgModal(false);
  };
  const [openOrgModal, setOpenOrgModal] = useState(false);
  function handleChangeScholarships(event) {
    const check = event.target.value;
    if (check === "e") setAvailableScholarships(0);
    setAvailableScholarships(parseInt(event.target.value));
  }
  function handleChangeMinAge(event) {
    const check = event.target.value;
    if (check === "e") setMinAge(0);
    setMinAge(parseInt(event.target.value));
  }
  function handleChangeMaxAge(event) {
    const check = event.target.value;
    if (check === "e") setMaxAge(0);
    setMaxAge(parseInt(event.target.value));
  }

  function handleChangeMinAmount(event) {
    const check = event.target.value;
    if (check === "e") setMinAmount(0);
    setMinAmount(parseInt(event.target.value));
  }
  function handleChangeMaxAmount(event) {
    const check = event.target.value;
    if (check === "e" || !Number(check)) setMaxAmount(0);
    setMaxAmount(parseInt(event.target.value));
  }
  const currencyChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region.currency));
    setValues((currentValues) => {
      return {
        ...currentValues,
        cashUnit: event.target.value,
      };
    });
  };
  const loadRequestData = () => {
    const newValues = {};
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          setDonationRequest(response);
          setId(response._id);
          setDefaultImageUrl(response.defaultImageUrl);
          newValues.fundsRecipient =
            response.orgId &&
            response.orgId._id &&
            response.fundsRecipient !== "self"
              ? response.orgId && response.orgId._id
              : "self";
          setName(response.name);
          newValues.scholarshipName = response.title;
          newValues.cashUnit = response.units;
          setScholarShipType(response.requested_for);
          setDescription(response.description);
          setEligibleCourse(
            response.skills.filter((x) => gradCoursesDetail.includes(x))
          );
          setOtherCourse(
            response.skills.filter((x) => !gradCoursesDetail.includes(x))[0]
          );
          setAvailableScholarships(response.size);
          setScholarShipDate(response.due_date);
          setActiveStep(0);
          setMinAmount(response.quantity);
          setMaxAmount(response.maxQuantity !== 0 && response.maxQuantity);
          setMinAge(
            response &&
              response.additionalInfo &&
              response.additionalInfo.minAge
              ? response.additionalInfo.minAge
              : 0
          );
          setMaxAge(
            response &&
              response.additionalInfo &&
              response.additionalInfo.maxAge
              ? response.additionalInfo.maxAge
              : 0
          );
          newValues.highestEducation =
            response &&
            response.additionalInfo &&
            response.additionalInfo.highestEducation
              ? response.additionalInfo.highestEducation
              : "";
          newValues.yearOfStudy =
            response &&
            response.additionalInfo &&
            response.additionalInfo.yearOfStudy;
          newValues.gpa =
            response && response.additionalInfo && response.additionalInfo.gpa;
          newValues.eligibilityCriteria =
            response &&
            response.additionalInfo &&
            response.additionalInfo.eligibilityCriteria;
          newValues.pitchDeckVideoUrl = response && response.pitchDeckVideoUrl;
          setTargetImpact(
            response && response.targetImpact ? response.targetImpact : ""
          );
          setImageUpload(true);
          ((response.orgId && response.orgId.user_info) ||
            response.fundsRecipient === "self") &&
            setOrgEdit(authUser.unique_id === response.user_info._id);
          setValues((currentValues) => {
            return {
              ...currentValues,
              ...newValues,
            };
          });
          response.shipping_address &&
            setAddress({
              line1: response.shipping_address.line1,
              line2: response.shipping_address.line2,
              city: response.shipping_address.city,
              state: response.shipping_address.state,
              zip_code: response.shipping_address.zip_code,
              country: response.shipping_address.country,
            });
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };
  useEffect(() => {
    if (authUser && authUser.unique_id) {
      getUserData(authUser && authUser.unique_id).then((response) => {
        const userData = response;
        setValues((currentValues) => {
          return {
            ...currentValues,
            firstName: userData.name.first_name,
            lastName: userData.name.last_name,
            email: userData.email,
            linkedInProfileUrl: userData.linkedInProfileUrl,
          };
        });
        setPhone(userData && userData.phone);
        setIsPhoneVerified(userData && userData.phone_verified);
        setAddress({
          country: userData && userData.address && userData.address.country,
          state: userData && userData.address && userData.address.state,
          city: userData && userData.address && userData.address.city,
        });
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (props.match.params.id) {
      setEditMode(true);
      setIsLoading(true);
      loadRequestData(props.match.params.id);
    }
  }, [props.match.params.id, authUser]);

  useEffect(() => {
    if (region && region.currency && !props.match.params.id) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          cashUnit: region.currency,
          cashQuantity: 0,
        };
      });
    }
  }, [region]);
  return (
    <div
      className="career-help-container"
      onClick={(event) => handleTooltipClose(event)}
    >
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              <div style={{ marginRight: "10px" }}>
                <Sidemenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            <Grid md={3} style={{ marginRight: "8px" }}>
              <Sidemenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/scholarship.png"
                    style={{
                      width: "100px",
                      height: "100px",
                      marginLeft: "10px",
                      borderRadius: "75%",
                      padding: "10px",
                      backgroundColor: "grey",
                    }}
                    alt="Scholarship"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Scholarship
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Bridging the Gap: Creating Access to Education with
                    Scholarships.
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid className="informatic-layout">
              <div>
                <Stepper
                  activeStep={activeStep}
                  style={{ background: "transparent" }}
                  alternativeLabel
                >
                  {getSteps().map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div className="field-length">
                  {activeStep === 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          component="h4"
                          style={{
                            fontWeight: "bold",
                            fontSize: "25px",
                            lineHeight: "37px",
                            color: "#000",
                            padding: "10px",
                            textAlign: "start",
                          }}
                          gutterBottom
                        >
                          Courses Offered
                        </Typography>
                      </Grid>
                      {(props.match.params.id ? orgEdit : true) && (
                        <Grid item xs={12}>
                          <FormControl
                            // style={{ width: "100%", padding: "10px 0px" }}
                            // className="recipient"
                            // error={errors && errors.fundsRecipient}
                            variant="outlined"
                            className={styles.blood_grp}
                          >
                            <InputLabel id="demo-simple-select-outlined-label">
                              Select/Add Organization
                              <span style={{ color: "red" }}> *</span>
                            </InputLabel>
                            <Select
                              style={{ width: "100%" }}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={values.fundsRecipient}
                              onChange={changeHandler}
                              placeholder="Organization"
                              name="fundsRecipient"
                              label="Select/Add Organization"
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-amount ${
                                  errors.fundsRecipient
                                    ? styles.bg_red
                                    : values.fundsRecipient
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                            >
                              <MenuItem value={"self"}>Self</MenuItem>
                              {orgList &&
                                orgList.length > 0 &&
                                orgList.map((org) => {
                                  return (
                                    <MenuItem
                                      value={org && org.orgId && org.orgId._id}
                                    >
                                      <img
                                        src={
                                          org &&
                                          org.orgId &&
                                          org.orgId.defaultImageUrl
                                            ? org.orgId.defaultImageUrl
                                            : "/images/orgLogo.png"
                                        }
                                        alt="orgLogo"
                                        width="20px"
                                        style={{ marginRight: 8 }}
                                      />{" "}
                                      {org && org.orgId && org.orgId.orgName}{" "}
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "100%",
                                          textAlign: "end",
                                        }}
                                      >
                                        {org &&
                                        org.orgId &&
                                        org.orgId.status === 1 ? (
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                          />
                                        ) : (
                                          <ErrorOutlineIcon
                                            style={{ color: "red" }}
                                          />
                                        )}
                                      </span>
                                    </MenuItem>
                                  );
                                })}
                              <MenuItem
                                value="add"
                                onClick={handleOrganization}
                              >
                                Add Organization
                              </MenuItem>
                            </Select>{" "}
                            <div className="custom-error">
                              {errors.fundsRecipient}
                            </div>
                          </FormControl>
                        </Grid>
                      )}
                      {editMode && !orgEdit && (
                        <Grid item xs={12} md={12}>
                          <InputLabel className="secFormLabel">
                            Recipient
                          </InputLabel>
                          <div className=" recipient-e ">
                            <Avatar
                              aria-label="recipe"
                              className={classes.avatar}
                            >
                              <Img
                                src={
                                  donationRequest &&
                                  donationRequest.orgId &&
                                  donationRequest.orgId._id
                                    ? donationRequest.orgId.defaultImageUrl
                                      ? donationRequest.orgId.defaultImageUrl
                                      : "/images/orgLogo.png"
                                    : donationRequest &&
                                      donationRequest.user_info &&
                                      donationRequest.user_info.image_url
                                    ? donationRequest.user_info.image_url
                                    : "/images/default-profile-photo1.png"
                                }
                                alt="user-profile"
                                className="user-profile-img"
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                                onClick={
                                  donationRequest && donationRequest.orgId
                                    ? () =>
                                        props.history.push(
                                          `/organization/${donationRequest.orgId._id}`
                                        )
                                    : () =>
                                        props.history.push(
                                          `/${donationRequest.user_info.username}`
                                        )
                                }
                              />
                            </Avatar>

                            <TextField
                              type="text"
                              variant="outlined"
                              className="profile-fields lnames recipient-bdr "
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              name="fundsRecipient"
                              disabled
                              value={values.fundsRecipient}
                            />
                          </div>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {/* <InputLabel
                          className="secFormLabel"
                          style={{ paddingLeft: "10px", paddingTop: "10px" }}
                        >
                          Scholarship Name/Title{" "}
                          <span style={{ color: "red" }}> *</span>
                        </InputLabel> */}
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingRight: "10px",
                            }}
                            error={errors.scholarshipName}
                          >
                            <TextField
                              label="Scholarship Name/Title *"
                              type="text"
                              variant="outlined"
                              style={{
                                width: "101%",
                                marginTop: "10px",
                              }}
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-title  ${
                                  errors.scholarshipName
                                    ? styles.bg_red
                                    : values.scholarshipName
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              name="scholarshipName"
                              value={values.scholarshipName}
                              onChange={changeHandler}
                            />
                            <Typography className="custom-error">
                              {errors.scholarshipName}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          // error={errors && errors.selectProfile}
                          variant="outlined"
                          className={styles.blood_grp}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Scholarship Type
                            <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={scholarshipType}
                            onChange={handleChangeScholarShipType}
                            name="scholarshipType"
                            label="Select ScholarshipType"
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors.scholarshipType
                                  ? styles.bg_red
                                  : values.scholarshipType
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          >
                            <MenuItem value={""}>
                              Select Scholarship Type
                            </MenuItem>
                            {scholarshipTypes.map((scholarshipType) => {
                              return (
                                <MenuItem value={scholarshipType}>
                                  {scholarshipType}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <Typography className="custom-error">
                            {errors.scholarshipType}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography
                            component="p"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15px",
                              color: "#000",
                              paddingLeft: "10px",
                              paddingTop: "20px",
                              textAlign: "start",
                            }}
                            gutterBottom
                          >
                            Description
                            <span style={{ color: "red" }}> *</span>
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              // paddingLeft: "10px",
                              // paddingRight: "10px",
                              paddingTop: "20px",
                            }}
                            error={errors && errors.description}
                          >
                            <Grid className="ck-editor-sc">
                              <CKEditor
                                style={{ padding: "10px 20px" }}
                                maxLength={300}
                                data={description}
                                name="description"
                                editor={ClassicEditor}
                                onChange={changeDescriptionHandler}
                                config={{
                                  toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "Link",
                                  ],
                                  placeholder: "Enter Description",
                                }}
                              />
                            </Grid>
                            <Typography className="custom-error">
                              {errors.description}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          className={styles.blood_grp}
                          variant="outlined"
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Eligible Courses
                            <span style={{ color: "red" }}> *</span>
                          </InputLabel>
                          <Select
                            style={{ padding: "9px 10px" }}
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            value={eligibleCourse}
                            onChange={changeEligibleCourse}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {selected.map((value) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    className={classes.chip}
                                  />
                                ))}
                              </div>
                            )}
                            MenuProps={MenuProps}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount  focus-disabled ${
                                errors.eligibleCourses
                                  ? styles.bg_red
                                  : values.eligibleCourses
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          >
                            {gradCoursesDetail.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, eligibleCourse, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ padding: "9px 10px" }}>
                        {eligibleCourse.includes("Others") && (
                          <TextField
                            label="Enter Eligible Courses *"
                            // fullWidth
                            variant="outlined"
                            style={{
                              // paddingTop: "30px",
                              // paddingLeft: "1px",
                              // fontStyle: "bold",
                              // fontWeight: "400",
                              // fontSize: "15px",
                              marginTop: "10px",
                              color: "#000000",
                              width: "100%",
                            }}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-title  ${
                                errors.eligibleCourses
                                  ? styles.bg_red
                                  : values.otherCourse
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            name="eligibleCourse"
                            // placeholder="Enter Eligible Courses"
                            value={otherCourse}
                            onChange={changeOtherCourse}
                          />
                        )}

                        <Typography className="custom-error">
                          {errors.eligibleCourses}
                        </Typography>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6} style={{ padding: "20px" }}>
                          <Box component="div" gutterBottom my={1}>
                            Available Scholarships
                          </Box>
                          <FormControl
                            style={{ width: "95%" }}
                            error={errors && errors.availableScholarships}
                          >
                            <ButtonGroup>
                              <Button
                                variant="outlined"
                                onClick={handleDeScholarship}
                                className="btn_vist"
                              >
                                -
                              </Button>
                              <TextField
                                style={{ width: "100%" }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                type="number"
                                name="availableScholarships"
                                onChange={handleChangeScholarships}
                                value={availableScholarships}
                              >
                                {availableScholarships}
                              </TextField>
                              <Button
                                variant="outlined"
                                onClick={handleInScholarship}
                                className="btn_vist"
                              >
                                +
                              </Button>
                            </ButtonGroup>
                            <FormHelperText>
                              {errors && errors.availableScholarships}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={12}
                          style={{ padding: "20px" }}
                        >
                          <Box
                            className="secFormLabel"
                            style={{
                              paddingTop: "10px",
                              marginBottom: "8px",
                            }}
                          >
                            Scholarships Deadline{" "}
                            <span style={{ color: "red" }}> *</span>
                          </Box>
                          <Grid item xs={12}>
                            <FormControl
                              style={{
                                width: "100%",

                                paddingRight: "10px",
                              }}
                              className="recipient"
                              error={errors && errors.date}
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  showTodayButton
                                  format="dd-MMM-yyyy"
                                  className={
                                    styles.plasma_input +
                                    " " +
                                    styles.date_b +
                                    `input-field dob ${
                                      errors.scholarshipDate
                                        ? styles.bg_red
                                        : scholarshipDate
                                        ? styles.bg_green
                                        : styles.bg_normal
                                    }`
                                  }
                                  variant="dialog"
                                  value={scholarshipDate}
                                  inputVariant="outlined"
                                  label="Select Date"
                                  placeholder="dd-MMM-yyyy"
                                  onChange={(newDate) => {
                                    handleChangeScholarShipDate(newDate);
                                  }}
                                  onClick={handleClick}
                                  open={showCalendar}
                                  onClose={handleClose}
                                  disablePast
                                />
                              </MuiPickersUtilsProvider>
                              <Typography className="custom-error">
                                {errors.scholarshipDate}
                              </Typography>
                            </FormControl>
                            {/* <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              open={toolTipStatus.DateHelpIcon}
                              title="Please pick a ScholarShip Deadline"
                              arrow
                              placement="right"
                              className="tooltip-text"
                            >
                              <HelpIcon
                                id="DateHelpIcon"
                                onClick={(e) => handleTooltipOpen(e)}
                              />
                            </Tooltip> */}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Box
                        component="div"
                        gutterBottom
                        my={2}
                        // fontWeight="bold"
                        style={{ paddingLeft: "15px" }}
                      >
                        Scholarship Amount
                      </Box>
                      <Grid>
                        <Grid container spacing={3} className="ship_amt">
                          <Grid item>
                            <FormControl
                              style={{
                                width: "100%",
                                paddingLeft: "15px",
                                paddingTop: "7px",
                              }}
                            >
                              <Select
                                variant="outlined"
                                style={{
                                  paddingBottom: "0px",
                                  height: "55px",
                                }}
                                name="cashUnit"
                                value={
                                  editMode
                                    ? values.cashUnit
                                    : region && region.currency
                                }
                                className="currency"
                                onChange={currencyChangeHandler}
                              >
                                {regions &&
                                  regions.length > 0 &&
                                  regions.map((region) => {
                                    return (
                                      <MenuItem value={region.currency}>
                                        {region.currency === "INR" ? (
                                          <FontAwesomeIcon
                                            color="#059585"
                                            size="2x"
                                            icon={faRupeeSign}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            color="#059585"
                                            size="2x"
                                            icon={faDollarSign}
                                          />
                                        )}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              style={{ width: "100%" }}
                              error={errors && errors.minAmount}
                            >
                              <TextField
                                type="number"
                                variant="outlined"
                                name="minAmount"
                                value={minAmount}
                                label="Min Amount"
                                onChange={handleChangeMinAmount}
                                className={
                                  styles.plasma_input +
                                  " " +
                                  `input-field request-amount ${
                                    errors && errors.minAmount
                                      ? styles.bg_red
                                      : values.minAmount
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                                required
                              />
                              <Typography className="custom-error">
                                {errors.minAmount}
                              </Typography>
                            </FormControl>
                          </Grid>
                          <Grid item xs={1} style={{ marginTop: "23px" }}>
                            <Typography>to</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl
                              style={{ width: "140%", marginLeft: "-11px" }}
                              error={errors && errors.maxAmount}
                            >
                              <TextField
                                type="number"
                                variant="outlined"
                                name="maxAmount"
                                value={maxAmount && Number(maxAmount)}
                                label="Max Amount"
                                onChange={handleChangeMaxAmount}
                                className={
                                  styles.plasma_input +
                                  " " +
                                  `input-field request-amount ${
                                    errors && errors.maxAmount
                                      ? styles.bg_red
                                      : maxAmount
                                      ? styles.bg_green
                                      : styles.bg_normal
                                  }`
                                }
                              />
                              <FormHelperText>
                                {errors.maxAmount}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box className="pod_buttons">
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "160px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => createScholarshipRequest(e)}
                          >
                            Save and continue
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "23px",
                          lineHeight: "37px",
                          color: "#000",

                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Eligibility Criteria
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel
                            className="secFormLabel"
                            style={{ paddingLeft: "10px", paddingTop: "10px" }}
                          >
                            Min Age <span style={{ color: "red" }}> *</span>
                          </InputLabel>

                          <FormControl
                            error={errors && errors.minAge}
                            style={{ width: "100%" }}
                          >
                            <ButtonGroup style={{ marginBottom: "5px" }}>
                              <Button
                                variant="outlined"
                                onClick={handleDeMinAge}
                                className="btn_vist"
                              >
                                -
                              </Button>
                              <TextField
                                style={{ width: "80%" }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                type="number"
                                name="minAge"
                                onChange={handleChangeMinAge}
                                value={minAge}
                              >
                                {minAge}
                              </TextField>
                              <Button
                                variant="outlined"
                                onClick={handleInMinAge}
                                className="btn_vist"
                              >
                                +
                              </Button>
                            </ButtonGroup>
                            <Typography className="custom-error">
                              {errors && errors.minAge}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel
                            className="secFormLabel"
                            style={{ paddingLeft: "10px", paddingTop: "10px" }}
                          >
                            Max Age <span style={{ color: "red" }}> *</span>
                          </InputLabel>

                          <FormControl
                            error={errors && errors.maxAge}
                            style={{ width: "90%" }}
                          >
                            <ButtonGroup style={{ marginBottom: "5px" }}>
                              <Button
                                variant="outlined"
                                onClick={handleDeMaxAge}
                                className="btn_vist"
                              >
                                -
                              </Button>
                              <TextField
                                style={{ width: "100%" }}
                                inputProps={{
                                  style: { textAlign: "center" },
                                }}
                                type="number"
                                name="maxAge"
                                onChange={handleChangeMaxAge}
                                value={maxAge}
                              >
                                {maxAge}
                              </TextField>
                              <Button
                                variant="outlined"
                                onClick={handleInMaxAge}
                                className="btn_vist"
                              >
                                +
                              </Button>
                            </ButtonGroup>
                            <Typography className="custom-error">
                              {errors && errors.maxAge}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingRight: "10px",
                            }}
                            error={errors && errors.highestEducation}
                          >
                            <TextField
                              label="Highest Education *"
                              type="text"
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              name="highestEducation"
                              value={values.highestEducation}
                              onChange={changeHandler}
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-title  ${
                                  errors.highestEducation
                                    ? styles.bg_red
                                    : values.highestEducation
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                            />
                            <Typography className="custom-error">
                              {errors.highestEducation}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ width: "106%" }}>
                        <Grid item xs={6} style={{ paddingTop: "20px" }}>
                          <TextField
                            type="text"
                            variant="outlined"
                            name="yearOfStudy"
                            label="Year of Study/ Grade Level"
                            value={values.yearOfStudy}
                            onChange={changeHandler}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors && errors.yearOfStudy
                                  ? styles.bg_red
                                  : values.yearOfStudy
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          />
                          {errors && errors.firstName && (
                            <Typography className="custom-error">
                              {errors.firstName}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={6} style={{ paddingTop: "20px" }}>
                          <TextField
                            type="text"
                            variant="outlined"
                            style={{ width: "90%" }}
                            name="gpa"
                            label="GPA/Grade"
                            value={values.gpa}
                            onChange={changeHandler}
                            className={
                              styles.plasma_input +
                              " " +
                              `input-field request-amount ${
                                errors && errors.gpa
                                  ? styles.bg_red
                                  : values.gpa
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                          />
                          {errors && errors.lastName && (
                            <Typography className="custom-error">
                              {errors.gpa}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          error={errors && errors.eligibleCriteria}
                        >
                          <TextField
                            type="text"
                            variant="outlined"
                            style={{
                              width: "100%",
                              marginTop: "10px",
                              marginBottom: "5px",
                            }}
                            label="Eligibility Criteria if any"
                            name="eligibilityCriteria"
                            value={values.eligibilityCriteria}
                            onChange={changeHandler}
                          />
                          <Typography className="custom-error">
                            {errors.eligibilityCriteria}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ alignItems: "center" }}>
                        <Typography
                          component="p"
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            fontSize: "15px",
                            color: "#000",
                            margin: "20px 0 10px 0",
                          }}
                          gutterBottom
                        >
                          Who can apply
                          <span style={{ color: "red" }}> *</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          style={{
                            width: "100%",
                            paddingRight: "10px",
                          }}
                          className="recipient"
                          error={errors && errors.targetImpact}
                        >
                          <Grid className="ck-editor-sc">
                            <CKEditor
                              style={{ padding: "10px 20px" }}
                              maxLength={300}
                              data={targetImpact}
                              name="targetImpact"
                              editor={ClassicEditor}
                              onChange={changeTargetImpactHandler}
                              config={{
                                toolbar: [
                                  "Heading",
                                  "bold",
                                  "italic",
                                  "bulletedList",
                                  "numberedList",
                                  "blockQuote",
                                  "Link",
                                ],
                                placeholder:
                                  "Please describe who can apply for this opportunity ",
                              }}
                            />
                          </Grid>
                          <Typography className="custom-error">
                            {errors.targetImpact}
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Box className="pod_buttons">
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "160px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            //disabled={activeStep === 1 && term ? false : true}
                            onClick={(e) => createScholarshipRequest(e)}
                          >
                            Save and continue
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "23px",
                          lineHeight: "37px",
                          color: "#000",

                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Almost Done
                      </Typography>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <FormControl
                            style={{
                              width: "100%",
                              paddingRight: "10px",
                            }}
                            error={errors && errors.pitchDeckVideoUrl}
                          >
                            <TextField
                              label="Application Form *"
                              className={
                                styles.plasma_input +
                                " " +
                                `input-field request-amount ${
                                  errors && errors.pitchDeckVideoUrl
                                    ? styles.bg_red
                                    : values.pitchDeckVideoUrl
                                    ? styles.bg_green
                                    : styles.bg_normal
                                }`
                              }
                              type="text"
                              variant="outlined"
                              style={{
                                width: "100%",
                                marginTop: "10px",
                              }}
                              name="pitchDeckVideoUrl"
                              value={values.pitchDeckVideoUrl}
                              onChange={changeHandler}
                            />
                            <Typography className="custom-error">
                              {errors.pitchDeckVideoUrl}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <div className="request-step-two">
                        <div className="request-two-step">
                          <h3 className="step-two-title">
                            Add a cover photo or video
                          </h3>
                        </div>
                        <p className="image-desc">
                          This is the image that will feature right on top of
                          your page. Make it impactful!
                        </p>
                        <div className="fund-img-txt">
                          <p> A picture is worth a thousand words</p>
                          <ul>
                            <li>
                              <span>Keep it relevant</span>
                            </li>
                            <li>
                              <span>Keep it clear</span>
                            </li>
                            <li>
                              <span>Types: .PNG, .JPG, .BMP, .MP4</span>
                            </li>
                            <li>
                              <span>Size: 700px X 400px</span>
                            </li>
                          </ul>
                        </div>
                        <MuiThemeProvider
                          theme={theme2}
                          className="req-tooltip-img"
                        >
                          <img
                            src={
                              defaultImageUrl
                                ? defaultImageUrl
                                : "/images/default-req-img.gif"
                            }
                            alt="default"
                            style={{ width: "100%" }}
                          />
                        </MuiThemeProvider>
                        {errors && errors.image && (
                          <Typography className="custom-error">
                            {errors.image}
                          </Typography>
                        )}
                        <div className="add-btn-fund">
                          <Button
                            style={{
                              font: "500 12px Roboto",
                              padding: "20px 0px",
                              margin: "10px 5px",
                              background: "transparent",
                              color: "#283E4A",
                              border: "1px solid #283E4A",
                            }}
                            onClick={() => setShowingAddPhotoModal(true)}
                          >
                            <FontAwesomeIcon
                              color="#283E4A"
                              size="1x"
                              style={{ marginRight: 6 }}
                              icon={faFileUpload}
                            />{" "}
                            Add Images
                          </Button>
                        </div>
                      </div>

                      <Box display="flex" justifyContent="flex-end">
                        <Box mr={2}>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => handleBack(e)}
                          >
                            Back
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            style={{
                              background: "rgb(243, 90, 87)",
                              margin: "30px",
                              float: "right",
                              color: "white",
                              marginRight: "10px",
                              width: "120px",
                            }}
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={createScholarshipRequest}
                          >
                            {editMode ? "Update" : "Submit"}
                          </Button>
                        </Box>
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={onCloseAddPhoto}
        uid={id}
        collection="DonationRequest"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
        multiple={true}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={onCloseModal}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(ScholarshipRequest);
