import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import SideMenu from "./SideMenu";
import Header from "./Header";
import { DescriptionOutlined } from "@material-ui/icons";
import AddDocument from "../common/AddDocument";
import toastr from "toastr";
import { createStudentBlog, getStudentBlog } from "../../utils/api";
import { appContext } from "../../App";
import Loader from "../common/Loader";
import firebase from "../../firebase/firebase";

const StudentBlog = () => {
  const { authUser } = useContext(appContext);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [term1, setTerm1] = useState(false);
  const [term2, setTerm2] = useState(false);
  const [errors, setErrors] = useState("");
  const [blogUrl, setBlogUrl] = useState("");
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleTerm1 = (event) => {
    if (event.target.checked) {
      setTerm1(true);
    } else {
      setTerm1(false);
    }
  };

  const handleTerm2 = (event) => {
    if (event.target.checked) {
      setTerm2(true);
    } else {
      setTerm2(false);
    }
  };

  const handleAddBlogModal = () => {
    setShowingAddDocumentModal(true);
  };

  const onSavingDocument = (message, url) => {
    setBlogUrl(url);
    toastr.success(message);
  };

  const submitBlog = () => {
    const errors = {};
    const finalValues = {};
    if (!blogUrl) {
      errors.document = "Please upload supporting blog which is mandatory.";
    }
    setErrors(errors);
    if (errors && Object.keys(errors).length > 0) {
      return;
    }
    const submitForm = window.confirm(
      "Are you sure you want to submit? Once submitted can't be edited."
    );
    if (submitForm) {
      finalValues.status = 1;
      finalValues.userId = authUser?.unique_id;
      finalValues.request_type = "student-blog";
      finalValues.sourceOfCreation = "talscouts";
      finalValues.documentUrl = blogUrl;
      // finalValues.eventId = "student-blog-sdg-no-poverty-jan-2024";
      createStudentBlog(finalValues)
        .then((response) => {
          setIsLoading(true);
          if (response?.statusCode === 200) {
            toastr.success("Blog submitted successfully");
            setAlreadySubmitted(true);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Error while submitting the idea. " + error.message);
        });
    }
  };

  useEffect(() => {
    if (authUser?.unique_id) {
      setIsLoading(true);
      // when user leaves and come backs to the same page,here we r setting the blogUrl by fetching the docs if user has already uploaded
      fetchDocuments(authUser?.unique_id);
      getStudentBlog(authUser?.unique_id)
        .then((response) => {
          if (response.length > 0) {
            if (response[0]?.status === 1) {
              setAlreadySubmitted(true);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Getting student blog failed", error.message);
        });
    }
  }, [authUser]);

  const fetchDocuments = (uid) => {
    return new Promise((resolve, reject) => {
      const path = `User/${uid}/` + "studentBlog";
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            setBlogUrl(url);
          });
        });
        resolve();
      });
    });
  };

  return (
    <div>
      <Header />
      <div className="desktop-menu">
        <SideMenu />
      </div>
      <Container maxWidth="xl" className="tal-container">
        <Box component="h3" mt={2} mb={2}>
          Student Blog on SDG -1 No Poverty
        </Box>
        <p>
          Write a blog on any facet of the <strong>SDG - 1 No Poverty</strong>,
          based on the outline provided below. The blog must be within the word
          limit of 250-300 words. If any statistics or statements are provided,
          cite them accordingly. The outline is as follows:
        </p>
        <Grid item xs={12}>
          <Typography
            className="donation-text"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Title of your Blog
          </Typography>
        </Grid>
        <p>Please add a self-descriptive title.</p>
        <Grid item xs={12}>
          <Typography
            className="donation-text"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Describe what social sectors you want to impact
          </Typography>
        </Grid>
        <p>
          What is the change you want to see in the respective social sectors,
          and in what way the underserved community can be impacted. For
          example, focus on any of the social sectors affected by or impacting
          poverty, such as farmers, daily wage labourers, unemployment,
          education, government etc.
        </p>
        <Grid item xs={12}>
          <Typography
            className="donation-text"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Describe your solution and how it creates a sustainable social
            impact
          </Typography>
        </Grid>
        <p>
          Please describe the solution you are proposing (scheme, idea or policy
          etc), how you think it can influence society to make the change, who
          you would like to be involved in the process of accelerating social
          change, how you personally plan to advocate for change - blogging,
          video, podcast, social media etc.
        </p>
        <Grid item xs={12}>
          <Typography
            className="donation-text"
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {alreadySubmitted
              ? "Uploaded blog"
              : "Please upload your blog in the PDF format"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            className="document-btn"
            onClick={handleAddBlogModal}
            style={{
              width: "100%",
              font: "500 12px Roboto",
              padding: "20px 5px",
              margin: "10px 5px",
              background: "transparent",
              color: "#283E4A",
              border: "1px solid #283E4A",
            }}
            component="span"
          >
            <DescriptionOutlined style={{ fontSize: 14, marginRight: 6 }} />{" "}
            {alreadySubmitted ? "View Uploaded Blog" : "Upload Blog Document"}
          </Button>
          {errors.document && (
            <FormHelperText style={{ color: "red" }}>
              {errors.document}
            </FormHelperText>
          )}
        </Grid>
        {!alreadySubmitted && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="term1"
                    checked={term1}
                    onChange={handleTerm1}
                    required="required"
                  />
                }
                label={
                  <div className="linktag">
                    <span> I Agree with the </span>
                    <a
                      href={`${process.env.REACT_APP_TALGIVING_URL}/users/Terms`}
                      target="blank"
                    >
                      {" "}
                      Terms &amp;conditions
                    </a>
                  </div>
                }
              />
              <br></br>
              <FormControlLabel
                control={
                  <Checkbox
                    name="term2"
                    checked={term2}
                    onChange={handleTerm2}
                    required="required"
                  />
                }
                label="I understand that this submission is final, no further changes will be considered for my idea submission."
              />
            </Grid>
            <Grid item xs={12} className="getfooter">
              <Button
                className="continues-btn"
                type="submit"
                disabled={term1 && term2 ? false : true}
                style={{
                  opacity: term1 && term2 ? 1 : 0.5,
                }}
                onClick={submitBlog}
              >
                Submit Blog(Final)
              </Button>
            </Grid>
          </>
        )}
      </Container>
      <AddDocument
        title={
          alreadySubmitted ? "Uploaded Blog Document" : "Add Blog Document"
        }
        isOpen={showingAddDocumentModal}
        onClose={() => setShowingAddDocumentModal(false)}
        uid={authUser?.unique_id}
        subCollection="studentBlog"
        collection="User"
        onSavingDocument={onSavingDocument}
        canEdit={alreadySubmitted ? false : true}
        multiple={false}
        singlePdfOnly={true}
        allowOnlyPdf={true}
      />
      <Loader isOpen={isLoading} onClose={() => setIsLoading(false)} />
    </div>
  );
};

export default StudentBlog;
