import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TurnedInRoundedIcon from "@material-ui/icons/TurnedInRounded";
import { appContext } from "../../App";
import AddIcon from "@material-ui/icons/Add";
import { numberWithCommas, AVATAR_DONEE, HOME_PAGE } from "../../utils/utils";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Box from "@material-ui/core/Box";
import "./Sidemenu.scss";
import { Tooltip } from "@material-ui/core";
import Organization from "../Organization/Organization";
import Contacts from "../Contacts/Contacts";
import ContactGroups from "../ContactGroups/ContactGroups";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    // borderRadius: 3,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
}));

const SideMenu = (props) => {
  const {
    authUser,
    userSummary,
    setAvatar,
    isSuperAdmin,
    isAdmin,
    isFinAdmin,
    isJudge,
    isTALRadioAdmin,
    isVolunteer,
    orgList,
    isTalradioAdmin,
    isMentor,
    contacts,
  } = useContext(appContext);
  const classes = useStyles();
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [radioMenu, setRadioMenu] = useState("");
  const [totalMyApplicationsCount, setTotalMyApplicationsCount] = useState(0);
  const handleEditProfile = () => {
    setRadioMenu("");
    props.history.push(`/newUserProfile/${authUser.unique_id}`);
  };

  const handleMyRequests = () => {
    setRadioMenu("");
    setAvatar(AVATAR_DONEE);
    props.history.push(HOME_PAGE);
  };
  const showFavourites = () => {
    setRadioMenu("");
    props.history.push("/myfavourites");
  };

  const handleOrgDetails = (orgData) => {
    setRadioMenu("");
    if (orgData.orgId) {
      props.history.push(`/organization/${orgData.orgId._id}`);
    }
  };

  const handleOrganization = () => {
    setRadioMenu("");
    setOpenOrgModal(true);
  };

  const CreateChatSession = () => {
    setRadioMenu("");
    props.history.push("/createAudioRoom");
  };

  const displayChatRooms = (id) => {
    setRadioMenu("");
    props.history.push("/chatTabs");
  };

  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    setRadioMenu("");
    props.history.push(`/organization/${orgId}`);
  };
  const handleTALRadioMenu = (id) => {
    setRadioMenu(id);
    props.history.push(`/talradioAdmin/manageTALRadio/tal-radio/${id}`);
  };

  const handleMyOrganizations = () => {
    setRadioMenu("");
    props.history.push("/myOrganizations");
  };

  const handleMyContactGroups = () => {
    setRadioMenu("");
    props.history.push("/myContactsandGroups");
  };
  const handleMyApplications = () => {
    setRadioMenu("");
    props.history.push("/myApplications");
  };

  useEffect(() => {
    if (userSummary) {
      let total = 0;
      userSummary &&
        userSummary.myApplications &&
        userSummary.myApplications.requestTypes &&
        userSummary.myApplications.requestTypes.forEach((requestType) => {
          if (
            [
              "scholarship",
              "internship",
              "volunteering",
              "career",
              "homework help",
            ].includes(requestType._id)
          ) {
            total += requestType.count;
          }
        });
      setTotalMyApplicationsCount(total);
    }
  }, [userSummary]);

  if (authUser && authUser.unique_id) {
    return (
      <div className="sidemenu-container tal_radio_menu">
        <div className="profile-block drop-box">
          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="user-details-cont">
              <div className="user-details">
                <p className="user-name">
                  <img
                    src={
                      authUser && authUser.profile_image_url !== ""
                        ? authUser.profile_image_url
                        : "/images/no-photo-available.png"
                    }
                    alt="user profile"
                    className="user-profile-image"
                  />
                  <p>
                    <span style={{ fontSize: "14px" }}>
                      Welcome, {authUser && authUser.name.first_name}
                    </span>
                    <span onClick={handleEditProfile}>Edit Profile</span>
                  </p>
                </p>
              </div>
            </div>
          </Paper>
          <hr className="hr-line" />
          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="personal-links-cont">
              <ul>
                <li style={{ fontWeight: 500 }}>
                  <span>Kindness Score</span>
                  <span style={{ color: "#000" }}>
                    {userSummary && numberWithCommas(userSummary.kindnessScore)}
                  </span>
                </li>
                <li onClick={handleMyRequests}>
                  <span>My Requests</span>
                  <span>
                    {userSummary &&
                      userSummary.donationRequests &&
                      userSummary.donationRequests.total}
                  </span>
                </li>
                <li onClick={showFavourites}>
                  <span>Favourites</span>
                  <span>
                    {userSummary &&
                      userSummary.favorites &&
                      userSummary.favorites.total}
                  </span>
                </li>
                <li onClick={handleMyOrganizations}>
                  <span>My Organizations</span>
                  <span>
                    <span>{orgList && orgList.length}</span>
                  </span>
                </li>
                <li onClick={handleMyContactGroups}>
                  <span> My Contacts and Groups</span>
                  <span>
                    <span>{contacts && contacts.length}</span>
                  </span>
                </li>
                <li onClick={handleMyApplications}>
                  <span> My Applications</span>
                  <span>
                    <span>{totalMyApplicationsCount}</span>
                  </span>
                </li>
              </ul>
            </div>
          </Paper>
          <hr className="hr-line" />
          <Paper className={classes.paper + " no-pad"} elevation={0}>
            <div className="saved-req-blk">
              <p
                className="saved-req-links"
                onClick={() => {
                  props.history.push(`/${authUser.username}`);
                }}
                style={{ cursor: "pointer" }}
              >
                View my public profile
              </p>
            </div>
          </Paper>
        </div>
        {/* {authUser && authUser.isPaidBootcamp && ( */}
        <div className="admin-block drop-box">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={"admin-nav-options"}
          >
            <ListItem
              button
              onClick={(e) => props.history.push(`/bootCamp`)}
              style={{ backgroundColor: "#f35a57" }}
            >
              <ListItemIcon>
                <TurnedInRoundedIcon />
              </ListItemIcon>
              <ListItemText
                style={{ color: "white" }}
                primary="Bootcamp Resources"
              />
            </ListItem>
          </List>
        </div>
        {/* )} */}
        {isJudge && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="TALTransformers Judge" />
              </div>
            </ListItem>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={"admin-nav-options"}
            >
              <ListItem
                button
                onClick={(e) => props.history.push(`/admin/submittedideas`)}
                style={{ backgroundColor: "#4dabf5" }}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "white" }}
                  primary="Judge ideas assigned to me"
                />
              </ListItem>
            </List>
          </div>
        )}
        {isMentor && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="TALTransformers Mentor" />
              </div>
            </ListItem>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={"admin-nav-options"}
            >
              <ListItem
                button
                onClick={(e) => props.history.push(`/admin/submittedideas`)}
                style={{ backgroundColor: "#4dabf5" }}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  style={{ color: "white" }}
                  primary="Mentor ideas assigned to me"
                />
              </ListItem>
            </List>
          </div>
        )}
        <div className="side-Organization admin-block drop-box">
          <div className="myOrganizations">
            <Box component="div" className="side-box">
              <Box component="p" fontWeight="500">
                Audio Rooms
              </Box>
              <Button className="drop-box">
                <Tooltip title="Create A New Audio Room">
                  <AddIcon onClick={CreateChatSession} />
                </Tooltip>
              </Button>
            </Box>

            <Box
              component="div"
              style={{ cursor: "pointer", padding: "5px 20px" }}
              onClick={() => displayChatRooms()}
            >
              View Ongoing and Future Rooms
            </Box>
          </div>
        </div>

        {(isSuperAdmin || isAdmin || isVolunteer) && (
          <div className="admin-block drop-box">
            <ListItem>
              <div style={{ display: "flex" }}>
                <ListItemText primary="Manage TALTransformers" />
              </div>
            </ListItem>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={"admin-nav-options"}
            >
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/submittedideas")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage Submitted Ideas" />
              </ListItem>
              {/* <ListItem
                button
                onClick={(e) => props.history.push("/admin/volunteers")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage TALScouts" />
              </ListItem> */}
              <ListItem
                button
                onClick={(e) => props.history.push("/admin/managelearninglabs")}
              >
                <ListItemIcon>
                  <TurnedInRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Manage LearningLabs" />
              </ListItem>
            </List>
          </div>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(SideMenu);
