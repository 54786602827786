import React, { useContext, useState, Fragment, useEffect } from "react";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import FormControl from "@material-ui/core/FormControl";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { signup } from "../../../utils/api";
import useFormValidation from "../../../hooks/useFormValidation";
import validateSignup from "../validateSignup";
import { appContext } from "../../../App";
import styles from "./Signup.module.scss";
import cuid from "cuid";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import firebase from "../../../firebase/firebase";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { InputLabel, MenuItem, Select, Typography } from "@material-ui/core";

import LogDesign from "../Logdesign";
import { getQueryStringValue } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  Paper: {
    minHeight: "100vh",
  },

  icons: {
    display: "flex",
    gridGap: "25px",
    justifyContent: "center",

    "& img": {
      cursor: "pointer",
    },
  },
  sign_right: {
    padding: "2rem",
    minHeight: "100vh",

    "& p:last-child": {
      textAlign: "left",
      "& strong": {
        cursor: "pointer",
      },
    },
    "& section": {
      padding: "2rem",

      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0 0",
      },

      "& hr": {
        position: "relative",
        top: "20px",
      },

      "& label": {
        display: "block",
        color: "#696F79",
        padding: "1rem 0",
      },

      "& h4": {
        fontWeight: "800",
        // textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        marginBottom: "20px",
        color: "#273d48",
        fontSize: "32px",
        textAlign: "center",
      },

      "& small": {
        color: "#BABABA",
        display: "block",
        position: "relative",
        backgroundColor: "white",
        padding: "0 5px 0 5px",
        left: "0",
        right: "0",
        top: "-4px",
        width: "220px",
        margin: "0 auto",
        fontSize: "14px",
      },

      "& FormControl": {
        display: "block",
      },
    },
  },
}));

const SignUp = (props) => {
  const classes = useStyles();

  const {
    userLocaleInfo,
    orgUserPayload,
    inviteGeneralUserPayload,
    setHideLoginButton,
    judgePayload,
    mentorPayload,
    teamUserPayload,
  } = useContext(appContext);

  const inviteUserPayload = orgUserPayload
    ? orgUserPayload
    : inviteGeneralUserPayload
    ? inviteGeneralUserPayload
    : judgePayload
    ? judgePayload
    : mentorPayload
    ? mentorPayload
    : teamUserPayload
    ? teamUserPayload
    : "";

  //default roles
  let defaultRoles = ["donor", "donee"];

  const initialState = {
    email:
      inviteUserPayload && inviteUserPayload.email
        ? inviteUserPayload.email
        : "",
    first_name:
      inviteUserPayload && inviteUserPayload.firstName
        ? inviteUserPayload.firstName
        : "",
    last_name:
      inviteUserPayload && inviteUserPayload.lastName
        ? inviteUserPayload.lastName
        : "",
    phone:
      inviteUserPayload && inviteUserPayload.phone
        ? inviteUserPayload.phone
        : "",
    roles:
      inviteUserPayload &&
      inviteUserPayload.role &&
      inviteUserPayload.role.length > 0
        ? inviteUserPayload.role
        : defaultRoles,
    institutionalRole: judgePayload && judgePayload.email ? "judge" : "student",
    password: "",
    referal: "",
    terms: false,
    isMentor: mentorPayload && mentorPayload.email ? true : false,
    sourceOfSignup:
      inviteUserPayload &&
      inviteUserPayload.role &&
      inviteUserPayload.role.length > 0 &&
      inviteUserPayload.role.includes("radiojockey")
        ? "talradio"
        : "talscouts",
    // registeredForEvent: "",
  };

  const [phone, setPhone] = useState(initialState.phone || null);
  const [showPassword, setShowPassword] = useState(true);
  const [otherErrors, setOtherErrors] = useState({});
  const [siteReferenceCode, setSiteReferenceCode] = useState(null);

  const signupHandler = () => {
    const finalValues = values;
    finalValues.websiteReferenceCode = siteReferenceCode;

    //validate the phone input
    if (!phone || !isPossiblePhoneNumber(phone)) {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "Please enter valid phone number",
        };
      });
      return;
    } else {
      finalValues.phone = phone;
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "",
        };
      });
    }

    signup(finalValues)
      .then((response) => {
        if (response) {
          props.history.push({
            pathname: `/otp-validation/${response.data.data.email}`,
            state: { password: finalValues.password },
          });
          firebase.analytics.logEvent("sign_up", { method: "email" });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          toastr.warning(
            "You already have an account with TALTransformers. Please login to continue."
          );
          props.history.push("/login");
        } else if (error.response && error.response.data) {
          toastr.error(error.response.data.message);
        }
      });
  };

  const googleLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/google/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=google",
      "_self"
    );
  };

  const facebookLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/facebook/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=facebook",
      "_self"
    );
  };

  const linkedInLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/linkedin/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=linkedin",
      "_self"
    );
  };

  const twitterLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/twitter/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        "&state=twitter",
      "_self"
    );
  };

  const showSigninPage = () => {
    props.history.push("/login");
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  useEffect(() => {
    setHideLoginButton(true);
    return () => {
      setHideLoginButton(false);
    };
  }, []);

  useEffect(() => {
    const localAuthUser = localStorage.getItem("authUser");
    if (localAuthUser) {
      localStorage.removeItem("authUser");
      localStorage.clear();
      window.location.reload();
    }
    const referenceCode = getQueryStringValue("refCode");
    setSiteReferenceCode(referenceCode);
  }, []);

  const wholeValidateSignup = () => {
    const errors = validateSignup(values);
    const otherErrors = {};

    if (!phone || !isPossiblePhoneNumber(phone)) {
      otherErrors.phone = "Please enter valid Phone Number";
    }
    setOtherErrors(otherErrors);
    return errors;
  };

  const { values, errors, changeHandler, submitHandler } = useFormValidation(
    initialState,
    wholeValidateSignup,
    signupHandler
  );

  var pswd = values.password;
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box component="main" mt={-4}>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <LogDesign />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Paper elevation={0}>
            <Box component="aside" className={classes.sign_right}>
              <Box component="section">
                <Box component="h4">Welcome to TALTransformers</Box>

                {/* {(!orgUserPayload || !inviteGeneralUserPayload) && (
                  <Fragment>
                    <Box component="div" className={classes.icons}>
                      <img
                        src="/images/google_icon.svg"
                        alt="google"
                        width="25px"
                        onClick={googleLoginHandler}
                      />
                      <img
                        src="/images/facebook_icon.svg"
                        alt="facebook"
                        width="13px"
                        onClick={facebookLoginHandler}
                      />
                      <img
                        src="/images/linkedin_icon.svg"
                        alt="linkedin"
                        width="25px"
                        onClick={linkedInLoginHandler}
                      />
                      <img
                        src="/images/twitter_icon.svg"
                        alt="twitter"
                        width="25px"
                        onClick={twitterLoginHandler}
                      />
                    </Box>
                  </Fragment>
                )}

                <hr />
                <Box component="small">or use your email for registration</Box> */}

                <Box component="article" my={2}>
                  <Box>
                    {/* <div className="flname"> */}

                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <div className="names">
                          {/* <Box component="label">First Name *</Box> */}

                          {(orgUserPayload && orgUserPayload.firstName) ||
                          (inviteGeneralUserPayload &&
                            inviteGeneralUserPayload.firstName) ? (
                            <TextField
                              className={`full-width border-radius signup-input ${
                                errors.first_name && "error-text"
                              }`}
                              fullWidth
                              placeholder="First Name *"
                              name="first_name"
                              variant="outlined"
                              onChange={changeHandler}
                              onKeyPress={(e) => handleEnterClick(e)}
                              value={values.first_name}
                              disabled
                            />
                          ) : (
                            <TextField
                              className={`full-width border-radius signup-input ${
                                errors.first_name && "error-text"
                              }`}
                              variant="outlined"
                              fullWidth
                              placeholder="First Name *"
                              name="first_name"
                              onChange={changeHandler}
                              onKeyPress={(e) => handleEnterClick(e)}
                              value={values.first_name}
                            />
                          )}
                          {errors && (
                            <div className="custom-error">
                              {errors.first_name}
                            </div>
                          )}
                        </div>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <div className="names">
                          {/* <Box component="label">Last Name *</Box> */}
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.last_name && "error-text"
                            }`}
                            variant="outlined"
                            fullWidth
                            placeholder="Last Name *"
                            name="last_name"
                            onChange={changeHandler}
                            value={values.last_name}
                            onKeyPress={(e) => handleEnterClick(e)}
                          />
                          {errors && errors.last_name && (
                            <div className="custom-error">
                              {errors.last_name}
                            </div>
                          )}
                        </div>
                      </Grid>

                      {/* </div> */}
                      <Grid item sm={6} xs={12}>
                        {/* <Box component="label">Phone Number *</Box> */}

                        <Box
                          component="div"
                          border="1px solid #ccc"
                          borderRadius="5px"
                          p={2}
                        >
                          <PhoneInput
                            variant="outlined"
                            fullWidth
                            placeholder=" Phone number *"
                            name=" Phone number "
                            defaultCountry={
                              userLocaleInfo && userLocaleInfo.country_code
                            }
                            value={phone}
                            onChange={setPhone}
                            onKeyPress={(e) => handleEnterClick(e)}
                          />
                        </Box>

                        {otherErrors && otherErrors.phone && (
                          <div className="custom-error">
                            {otherErrors.phone}
                          </div>
                        )}
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        {/* <Box component="label">Email address *</Box> */}

                        {(orgUserPayload && orgUserPayload.email) ||
                        (teamUserPayload && teamUserPayload.email) ||
                        (judgePayload && judgePayload.email) ||
                        (inviteGeneralUserPayload &&
                          inviteGeneralUserPayload.email) ? (
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.email && "error-text"
                            }`}
                            fullWidth
                            name="email"
                            variant="outlined"
                            onChange={changeHandler}
                            onKeyPress={(e) => handleEnterClick(e)}
                            value={values.email}
                            disabled
                          />
                        ) : (
                          <TextField
                            className={`full-width border-radius signup-input ${
                              errors.email && "error-text"
                            }`}
                            fullWidth
                            placeholder="Email address *"
                            name="email"
                            variant="outlined"
                            onChange={changeHandler}
                            onKeyPress={(e) => handleEnterClick(e)}
                            value={values.email}
                            autoComplete="new-password"
                          />
                        )}
                        {errors && (
                          <div className="custom-error">{errors.email}</div>
                        )}
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        {/* <Box component="label">Institution Name</Box> */}
                        <TextField
                          className={`full-width border-radius signup-input ${
                            errors.institutionName && "error-text"
                          }`}
                          variant="outlined"
                          fullWidth
                          placeholder="School / College Name * "
                          name="institutionName"
                          onChange={changeHandler}
                          value={values.institutionName}
                          onKeyPress={(e) => handleEnterClick(e)}
                        />
                        {errors && (
                          <div className="custom-error">
                            {errors.institutionName}
                          </div>
                        )}
                      </Grid>
                      {/* <Grid item sm={6} xs={12}>
                        <Box component="label">Institution Name</Box>
                        <TextField
                          className={`full-width border-radius signup-input ${
                            errors.studentId && "error-text"
                          }`}
                          variant="outlined"
                          fullWidth
                          placeholder="Student Id "
                          name="studentId"
                          onChange={changeHandler}
                          value={values.studentId}
                          onKeyPress={(e) => handleEnterClick(e)}
                        />
                      </Grid> */}
                      <Grid item md={6} sm={6} xs={12}>
                        {/* <Box component="label">Select Role</Box> */}

                        <FormControl style={{ width: "100%" }}>
                          <InputLabel
                            style={{ paddingLeft: "20px", marginTop: "-17px" }}
                          >
                            {" "}
                            Select Your Role
                          </InputLabel>
                          <Select
                            variant="outlined"
                            name="institutionalRole"
                            value={values.institutionalRole}
                            label="Select Your Role"
                            onChange={changeHandler}
                            disabled={judgePayload && judgePayload.email}
                          >
                            <MenuItem value="student">Student</MenuItem>
                            <MenuItem value="judge">Judge</MenuItem>
                            <MenuItem value="mentor">Mentor</MenuItem>
                            <MenuItem value="ventureCapitalist">
                              Venture Capitalist
                            </MenuItem>
                            <MenuItem value="club">Club</MenuItem>
                            <MenuItem value="volunteer">Volunteer</MenuItem>
                            <MenuItem value="other">Others</MenuItem>
                          </Select>{" "}
                        </FormControl>
                      </Grid>
                      {values?.institutionalRole === "student" && (
                        <Grid item md={12} sm={12} xs={12}>
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel
                              style={{
                                paddingLeft: "20px",
                                marginTop: "-17px",
                              }}
                            >
                              {" "}
                              Select Event
                            </InputLabel>
                            <Select
                              variant="outlined"
                              name="registeredForEvent"
                              value={values.registeredForEvent}
                              label="Select Event"
                              onChange={changeHandler}
                            >
                              <MenuItem value="Social Entrepreneurship Boot Camp">
                                Social Entrepreneurship Boot Camp
                              </MenuItem>
                              <MenuItem value="TALTransformers Global Youth Social Innovation Challenge 2024 - Climate Tech">
                                TALTransformers Global Youth Social Innovation
                                Challenge 2024 - Climate Tech
                              </MenuItem>
                              <MenuItem value="TALTransformers Global Youth Social Innovation Challenge on Health Tech">
                                TALTransformers Global Youth Social Innovation
                                Challenge on Health Tech
                              </MenuItem>
                              <MenuItem value="ATA-TALTransformers Entrepreneurship Pitch Fest 2024">
                                ATA-TALTransformers Entrepreneurship Pitch Fest
                                2024
                              </MenuItem>
                              <MenuItem value="TTA TALTransformers Youth Social Entrepreneurship Pitch Fest 2024">
                                TTA TALTransformers Youth Social
                                Entrepreneurship Pitch Fest 2024
                              </MenuItem>
                              <MenuItem value="TALTransformers Global Youth Social Innovation Challenge on Agri Tech">
                                TALTransformers Global Youth Social Innovation
                                Challenge on Agri Tech
                              </MenuItem>
                              {/* <MenuItem value="ISF TALTransformers 2024">
                              ISF TALTransformers 2024
                            </MenuItem> */}
                            </Select>
                          </FormControl>
                          <Typography>
                            {errors && (
                              <div className="custom-error">
                                {errors.registeredForEvent}
                              </div>
                            )}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item sm={6} xs={12}>
                        {/* <Box component="label">Institution Web site</Box> */}
                        <TextField
                          className={`full-width border-radius signup-input ${
                            errors.institutionUrl && "error-text"
                          }`}
                          variant="outlined"
                          fullWidth
                          placeholder="School / College Website "
                          name="institutionUrl"
                          onChange={changeHandler}
                          value={values.institutionUrl}
                          onKeyPress={(e) => handleEnterClick(e)}
                        />
                        <Typography>
                          {errors && (
                            <div className="custom-error">
                              {errors.institutionUrl}
                            </div>
                          )}
                        </Typography>
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <div className="flname">
                          <div className="pass-container">
                            <div className="names pass-field">
                              {/* <Box component="label">Password *</Box> */}

                              <Box component="div" position="relative">
                                <TextField
                                  className={`full-width border-radius signup-input ${
                                    errors.password && "error-text"
                                  }`}
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Password *"
                                  type={showPassword ? "password" : "text"}
                                  name="password"
                                  onChange={changeHandler}
                                  onKeyPress={(e) => handleEnterClick(e)}
                                  // onClick={showPasswordRules}
                                  value={values.password}
                                  autoComplete="new-password"
                                />
                                <InputAdornment
                                  fullWidth
                                  position="end"
                                  className="showpassword"
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    style={{
                                      top: "3px",
                                      position: "absolute",
                                      right: "10px",
                                    }}
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Tooltip title="Click to Show the Password">
                                        <VisibilityOff />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Click to hide the Password">
                                        <Visibility />
                                      </Tooltip>
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              </Box>
                            </div>

                            {(errors ||
                              !pswd.match(/[a-z]/) ||
                              !pswd.match(/[A-Z]+/) ||
                              !pswd.match(/\d/) ||
                              !pswd.match(/[^a-zA-Z0-9\-/]/) ||
                              pswd.length < 6) && (
                              <div className="custom-error">
                                {errors.password}
                              </div>
                            )}
                            {pswd &&
                              (!pswd.match(/[a-z]/) ||
                                !pswd.match(/[A-Z]+/) ||
                                !pswd.match(/\d/) ||
                                !pswd.match(/[^a-zA-Z0-9\-/]/) ||
                                pswd.length < 6) && (
                                <div className="password-strength">
                                  <ul className="password-error">
                                    <li>
                                      {pswd.match(/[a-z]/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one letter</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one letter</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li>
                                      {pswd.match(/[A-Z]+/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one capital letter</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one capital letter</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li>
                                      {pswd.match(/\d/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one digit</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one digit</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li>
                                      {pswd.match(/[^a-zA-Z0-9\-/]/) ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>one special character</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>one special character</strong>
                                        </Alert>
                                      )}
                                    </li>
                                    <li
                                      className={
                                        pswd.length >= 6 ? "valid" : "invalid"
                                      }
                                      style={{ "text-align": "start" }}
                                    >
                                      {pswd.length >= 6 ? (
                                        <Alert severity="success">
                                          Contains at least{" "}
                                          <strong>6 characters</strong>
                                        </Alert>
                                      ) : (
                                        <Alert severity="error">
                                          Contains at least{" "}
                                          <strong>6 characters</strong>
                                        </Alert>
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className={styles.signup_c}>
                  By clicking signup, I agree that I have read and accepted the{" "}
                  <a
                    href={`${process.env.REACT_APP_TALGIVING_URL}/users/Terms`}
                    target="blank"
                  >
                    Terms of use
                  </a>
                </Box>
                <Button
                  fullWidth
                  className="tal_primary_bg "
                  variant="contained"
                  type="submit"
                  onClick={submitHandler}
                >
                  Register Account
                </Button>
                <Box component="p" mt={4}>
                  {" "}
                  Already have an account?{" "}
                  <span onClick={showSigninPage}>
                    {" "}
                    <strong
                      className="tal_primary_color"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Login
                    </strong>
                  </span>
                </Box>
                <Box className={styles.power_tag}>Powered by Touch-A-Life</Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(SignUp);
