import React, { useState, useEffect, useContext, Fragment } from "react";
import moment from "moment";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  Typography,
  Link,
  Tooltip,
} from "@material-ui/core";
import { appContext } from "../../App";
import {
  DATE_FORMAT,
  DONATION_STATUS_COMPLETED,
  DONATION_STATUS_INITIATED,
  DONATION_STATUS_PENDING,
  getShortUrl,
} from "../../utils/utils";
import {
  getUserDonations,
  getTransactionDetails,
  getDonationRequest,
} from "../../utils/api";
import { formatNumber } from "../../utils/utils";
import SideMenu from "../SideMenu/Sidemenu";
import "./myDonations.scss";
import PrintReceiptModal from "./PrintReceiptModal";
import ShareOnSocialMedia from "../share/ShareOnSocialMedia";
import ShareIcon from "@material-ui/icons/Share";
import DonationRequest from "../donationRequest/DonationRequest";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "donatedDate", numeric: false, disablePadding: true, label: "Date" },
  {
    id: "donatedTo",
    numeric: false,
    disablePadding: false,
    label: "Donated To",
  },
  { id: "amount", numeric: true, disablePadding: false, label: "Amount" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "receipt", numeric: true, disablePadding: false, label: "Receipt" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#000", color: "#fff" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="table-header-text"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "600px",
    overflowX: "auto",
    left: "0",
  },
  container: {
    padding: "0 10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const MyDonations = () => {
  const { authUser } = useContext(appContext);
  const [donations, setDonations] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [isOpenPrintModal, setIsOpenPrintModal] = useState(false);
  const [row, setRow] = useState(null);
  const [donationRequestShareUrl, setDonationRequestShareUrl] = useState("");
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [donationRequestId, setDonationRequestId] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loadDonations = (userId) => {
    setIsLoading(true);
    getUserDonations(userId)
      .then((response) => {
        if (response && response instanceof Array && response.length > 0) {
          const detailsArray = [];
          response.map((details) => {
            const obj = {};
            obj.address = details.billingAddress;
            obj.isPrivate =
              details.donation_request_info &&
              details.donation_request_info.isPrivate;
            obj.dafaultImageUrl =
              details.donation_request_info &&
              details.donation_request_info.defaultImageUrl;

            if (details && details.donationFor === "tal") {
              obj.donationId = details._id;
              obj.donationFor = "TAL";
              obj.donatedTo = "TOUCH-A-LIFE";
              obj.units = details.units ? details.units.toUpperCase() : "";
              obj.amount = formatNumber(details.quantity);
              obj.donatedDate = details.createdAt;
              if (details.user_info && details.user_info.name) {
                obj.name = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
                obj.userAddress = details.user_info.address;
              }
              obj.status = details.status;
              obj.dafaultImageUrl =
                details.donation_request_info &&
                details.donation_request_info.defaultImageUrl;
              detailsArray.push(obj);
            } else if (details && details.donationFor === "donationRequest") {
              obj.donationId = details._id;
              obj.donationFor = "Donation Request";
              obj.donatedTo =
                details.donation_request_info &&
                details.donation_request_info.title;
              obj.reference =
                details.donation_request_info &&
                details.donation_request_info.name;
              obj.units = details.units ? details.units.toUpperCase() : "";
              obj.amount = formatNumber(details.quantity);
              obj.donatedDate = details.createdAt;
              if (details.user_info && details.user_info.name) {
                obj.name = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
                obj.userAddress = details.user_info.address;
              }
              obj.status = details.status;
              obj.dafaultImageUrl =
                details.donation_request_info &&
                details.donation_request_info.defaultImageUrl;
              detailsArray.push(obj);
            } else if (details && details.donationFor === "individual") {
              obj.donationId = details._id;
              obj.donationFor = "Individual";
              obj.donatedTo =
                details.toUserInfo && details.toUserInfo.name
                  ? `${details.toUserInfo.name.first_name} ${details.toUserInfo.name.last_name}`
                  : "Individual User";
              if (details.toUserInfo && details.toUserInfo.username) {
                obj.reference = details.toUserInfo.username;
              }
              obj.units = details.units ? details.units.toUpperCase() : "";
              obj.amount = formatNumber(details.quantity);
              obj.donatedDate = details.createdAt;
              if (details.user_info && details.user_info.name) {
                obj.name = `${details.user_info.name.first_name} ${details.user_info.name.last_name}`;
                obj.userAddress = details.user_info.address;
              }
              obj.status = details.status;
              obj.dafaultImageUrl =
                details.donation_request_info &&
                details.donation_request_info.defaultImageUrl;
              detailsArray.push(obj);
            }
            return null;
          });
          setDonations(detailsArray);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching donations:" + error.message);
        setIsLoading(false);
      });
  };

  const getStatusLabel = (status) => {
    if (status === DONATION_STATUS_INITIATED) {
      return "Initiated";
    } else if (status === DONATION_STATUS_PENDING) {
      return "Pending";
    } else if (status === DONATION_STATUS_COMPLETED) {
      return "Completed";
    }
  };

  useEffect(() => {
    if (authUser) {
      loadDonations(authUser.unique_id);
    }
  }, [authUser]);

  const handlePrintModal = (row) => {
    var donationQuery;
    if (row.donationFor === "Donation Request" || row.donationFor === "TAL") {
      donationQuery = "tal";
    } else {
      donationQuery = "individual";
    }
    getTransactionDetails(row.donationId, donationQuery)
      .then((response) => {
        if (response.data) {
          const transData = response.data;
          let donationFor = "";
          if (transData.donationData && transData.donationData.donationFor) {
            if (transData.donationData.donationFor === "tal") {
              donationFor = "TOUCH-A-LIFE FOUNDATION";
            } else if (
              transData.donationData.donationFor === "donationRequest"
            ) {
              donationFor = "Donation Request";
            } else if (transData.donationData.donationFor === "individual") {
              donationFor = "Individual";
            } else {
              donationFor = "Others";
            }
          }

          const details = { ...row };
          details.userMail = response.data.userId.email;
          details.phone = response.data.userId.phone;
          details.receiptId = response.data.transactionNumber;
          details.paymentId = response.data.transactionId;
          details.paymentMethod = response.data.paymentMethod;
          details.donationFor = donationFor;
          details.isPrivate = response.data.isPrivate;
          setRow(details);
          setIsOpenPrintModal(true);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleShareModal = (row) => {
    var donationQuery;
    let longUrl = "";
    let referenceId = "";
    if (row.donationFor === "Donation Request" || row.donationFor === "TAL") {
      donationQuery = "tal";
    } else {
      donationQuery = "individual";
    }
    getTransactionDetails(row.donationId, donationQuery)
      .then((response) => {
        if (response.data) {
          const transData = response.data;
          let donationFor = "";
          if (transData) {
            setDonationRequestId(
              transData.donationData &&
                transData.donationData.donation_request_info &&
                transData.donationData.donation_request_info._id
            );
            referenceId =
              transData.donationData &&
              transData.donationData.donation_request_info &&
              transData.donationData.donation_request_info._id;
          }
          if (transData.donationData && transData.donationData.donationFor) {
            if (transData.donationData.donationFor === "tal") {
              donationFor = "tal";
              longUrl = process.env.REACT_APP_BASE_URL;
            } else if (
              transData.donationData.donationFor === "donationRequest"
            ) {
              donationFor = "donationRequest";
              const donationRequestShareUrl =
                transData &&
                transData.donationData &&
                transData.donationData.donation_request_info &&
                `${process.env.REACT_APP_API_BASE_URL}/donationRequestShare/${transData.donationData.donation_request_info.name}?uniqueId=${transData.donationData.donation_request_info._id}&name=${transData.donationData.donation_request_info.name}&type=donationRequest`;
              longUrl = donationRequestShareUrl;
            } else if (transData.donationData.donationFor === "individual") {
              donationFor = "Individual";
              longUrl = `${process.env.REACT_APP_BASE_URL}/${
                transData && transData.userId && transData.userId.username
              }`;
              referenceId = transData.userId._id;
            } else {
              donationFor = "Others";
              longUrl = process.env.REACT_APP_BASE_URL;
            }
          }
          getShortUrl(longUrl, donationFor, referenceId)
            .then((shortUrl) => {
              setShortUrl(shortUrl);
              const details = { ...row };
              details.userMail = response.data.userId.email;
              details.phone = response.data.userId.phone;
              details.receiptId = response.data.transactionNumber;
              details.paymentId = response.data.transactionId;
              details.paymentMethod = response.data.paymentMethod;
              details.donationFor = donationFor;
              details.isPrivate = response.data.isPrivate;
              details.defaultImageUrl = response.data.defaultImageUrl;
              setRow(details);
              setIsOpenShareModal(true);
            })
            .catch((errorMessage) => {
              toastr.error(errorMessage);
            });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const loadDonationRequest = (donationRequestId) => {
    if (donationRequestId) {
      getDonationRequest(donationRequestId, authUser && authUser.unique_id)
        .then((response) => {
          setDefaultImageUrl(response.defaultImageUrl);
        })
        .catch((error) => {
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
          }
        });
    }
  };

  // Load DonationRequest Object
  useEffect(() => {
    loadDonationRequest(donationRequestId);
  }, [donationRequestId]);

  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  return (
    <div className="myDonations-container">
      <div className="barnav">
        <Container MaxWidth="lg">
          <div style={{ display: "flex" }}>
            <Button onClick={handleCloseSideMenu}>
              {!closeSideMenu && <MenuIcon />}
              {closeSideMenu && <ArrowBackIcon />}
            </Button>

            <h2
              style={{
                marginTop: "10px",
                lineHeight: "30px",
                color: "#ef6c00",
                fontSize: "18px",
              }}
            >
              My Donations
            </h2>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          )}

          <div className="table-container " style={{ width: "100%" }}>
            <TableContainer className={`${classes.container}`}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(
                    donations && donations,
                    getComparator(order, orderBy)
                  )
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          // onClick={event => handleClick(event, row.name)}
                          // role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left" padding="5px">
                            {moment(row.donatedDate).format(DATE_FORMAT)}
                          </TableCell>
                          <TableCell align="left">
                            {row.donationFor === "Individual" ? (
                              <Link href={`/${row.reference}`} target="blank">
                                {row.donatedTo}
                              </Link>
                            ) : row.donationFor === "Donation Request" ? (
                              <Link
                                href={`/donationRequest/${row.reference}`}
                                target="blank"
                              >
                                {row.donatedTo}
                              </Link>
                            ) : (
                              row.donatedTo
                            )}
                          </TableCell>
                          <TableCell align="left" left="0" right="0">
                            {row.units} {row.amount}
                          </TableCell>
                          <TableCell align="left">
                            {getStatusLabel(row.status)}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                              }}
                            >
                              <Tooltip title="Print the Receipt">
                                <Button onClick={(e) => handlePrintModal(row)}>
                                  <PrintIcon />
                                </Button>
                              </Tooltip>
                              {!row.isPrivate && (
                                <Fragment>
                                  <Tooltip title="Share Donation">
                                    <Button
                                      onClick={(e) => handleShareModal(row)}
                                    >
                                      <ShareIcon />
                                      &nbsp;&nbsp; Share
                                    </Button>
                                  </Tooltip>
                                </Fragment>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {isLoading && donations.length === 0 && (
                <Typography className="no-req-text">Loading...</Typography>
              )}
              {!isLoading && donations && donations.length === 0 && (
                <Typography className="no-req-text">
                  No Donations Found
                </Typography>
              )}
            </TableContainer>

            <PrintReceiptModal
              receiptData={row}
              isOpen={isOpenPrintModal}
              onClose={() => setIsOpenPrintModal(false)}
            />

            <ShareOnSocialMedia
              open={isOpenShareModal}
              handleClose={(e) => setIsOpenShareModal(false)}
              url={shortUrl}
              quote={`Hello, 
      I just contributed ${row && row.units} ${
                row && formatNumber(row.amount)
              } towards the donation request '${
                row && row.donatedTo
              }' using the TALGiving (https://talgiving.org) platform created by Touch-A-Life foundation (https://touchalife.org).
                          
Thanks,
Donation Request URL:
`}
              subject={`[Touch-A-Life] I just made a donation to "${
                row && row.donatedTo
              }"`}
              content={`
Hello,<br />I just contributed <b>${row && row.units} ${
                row && formatNumber(row.amount)
              }</b> towards the donation request <b>${
                row && row.donatedTo
              }</b> using the TALGiving  (<a href={'https://talgiving.org'}>TALGiving.org</a>)
  platform created by Touch-A-Life foundation (<a href={'https://www.touchalife.org/'}>TouchALife.org</a>) . </br> 
<a href="${shortUrl}">Click Here</a> to view the Donatation Request  </br>
<br />           <img width="400"
src= ${
                defaultImageUrl
                  ? defaultImageUrl
                  : "/images/default-donation-request.jpg"
              }
alt="donationrequest"
width="50%"
/> 
<br /><br /> Thanks, <br />${row && row.name}
`}
              redirectPage={"/myDonations"}
              shareURL={donationRequestShareUrl}
              representsWhat={"donationRequest"}
              referenceId={donationRequestId}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MyDonations;
