import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Grid,
  Dialog,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PlaceIcon from "@material-ui/icons/Place";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useFormValidation from "../../hooks/useFormValidation";
import { appContext } from "../../App";
import PhoneNumber from "../Phone/PhoneNumber";
import AddDocument from "../common/AddDocument";
import toastr from "toastr";
import { applyDonationRequest } from "../../utils/api";
import ShortAddressIsolated from "../common/ShortAddressIsolated";
import PhoneInput from "react-phone-number-input";
import styles from "../Scholarship/ScholarshipRequest.module.scss";
import { isValidPhoneNumber } from "react-phone-number-input";
import { DescriptionOutlined, LaunchOutlined } from "@material-ui/icons";

const ApplyPost = (props) => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    occupation: "",
    gender: "",
    audioRoom: "",
  };

  const [post, setPost] = useState({});
  const [phone, setPhone] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const phoneRef = useRef();
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const { authUser, setAuthUser, userLocaleInfo } = useContext(appContext);
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState);
  const [address, setAddress] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const finalValues = {};
  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const onSavingDocument = (message, url) => {
    setDocumentUrl(url);
    toastr.success(message);
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const handleSubmitData = () => {
    const errors = {};
    if (
      post.request_type === "internship" ||
      post.request_type === "volunteering" ||
      post.request_type === "scholarship"
    ) {
      if (values.firstName.length === 0) {
        errors.firstName = "Please enter First Name";
      }
      if (values.lastName.length === 0) {
        errors.lastName = "Please enter Last Name";
      }
      if (values.email.length === 0) {
        errors.email = "Please enter Email";
      }
      if (phoneRef.current && !phoneRef.current.isValid()) {
        return;
      }
      if (!phone) {
        errors.phone = "Please enter the phone number ";
      } else if (!isValidPhoneNumber(phone)) {
        errors.phone = "Please enter  valid phone number ";
      }
      if (
        post.request_type !== "scholarship" &&
        values.occupation.length === 0
      ) {
        errors.occupation = "Please enter Occupation";
      }

      if (
        tempAddress === null ||
        address === null ||
        tempAddress.city === null ||
        address.city === null ||
        tempAddress.state === null ||
        address.state === null ||
        tempAddress.country === null ||
        address.country === null
      ) {
        errors.address = "Please Select your Location";
      } else if (tempAddress || address) {
        if (
          tempAddress.city.length === 0 ||
          address.city.length === 0 ||
          address.state.length === 0 ||
          tempAddress.state.length === 0 ||
          address.country.length === 0 ||
          tempAddress.country.length === 0
        ) {
          errors.address = "Please Select your Location";
        }
      }
    } else if (post.request_type === "homework help") {
      if (values.firstName.length === 0) {
        errors.firstName = "Please enter First Name";
      }
      if (values.lastName.length === 0) {
        errors.lastName = "Please enter Last Name";
      }
      if (values.email.length === 0) {
        errors.email = "Please enter Email";
      }
      if (values.audioRoom.length === 0) {
        errors.audioRoom = "Please paste your audio room link";
      }
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    finalValues.donation_request_info = post._id;
    finalValues.user_info = post.user_info._id;
    const participantName = {
      first_name: values.firstName,
      last_name: values.lastName,
    };
    finalValues.participantName = participantName;
    finalValues.participantPhone = authUser.phone;
    finalValues.participantOccupation = values.occupation;
    finalValues.participantGender = values.gender;

    if (tempAddress) {
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
      finalValues.address = tempAddress;
      setAddress(tempAddress);
    } else {
      finalValues.city = address.city;
      finalValues.state = address.state;
      finalValues.country = address.country;
      finalValues.address = address;
      setAddress(address);
    }

    finalValues.participantResumeDownloadUrl = documentUrl;

    finalValues.participantEmailAddress = values.email;

    //finalValues.participantResumeDownloadUrl="test";
    finalValues.participantAudioRoomUrl = values.audioRoom;

    applyDonationRequest(finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (post.request_type === "internship") {
            toastr.success("Successfully applied for internship request");
          } else if (post.request_type === "volunteering") {
            toastr.success("Successfully applied for Volunteering request");
          } else if (post.request_type === "homework help") {
            toastr.success("Successfully applied for Homework help");
          } else if (post.request_type === "scholarship") {
            toastr.success("Successfully applied for Scholarship Request");
          }
          if (
            post.request_type === "scholarship" ||
            post.request_type === "internship" ||
            post.request_type === "volunteering" ||
            post.request_type === "homework help"
          ) {
            setActiveStep(activeStep + 1);
            props.setDonationRequest &&
              props.setDonationRequest({ ...post, isScholarshipApplied: true });
            props.setDonationRequests &&
              props.setDonationRequests([
                ...props.donationRequests.map((dr) => {
                  if (dr._id === post._id) {
                    return { ...post, isScholarshipApplied: true };
                  } else {
                    return dr;
                  }
                }),
              ]);
          }
          setErrors({});
          if (activeStep === 0 && post.request_type !== "scholarship") {
            props.onClose();
          }
        }
      })
      .catch((error) => {
        toastr.error(
          error.response && error.response.data && error.response.data.message
        );
      });
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      setPost(props.post);
      values.firstName = authUser.name.first_name;
      values.lastName = authUser.name.last_name;
      values.occupation = authUser.occupation;
      values.email = authUser.email;
      values.gender = authUser.gender;
      setPhone(authUser.phone);
      setPhoneVerified(authUser.phone_verified);
      setAddress(authUser.address);
    }
  }, [authUser]);

  useEffect(() => {
    finalValues.participantResumeDownloadUrl = documentUrl;
  }, [documentUrl]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      {activeStep === 0 &&
      post &&
      post.request_type &&
      post.request_type === "homework help" ? (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12}>
            <IconButton
              edge="start"
              color="inherit"
              style={{ float: "right" }}
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "20px",
                paddingLeft: "10px",
                fontWeight: "bold",
              }}
            >
              Invite To An Audio Room
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "20px",
                paddingLeft: "10px",
                fontSize: "12px",
                marginBottom: "15px",
              }}
            >
              Please provide the url of an audio room that you want the student
              to join. You can create an audio room from the home page if you
              haven't created one already.
            </Typography>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.firstName}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  value={values.firstName}
                  onChange={changeHandler}
                />
                <FormHelperText>{errors.firstName}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.firstName}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  value={values.lastName}
                  onChange={changeHandler}
                />
                <FormHelperText>{errors.lastName}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.email}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  value={values.email}
                  onChange={changeHandler}
                />
                <FormHelperText>{errors.email}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                style={{ width: "100%", padding: "10px" }}
                error={errors && errors.audioRoom}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  style={{
                    fontWeight: "bold",
                    fontWeight: "400",
                    fontSize: "20px",
                    color: "#000000",
                    width: "100%",
                  }}
                  name="audioRoom"
                  label="Audio Room URL"
                  placeholder="Paste the Audio Room URL here"
                  value={values.audioRoom}
                  onChange={changeHandler}
                />
                <FormHelperText>{errors.audioRoom}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Button
                style={{
                  background: "#f44336",
                  margin: "30px 10px 10px 0px",
                  float: "right",
                }}
                alignItems="center"
                className="submit-button"
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmitData}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ padding: "20px" }}>
          <Grid item xs={12}>
            <IconButton
              edge="start"
              color="inherit"
              style={{ float: "right" }}
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "15px",
                }}
              >
                {post.request_type === "scholarship"
                  ? post.title
                  : "Quickly Review and Apply"}
              </Typography>
              {post.request_type === "scholarship" &&
                !post.orgId &&
                post.shipping_address && (
                  <span className={styles.location_txt}>
                    <PlaceIcon />
                    <Typography>
                      {post.shipping_address.city +
                        "," +
                        post.shipping_address.country}
                    </Typography>
                  </span>
                )}
              {post.request_type === "scholarship" &&
                post.orgId &&
                post.orgId.orgAddress && (
                  <span className={styles.location_txt}>
                    <PlaceIcon />
                    <Typography>
                      {post.orgId.orgAddress.city +
                        "," +
                        post.orgId.orgAddress.country}
                    </Typography>
                  </span>
                )}
            </Grid>
            <hr />
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "500",
                marginBottom: "15px",
              }}
            >
              {post.request_type === "scholarship" && activeStep === 0
                ? "Personal Info"
                : "Submit Application Form"}
            </Typography>
            {post.request_type === "scholarship" && activeStep === 1 && (
              <>
                {" "}
                <Typography>
                  Fill Application Form on Organization Portal
                </Typography>
                <Box
                  component="span"
                  onClick={() => window.open(post.pitchDeckVideoUrl)}
                >
                  <Grid className={styles.fill_btn}>
                    <Grid>
                      <DescriptionOutlined />
                      <Typography>Fill Application form on portal</Typography>
                    </Grid>
                    <Grid>
                      <LaunchOutlined />
                    </Grid>
                  </Grid>
                  <Grid className={styles.app_txt_gu}>
                    <Typography>
                      Application Guidelines: Scholarship Application Process
                    </Typography>
                    <Typography>
                      Step 1 : Complete the application form effortlessly by
                      using the scholarship provider's provided link (given
                      above).
                    </Typography>
                    <Typography>
                      Step 2 : Keep track of your application status by
                      accessing the scholarship provider's website anytime.
                    </Typography>
                    <Typography>
                      Step 3 : All emails and further communications regarding
                      your application will be received from the scholarship
                      provider.
                    </Typography>
                    <Typography>
                      Step 4 : Please note that any information provided in the
                      application form is solely managed by the scholarship
                      provider.
                    </Typography>
                    <Typography>
                      Step 5 : TALGiving serves as a bridge between applicants
                      and providers, but we do not control the application
                      outcome or any associated procedures.
                    </Typography>
                  </Grid>
                </Box>
              </>
            )}
          </Grid>

          {activeStep === 0 ? (
            <>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    error={errors && errors.firstName}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        marginBottom: "5px",
                        fontWeight: "bold",
                        fontWeight: "400",
                        fontSize: "20px",
                        color: "#000000",
                        width: "100%",
                      }}
                      name="firstName"
                      label="First Name *"
                      placeholder="Enter First Name"
                      value={values.firstName}
                      onChange={changeHandler}
                    />
                    {errors && errors.firstName && (
                      <Typography className="custom-error">
                        {errors.firstName}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    error={errors && errors.lastName}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        marginBottom: "5px",
                        fontWeight: "bold",
                        fontWeight: "400",
                        fontSize: "20px",
                        color: "#000000",
                      }}
                      name="lastName"
                      label="Last Name *"
                      placeholder="Enter Last Name"
                      value={values.lastName}
                      onChange={changeHandler}
                    />
                    {errors && errors.lastName && (
                      <Typography className="custom-error">
                        {errors.lastName}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "10px" }}
                    error={errors && errors.email}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{
                        fontWeight: "bold",
                        fontWeight: "400",
                        fontSize: "20px",
                        color: "#000000",
                        width: "100%",
                      }}
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      value={values.email}
                      onChange={changeHandler}
                      disabled
                    />
                    {errors && errors.email && (
                      <Typography className="custom-error">
                        {errors.email}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12} className="phone-apply">
                  <FormControl style={{ width: "100%", padding: "10px" }}>
                    {/* <Box
                      style={{ marginBottom: "5px" }}
                      component="div"
                      border="1px solid #ccc"
                      borderRadius="5px"
                      p={2}
                    > */}
                    <PhoneNumber
                      phone={phone}
                      isPhoneVerified={phoneVerified}
                      ref={phoneRef}
                      setIsPhoneVerified={setPhoneVerified}
                    />
                    {/* <PhoneInput
                        variant="outlined"
                        fullWidth
                        placeholder=" Phone number *"
                        name=" Phone number "
                        label="phone"
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={
                          userLocaleInfo && userLocaleInfo.country_code
                        }
                        value={phone}
                        onChange={setPhone}
                        // onKeyPress={(e) => handleEnterClick(e)}
                      /> */}
                    {/* </Box> */}
                    {errors && errors.phone && (
                      <Typography className="custom-error">
                        {errors.phone}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              {post.request_type !== "scholarship" && (
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      fullWidth
                      style={{ padding: "7px 15px 7px 10px" }}
                      error={errors && errors.gender}
                    >
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        style={{
                          margin: "0px 20px",
                          background: "#fff",
                          zIndex: "3",
                        }}
                      >
                        Gender
                      </InputLabel>
                      <Select
                        value={values.gender}
                        onChange={changeHandler}
                        variant="outlined"
                        className="profile-fields gender"
                        style={{ width: "100%" }}
                        name="gender"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        required
                      >
                        <MenuItem value={" "}>Select Gender</MenuItem>
                        <MenuItem value={"m"}>Male</MenuItem>
                        <MenuItem value={"f"}>Female</MenuItem>
                        <MenuItem value={"o"}>Other</MenuItem>
                      </Select>

                      <FormHelperText>{errors.gender}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      style={{ padding: "10px", width: "100%" }}
                      error={errors && errors.occupation}
                    >
                      <TextField
                        style={{ marginBottom: "5px" }}
                        id="outlined-error-helper-text"
                        name="occupation"
                        label="Occupation"
                        onChange={changeHandler}
                        variant="outlined"
                        className="text-field"
                        value={values.occupation}
                      />

                      <Typography className="custom-error">
                        {errors.occupation}
                      </Typography>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    style={{ width: "100%", padding: "15px" }}
                    className=" recipient"
                    error={errors && errors.address}
                  >
                    <p
                      className="step-one-title"
                      style={{ fontWeight: "bold" }}
                    >
                      Select your location
                      <span style={{ color: "red" }}> *</span>
                    </p>
                    <ShortAddressIsolated
                      value={address}
                      onChange={handleChangeAddress}
                      error={errors && errors.address}
                    />
                    {errors && errors.address && (
                      <Typography
                        className={styles.custom_error}
                        style={{ marginTop: "6px" }}
                      >
                        {errors.address}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              {post.request_type !== "scholarship" && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        padding: "0 15px",
                      }}
                    >
                      Resume
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className="submit-btn"
                      onClick={() => setShowingAddDocumentModal(true)}
                      style={{
                        // width: "60%",
                        font: "500 12px Roboto",
                        // padding: "20px 30px",
                        margin: "0px 15px 10px",
                        background: " #fff",
                        color: "rgb(244, 67, 54)",
                        padding: "15px",
                        border: "1px solid   rgb(244, 67, 54)",
                      }}
                    >
                      <CloudUploadIcon
                        style={{
                          fontSize: 6,
                          marginRight: 4,
                          color: "rgb(244, 67, 54)",
                        }}
                      />{" "}
                      Upload file(s)
                    </Button>
                    <Typography variant="p">
                      DOC/DOCX/PDF/RTF | MAX :2MB
                    </Typography>
                    <Grid item xs={12}>
                      {documentUrl ? (
                        <Typography variant="p" style={{ marginLeft: "15px" }}>
                          Resume uploaded
                        </Typography>
                      ) : (
                        <Typography variant="p" style={{ marginLeft: "15px" }}>
                          No file uploaded
                        </Typography>
                      )}
                    </Grid>
                    <hr></hr>
                    <Typography
                      style={{ fontSize: "10px", marginLeft: "15px" }}
                    >
                      Recruiters give first preference to candidates who have a
                      resume
                    </Typography>
                    {errors && errors.documentUrl && (
                      <div className="custom-error">{errors.documentUrl}</div>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  style={{
                    background: "#f44336",
                    margin: "30px 0px 0px",
                    float: "right",
                  }}
                  alignItems="center"
                  className="submit-button"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmitData}
                >
                  {post.request_type !== "scholarship" ? "Apply" : "Continue"}
                </Button>
              </Grid>
              {post && (
                <AddDocument
                  title="Add Supporting Documents"
                  isOpen={showingAddDocumentModal}
                  onClose={onCloseAddDocument}
                  uid={authUser && authUser.unique_id}
                  collection={`/DonationRequest/${post._id}/Responses`}
                  onSavingDocument={onSavingDocument}
                  canEdit={true}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Dialog>
  );
};

export default ApplyPost;
