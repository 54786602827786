import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import OtpInput from "react-otp-input";
import { withRouter } from "react-router-dom";
import { validateOtp, resendOTP, login } from "../../../utils/api";
import { appContext } from "../../../App";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import "./OtpValidation.scss";

const OtpValidation = (props) => {
  const SECONDS_RESET_TIMER = 60;
  const { setAuthUser } = useContext(appContext);
  const [otpValues, setOtp] = useState("");
  const [error, setError] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(SECONDS_RESET_TIMER);

  const postOtpValues = (otp) => {
    var email = props.match.params.id;
    const password = props.location.state.password;
    const otpDetails = {};
    otpDetails.otp = otp;
    otpDetails.email = email;
    validateOtp(otpDetails)
      .then((response) => {
        if (response) {
          if (
            response.status === "success" &&
            response.message === "OTP verification has successfully completed"
          ) {
            toastr.success("User verified successfully.");
            // window.location.href = "https://www.taltransformers.org/";
            const logDetails = {};
            logDetails.account = email;
            logDetails.password = password;
            logDetails.editUsername = true;
            login(logDetails).then((response) => {
              if (response.data.data.account_verified === true) {
                setAuthUser(response.data.data);
                localStorage.setItem(
                  "authUser",
                  JSON.stringify(response.data.data)
                );
                localStorage.setItem(
                  "stripeCustomerId",
                  response.data.data.stripeCustomerId !== ""
                    ? response.data.data.stripeCustomerId
                    : "null"
                );
                const redirectUri = localStorage.getItem("redirectUri");
                if (redirectUri) {
                  localStorage.removeItem("redirectUri");
                  props.history.push(redirectUri);
                } else {
                  props.history.push("/editUsername", {
                    userName: logDetails.editUsername,
                  });
                }
              }
            });
          } else if (response.message === "Already verified") {
            setError("Already verified");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            toastr.error("Invalid OTP");
          }
        }
      });
  };

  useEffect(() => {
    setTimeout(function () {
      if (timer > 0) {
        setTimer((currentValue) => {
          return currentValue - 1;
        });
      }
      if (timer === 0) {
        setShowResendButton(true);
      }
    }, 1000);
  }, [timer]);

  const handleOtpCode = (e) => {
    setOtp(e);
    if (e.length === 6) {
      postOtpValues(e);
    }
  };

  const resendOtp = () => {
    const obj = {};
    obj.email = props.match.params.id;
    resendOTP(obj)
      .then((response) => {
        setTimer(SECONDS_RESET_TIMER);
        setShowResendButton(false);
        toastr.success(response.data.message);
      })
      .catch((error) => {
        toastr.error(error.response.message);
      });
  };

  return (
    <div className="password-reset-container">
      {/* {otpValues && otpValues.length === 6 ? postOtpValues() : renderFunction()} */}

      {/* <img src="/images/otp.svg" alt="otp mail" width="70px" /> */}

      <p>
        <MailOutlineIcon
          style={{ fontSize: "40px", color: "rgb(40, 62, 74)" }}
        />
      </p>
      <p>Verify Account</p>

      <p style={{ color: "red" }}>{error && error}</p>

      <p>
        Enter the OTP we just sent to your registered mail (If not received in
        inbox, please check spam folder as well)
      </p>

      <div className="otp-boxes">
        <OtpInput
          onChange={(e) => handleOtpCode(e)}
          numInputs={6}
          value={otpValues}
          separator={<span> &nbsp; &nbsp; </span>}
          shouldAutoFocus={true}
        />

        <div className="t-s">
          {/* <p>
            Remaining Time:{"  "}
            <span>{timer}</span>
          </p> */}
          {/* id="timer" */}

          <p>
            {showResendButton && showResendButton === true ? (
              <span style={{ cursor: "pointer" }} onClick={resendOtp}>
                Resend OTP
              </span>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(OtpValidation);
