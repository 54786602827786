import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
  Paper,
  MenuItem,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import AddDocument from "../common/AddDocument";
import FormHelperText from "@material-ui/core/FormHelperText";
import NewAddress from "../common/NewAddress";
import { appContext } from "../../App";
import Select from "@material-ui/core/Select";
import {
  createDonationRequest,
  getDonationRequest,
  getUserData,
  updateDonationRequest,
  updateProfile,
} from "../../utils/api";
import useFormValidation from "../../hooks/useFormValidation";
import "./HomeWork.scss";
import "react-phone-number-input/style.css";
import ShortAddress from "../common/ShortAddress";
import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import PhoneNumber from "../Phone/PhoneNumber";
import { Box } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  HOME_PAGE,
  STATUS_NEW,
  STATUS_APPROVED,
  STATUS_INCOMPLETE,
} from "../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import styles from "./HomeWorkRequest.module.scss";
const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    margin: "15px",
  },
}));
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Homework = (props) => {
  const initialState = {
    experience: "",
    subject: "",
    title: "",
    brief: "",
  };
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState);
  const {
    authUser,
    setAuthUser,
    region,
    isAdmin,
    isSuperAdmin,
    isVolunteer,
    userLocaleInfo,
  } = useContext(appContext);
  const [closeSideMenu] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [description, setDescription] = useState("");
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState(initialState.phone || null);
  const handleDueDateChange = (date) => {
    setDueDate(date);
  };
  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  const onSavingDocument = (message, url) => {
    setDocumentUrl(url);
    toastr.success(message);
  };

  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [id, setId] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [occupation, setOccupation] = useState("");
  const [linkedInUpdate, setLinkedInUpdate] = useState("");
  const [occupationUpdate, setOccupationUpdate] = useState("");
  const [otherErrors, setOtherErrors] = useState({});
  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const classes = useStyles();
  const phoneRef = useRef();
  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };
  const handleOccupation = (event) => {
    setOccupationUpdate(event.target.value);
  };
  const handleLinkedIn = (event) => {
    setLinkedInUpdate(event.target.value);
  };
  /*tooltip*/
  const [toolTipStatus, setToolTipStatus] = React.useState({
    occuptionHelpIcon: false,
    phoneHelpIcon: false,
    LinkedInHelpIcon: false,
    locationHelpIcon: false,
    topicHelpIcon: false,
    titleHelpIcon: false,
    descripitionHelpIcon: false,
    BriefHelpIcon: false,
    lastdateHelpIcon: false,
    preferredHelpIcon: false,
  });

  const tooTipClose = () => {
    Object.keys(toolTipStatus).forEach((item) => {
      setToolTipStatus((previousValue) => ({
        ...previousValue,
        [item]: false,
      }));
    });
  };

  const handleTooltipClose = (event) => {
    let toolTipId = Object.keys(toolTipStatus).filter(
      (item) => item === event.target.id
    );
    if (!toolTipId.length > 0) {
      tooTipClose();
    }
  };

  const handleTooltipOpen = (e) => {
    e.stopPropagation();
    Object.keys(toolTipStatus).forEach((item) => {
      if (item === e.currentTarget.id) {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: true,
        }));
      } else {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: false,
        }));
      }
    });
  };
  /*tooltip*/

  const handleSubmitData = () => {
    updateUserProfile();
    const finalValues = {};
    const errors = {};
    if (activeStep === 0) {
      if (description.length === 0) {
        errors.description = "Please enter Description";
      } else if (description && description.length <= 10) {
        errors.description = "Please enter atleast 10 characters";
      }

      if (
        occupation &&
        occupationUpdate &&
        occupation.length === 0 &&
        occupationUpdate.length === 0
      )
        errors.occupation = "Please enter your occupation";

      if (values.title.length === 0) {
        errors.title = "Please enter Title";
      }
      if (values.brief.length === 0) {
        errors.brief = "Please enter Brief";
      }
      if (values.subject.length === 0) {
        errors.subject = "Please enter why you need help";
      }
      if (
        tempAddress === null ||
        tempAddress.city === null ||
        tempAddress.state === null ||
        tempAddress.country === null
      ) {
        errors.address = "Please Select your Location";
      }
      if (dueDate === null) {
        errors.dueDate = "Please Select Due Date";
      }
    }
    if (activeStep === 1) {
      if (!documentUrl) {
        errors.documentUrl = "Please upload at least one document";
      }
    }
    setErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      finalValues.user_id = authUser.unique_id;
      finalValues.region = region._id;
      finalValues.request_type = "homework help";
      finalValues.creatorType = "student";
      finalValues.sourceOfCreation = "talscouts";
      //  finalValues.tags = ["Homework", "Student"];
      finalValues.requested_for = values.subject;
      finalValues.contactNumber = authUser.phone;
      finalValues.occupation = values.occupation;
      finalValues.linkedInProfile = values.linkedInProfile;
      finalValues.title = values.title;
      finalValues.brief = values.brief;
      finalValues.description = description;
      finalValues.city = tempAddress.city;
      finalValues.state = tempAddress.state;
      finalValues.country = tempAddress.country;
      finalValues.quantity = "0";
      let additionalInfo = {
        preferred_language: values.preferredLanguage,
        documentUrl: documentUrl,
      };
      finalValues.additionalInfo = additionalInfo;

      if (donationRequest.due_date === dueDate) {
        finalValues.due_date = dueDate;
      } else {
        finalValues.due_date = dueDate.getTime();
      }
      if (activeStep === 0) {
        if (id !== "") {
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
            })
            .catch((error) => {
              toastr.error(error.response.data.message);
            });
        } else {
          finalValues.status = -2;
          createDonationRequest(finalValues)
            .then((response) => {
              if (response.statusCode === 200) {
                setId(response.data._id);
                setName(response.data.name);
                setActiveStep(1);
                setErrors({});
                scrollToTop();
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 409) {
                setErrors((currentValues) => {
                  return {
                    ...currentValues,
                    title:
                      "This title is already taken. Please change the title and try again.",
                  };
                });
              } else {
                toastr.error(
                  error.response &&
                    error.response.data &&
                    error.response.data.message
                );
              }
              setActiveStep(0);
            });
        }
      }

      if (activeStep === 1) {
        additionalInfo = { ...additionalInfo, documentUrl: documentUrl };
        finalValues.additionalInfo = additionalInfo;
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
          return;
        }
        if (documentUrl) {
          finalValues.user_id = authUser && authUser.unique_id;
          if (
            authUser &&
            authUser.unique_id &&
            donationRequest &&
            donationRequest.user_info &&
            authUser.unique_id == donationRequest.user_info._id
          ) {
            finalValues.status = 0;
          } else if (!editMode) {
            finalValues.status = 0;
          }
          updateDonationRequest(finalValues, id)
            .then(() => {
              setActiveStep(activeStep + 1);
              setErrors({});
              toastr.success(
                "Your HomeWork Help Request is successfully" +
                  (props.match.params.id ? " updated" : " created ")
              );
              props.history.push(`/donationRequest/${name}`);
            })
            .catch((error) => {
              toastr.error(error.response.data.message);
            });
        }
      }
    }
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      loadUserData(authUser.unique_id);
    }
  }, [authUser]);

  useEffect(() => {
    if (props.match.params.id) {
      setEditMode(true);
      loadRequestData(props.match.params.id);
    }
  }, [props.match.params.id, authUser]);

  const loadRequestData = () => {
    setIsLoading(true);
    const newValues = {};
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          setDonationRequest(response);
          setId(response._id);
          setName(response.name);
          response.shipping_address &&
            setAddress({
              city: response.shipping_address.city,
              state: response.shipping_address.state,
              zip_code: response.shipping_address.zip_code,
              country: response.shipping_address.country,
            });
          newValues.subject = response.requested_for;
          newValues.title = response.title;
          setDescription(response.description);
          newValues.brief = response.brief;
          setDueDate(response.due_date);
          newValues.preferredLanguage =
            response.additionalInfo.preferred_language;
          setDocumentUrl(response.additionalInfo.documentUrl);
          setValues((currentValues) => {
            return {
              ...currentValues,
              ...newValues,
            };
          });
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };
  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setOccupation(userData.occupation);
        setOccupationUpdate(userData.occupation);
        setLinkedInProfile(userData.linkedInProfileUrl);
        setLinkedInUpdate(userData.linkedInProfileUrl);
        setPhone(userData && userData.phone);
      })
      .catch((error) => {
        // toastr.error(error.response.data.message);
      });
  };

  const updateUserProfile = () => {
    const finalValues = {};
    //validate the phone input
    if (!phone || !isPossiblePhoneNumber(phone)) {
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "Please enter valid phone number",
        };
      });
      return;
    } else {
      finalValues.phone = phone;
      setOtherErrors((currentValues) => {
        return {
          ...currentValues,
          phone: "",
        };
      });
    }
    finalValues.name = authUser.name;
    if (linkedInUpdate && values.linkedInProfile !== linkedInUpdate)
      finalValues.linkedInProfileUrl = linkedInUpdate;
    if (occupationUpdate && values.occupation !== occupationUpdate)
      finalValues.occupation = occupationUpdate;
    if (Object.keys(finalValues).length > 0) {
      updateProfile(authUser.unique_id, finalValues)
        .then((response) => {
          setAuthUser(response.data.data);
          if (authUser && authUser.unique_id) {
            localStorage.setItem(
              "authUser",
              JSON.stringify(response.data.data)
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &
                error.response.data.message &
                (error.response.data.errors.length > 0)
            ) {
              toastr.error(error.response.data.errors[0].msg);
            } else {
              toastr.error(error.response.data.message);
            }
          } else {
            toastr.error(error.response.data.message);
          }
        });
    }
  };

  return (
    <div
      className="career-help-container"
      onClick={(event) => handleTooltipClose(event)}
    >
      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <Grid md={3} style={{ marginRight: "8px" }}>
              <SideMenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src="/images/homework.png"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "20px",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Homework Help
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    Bridging the gap between students and teachers to make
                    better students
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid container>
              <div className={classes.field}>
                {activeStep === 0 && (
                  <Grid item md={12} xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "25px",
                          lineHeight: "37px",
                          color: "#000",
                          padding: "10px",
                          textAlign: "start",
                        }}
                      >
                        Personal Information
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="tooltip-css">
                      <FormControl style={{ padding: "15px", width: "100%" }}>
                        <PhoneNumber
                          phone={authUser && authUser.phone}
                          isPhoneVerified={authUser && authUser.phone_verified}
                          ref={phoneRef}
                        />
                        {/* <Box
                          component="div"
                          border="1px solid #ccc"
                          borderRadius="5px"
                          marginBottom="5px"
                          p={2}
                        >
                          <PhoneInput
                            variant="outlined"
                            fullWidth
                            placeholder=" Phone number *"
                            name=" Phone number "
                            defaultCountry={
                              userLocaleInfo && userLocaleInfo.country_code
                            }
                            value={phone}
                            onChange={setPhone}
                            onKeyPress={(e) => handleEnterClick(e)}
                          />
                        </Box> */}
                        {otherErrors && otherErrors.phone && (
                          <div className="custom-error">
                            {otherErrors.phone}
                          </div>
                        )}
                      </FormControl>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.phoneHelpIcon}
                        title="Please verify your mobile number"
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="phoneHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} className="tooltip-css">
                        <FormControl
                          style={{ padding: "15px", width: "100%" }}
                          error={errors && errors.occupation}
                        >
                          <TextField
                            id="outlined-error-helper-text"
                            label={"Occupation"}
                            value={occupationUpdate}
                            onChange={handleOccupation}
                            variant="outlined"
                            className="text-field"
                            name="occupation"
                          />
                          <FormHelperText>{errors.occupation}</FormHelperText>
                        </FormControl>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          open={toolTipStatus.occuptionHelpIcon}
                          title="Mention your current job title and position."
                          arrow
                          placement="right"
                          className="tooltip-text"
                        >
                          <HelpIcon
                            id="occuptionHelpIcon"
                            onClick={(e) => handleTooltipOpen(e)}
                          />
                        </Tooltip>
                      </Grid>
                      {/* <Grid item xs={6}>
                    <FormControl
                      style={{ padding: "15px", width: "100%" }}
                      error={errors && errors.experience}
                    >
                      <TextField
                        id="outlined-error-helper-text"
                        label="Experience"
                        onChange={changeHandler}
                        name="experience"
                        variant="outlined"
                        className="text-field"
                        values={values.experience}
                      />
                      <FormHelperText>{errors.experience}</FormHelperText>
                    </FormControl>
                  </Grid> */}
                    </Grid>

                    <Grid item xs={12} className="tooltip-css">
                      <FormControl
                        style={{ padding: "15px", width: "100%" }}
                        error={errors && errors.linkedIn}
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label={"LinkedIn profile"}
                          value={linkedInUpdate}
                          onChange={handleLinkedIn}
                          variant="outlined"
                          className="text-field"
                          name="linkedInProfile"
                        />
                        <FormHelperText>{errors.linkedIn}</FormHelperText>
                      </FormControl>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.LinkedInHelpIcon}
                        title="Add your LinkedIn or any other social profile"
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="LinkedInHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{ width: "100%", padding: "15px" }}
                        className=" recipient"
                        error={errors && errors.address}
                      >
                        <div className="tooltip-css">
                          <p style={{ marginLeft: "5px" }}>
                            Select your location
                          </p>
                          <Tooltip
                            style={{ marginBottom: "1rem" }}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.locationHelpIcon}
                            title="Select your current location"
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="locationHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </div>
                        <ShortAddress
                          value={address}
                          onChange={handleChangeAddress}
                          error={errors && errors.address}
                        />
                        {/* <FormHelperText>{errors.address}</FormHelperText> */}
                        <div className="custom-error">{errors.address}</div>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} className="tooltip-css">
                      <FormControl
                        style={{ padding: "0 15px 0", width: "100%" }}
                        error={errors && errors.subject}
                      >
                        <TextField
                          id="subject"
                          label="Enter Subject/Topic in which you needs help"
                          value={values.subject}
                          onChange={changeHandler}
                          variant="outlined"
                          className={
                            styles.plasma_input +
                            " " +
                            `input-field request-title ${
                              errors && errors.subject
                                ? styles.bg_red
                                : values.subject
                                ? styles.bg_green
                                : styles.bg_normal
                            }`
                          }
                          name="subject"
                          placeholder="Enter Subject/Topic in which you needs help"
                        />

                        {/* <FormHelperText>
                          {errors && errors.subject}
                        </FormHelperText> */}
                        <div className="custom-error">
                          {errors && errors.subject}
                        </div>
                      </FormControl>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.topicHelpIcon}
                        title="Mention the Subject / topic that you need assistance
                              "
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="topicHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} className="tooltip-css">
                      <FormControl
                        style={{ padding: "0 15px 0", width: "100%" }}
                        error={errors && errors.title}
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label="Give a title to the homework help you need"
                          value={values.title}
                          onChange={changeHandler}
                          variant="outlined"
                          className={
                            styles.plasma_input +
                            " " +
                            `input-field request-title ${
                              errors && errors.title
                                ? styles.bg_red
                                : values.title
                                ? styles.bg_green
                                : styles.bg_normal
                            }`
                          }
                          name="title"
                          placeholder="Give a title to the homework help you need"
                        />
                        {/* <FormHelperText>
                          {errors && errors.title}
                        </FormHelperText> */}
                        <div className="custom-error">
                          {errors && errors.title}
                        </div>
                      </FormControl>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.titleHelpIcon}
                        title=" Include a title that reflects the assistance you require."
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="titleHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} className="tooltip-css">
                      <Typography
                        component="p"
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#263238",
                          paddingLeft: "20px",
                          paddingTop: "10px",
                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Description for Homework Help
                      </Typography>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.descripitionHelpIcon}
                        title="Clearly state the issues or roadblocks you are encountering and the precise assistance you seek."
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="descripitionHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} className="tooltip-css">
                      <FormControl
                        style={{ width: "100%", padding: "15px" }}
                        className="recipient"
                        error={errors && errors.description}
                      >
                        <Grid className="ck-editor-css">
                          <CKEditor
                            style={{ padding: "10px 20px" }}
                            data={description}
                            name="description"
                            editor={ClassicEditor}
                            onChange={changeDescriptionHandler}
                            config={{
                              toolbar: [
                                "Heading",
                                "bold",
                                "italic",
                                "bulletedList",
                                "numberedList",
                                "blockQuote",
                                "Link",
                              ],
                              placeholder:
                                "Describe the homework help in detail",
                            }}
                          />
                        </Grid>
                        {errors && errors.description && (
                          // <FormHelperText>{errors.description}</FormHelperText>
                          <div className="custom-error">
                            {errors.description}
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="tooltip-css">
                      <Typography
                        component="p"
                        style={{
                          fontWeight: "500",
                          fontSize: "15px",
                          color: "#263238",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                          textAlign: "start",
                        }}
                        gutterBottom
                      >
                        Brief for Homework Help
                      </Typography>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.BriefHelpIcon}
                        title="Describe in brief the help you require in completing the homework
                              "
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="BriefHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        style={{ width: "100%", padding: "0 15px 0" }}
                        // className="recipient"
                        error={errors && errors.brief}
                        variant="outlined"
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label="Brief"
                          placeholder="Describe the homework help in brief"
                          value={values.brief}
                          className={
                            styles.plasma_input +
                            " " +
                            `input-field request-title ${
                              errors && errors.brief
                                ? styles.bg_red
                                : values.brief
                                ? styles.bg_green
                                : styles.bg_normal
                            }`
                          }
                          onChange={changeHandler}
                          variant="outlined"
                          name="brief"
                        />
                        {/* <FormHelperText>
                          {errors && errors.brief}
                        </FormHelperText> */}
                        <div className="custom-error">
                          {errors && errors.brief}
                        </div>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="tooltip-css">
                      <FormControl
                        style={{
                          width: "100%",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                        className="recipient"
                        error={errors && errors.dueDate}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            showTodayButton
                            format="dd-MMM-yyyy"
                            style={{ width: "100%" }}
                            margin="normal"
                            className={
                              styles.plasma_input +
                              " " +
                              styles.date_b +
                              " " +
                              `input-field ${
                                errors.dueDate
                                  ? styles.bg_red
                                  : dueDate
                                  ? styles.bg_green
                                  : styles.bg_normal
                              }`
                            }
                            inputVariant="outlined"
                            label="Due Date"
                            name="endDate"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            disablePast
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {/* <FormHelperText>
                          {errors && errors.dueDate}
                        </FormHelperText> */}
                        <div className="custom-error">
                          {errors && errors.dueDate}
                        </div>
                      </FormControl>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.lastdateHelpIcon}
                        title="Mention the last date for completing the assignment / Homework
                              "
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="lastdateHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} className="tooltip-css">
                      <FormControl
                        style={{ width: "100%", padding: "15px" }}
                        error={errors && errors.preferredLanguage}
                      >
                        <TextField
                          id="outlined-error-helper-text"
                          label="Preferred Language"
                          placeholder="Enter Preferred Language"
                          value={values.preferredLanguage}
                          onChange={changeHandler}
                          variant="outlined"
                          className="text-field text-file"
                          name="preferredLanguage"
                          focused
                        />
                        <FormHelperText>
                          {errors.preferredLanguage}
                        </FormHelperText>
                      </FormControl>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={toolTipStatus.preferredHelpIcon}
                        title="Include all the languages in which you are comfortable interacting.
                              "
                        arrow
                        placement="right"
                        className="tooltip-text"
                      >
                        <HelpIcon
                          id="preferredHelpIcon"
                          onClick={(e) => handleTooltipOpen(e)}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <>
                    <Grid item xs={12} style={{ padding: "0 10px" }}>
                      <Typography
                        component="h4"
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          lineHeight: "37px",
                          color: "#000",
                          padding: "0px 5px",
                          textAlign: "start",
                        }}
                      >
                        Upload any supporting documents that can help the
                        teacher in understanding your need better
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "0 10px" }}>
                      <Button
                        className="document-btn"
                        onClick={() => setShowingAddDocumentModal(true)}
                        style={{
                          // width: "60%",
                          font: "500 12px Roboto",
                          // padding: "20px 30px",
                          margin: "10px 5px",
                          background: " #fff",
                          color: " rgb(244, 67, 54)",
                          padding: "15px",
                          border: "1px solid   rgb(244, 67, 54)",
                        }}
                      >
                        <CloudUploadIcon
                          style={{ fontSize: 6, marginRight: 4 }}
                        />{" "}
                        Upload file(s)
                      </Button>
                      {documentUrl === "" ? (
                        <Typography variant="p" style={{ marginLeft: "15px" }}>
                          No file uploaded
                        </Typography>
                      ) : (
                        <Typography variant="p" style={{ marginLeft: "15px" }}>
                          Document uploaded
                        </Typography>
                      )}
                      {errors && errors.documentUrl && (
                        <div className="custom-error">{errors.documentUrl}</div>
                      )}
                    </Grid>
                  </>
                )}
                <Grid>
                  <Button
                    style={{
                      background: "#f44336",
                      margin: "30px 15px 30px 0",
                      float: "right",
                    }}
                    className="submit-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={handleSubmitData}
                  >
                    {activeStep === 0 ? (editMode ? "Update" : "Next") : "Save"}
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <div>
        <AddDocument
          title="Add Supporting Documents"
          isOpen={showingAddDocumentModal}
          onClose={onCloseAddDocument}
          uid={id}
          collection="DonationRequest"
          onSavingDocument={onSavingDocument}
          canEdit={true}
        />
      </div>
    </div>
  );
};

export default withRouter(Homework);
