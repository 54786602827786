import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { appContext } from "./App";
import toastr from "toastr";

function ProtectedRoute({ component: Component, ...rest }) {
  const authUser = localStorage.getItem("authUser");
  if (authUser && authUser.unique_id !== null) {
    return (
      <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    );
  } else {
    toastr.warning("Please signup or login to continue");
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
}
export default withRouter(ProtectedRoute);
