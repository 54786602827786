import React, { useState, useContext, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import moment from "moment";
import toastr from "toastr";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  Typography,
  TablePagination,
  Grid,
  CardHeader,
  Tooltip,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import Loader from "../common/Loader";
import { DATE_FORMAT, getObjectValuesInStringFormat } from "../../utils/utils";
import cssstyles from "./ScholarshipViewApplications.module.scss";
import Avatar from "@material-ui/core/Avatar";
import { LocationOn } from "@material-ui/icons";
import {
  getDonationRequest,
  getDonationRequestResponse,
} from "../../utils/api";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "participantName",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "participantEmailAddress",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "participantPhone",
    numeric: true,
    disablePadding: false,
    label: "Mobile Number",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#000", color: "#fff" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="table-header-text"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    "& td": {
      padding: "4px 16px",
    },
    "& td:nth-child(3)": {
      wordBreak: "break-word",
    },
    "& td:nth-child(6)": {
      "& div": {
        whiteSpace: "nowrap",
        // "& Button": {
        //   padding: "12px 12px 12px 0",
        // },
      },
    },
    "& td:nth-child(7)": {
      "& div": {
        whiteSpace: "nowrap",
        // "& Button": {
        //   padding: "12px 12px 12px 0",
        // },
      },
    },
  },
  container: {
    padding: "0 10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const ScholarshipViewApplications = (props) => {
  const { authUser, isAdmin, requestsResponses } = useContext(appContext);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [scholarshipRequests, setScholarshipRequests] = useState(null);
  const [donationRequest, setDonationRequest] = useState(null);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (props.match.params.id) {
      setIsLoading(true);
      loadRequestData(props.match.params.id);
      getDonationRequestResponse()
        .then((response) => {
          setScholarshipRequests(
            response.filter(
              (res) =>
                res &&
                res.donation_request_info &&
                res.donation_request_info._id === props.match.params.id
            )
          );
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toastr.error("Error getting donation requests:" + error.message);
        });
    }
  }, [props.match.params.id, authUser && authUser.unique_id]);

  const loadRequestData = () => {
    getDonationRequest(props.match.params.id, authUser && authUser.unique_id)
      .then((response) => {
        setDonationRequest(response);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.data && error.data.statusCode === 422) {
          toastr.error(error.data.message);
        }
      });
  };

  return (
    <div className="myDonations-container">
      <div className={cssstyles.bar_nav + " " + "barnav"}>
        <Container MaxWidth="lg">
          <div style={{ display: "flex" }}>
            {authUser && (
              <Button onClick={handleCloseSideMenu}>
                {!closeSideMenu && <MenuIcon />}
                {closeSideMenu && <ArrowBackIcon />}
              </Button>
            )}
            <h2
              style={{
                marginTop: "10px",
                lineHeight: "30px",
                color: "#f35a57",
                fontSize: "18px",
              }}
            >
              View Applicants
            </h2>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          )}

          <div style={{ position: "sticky", top: "0", width: "100%" }}>
            {donationRequest && (
              <>
                <Grid className={cssstyles.line_align}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ paddingRight: "10px" }}>
                      <ArrowBackIcon
                        className={cssstyles.point_details}
                        style={{ fontSize: "50px" }}
                        onClick={() => {
                          props.history.push(
                            `/donationRequest/${donationRequest._id}`
                          );
                        }}
                      />
                    </span>
                    <span className={cssstyles.point_details}>
                      <CardHeader
                        onClick={
                          donationRequest &&
                          donationRequest.orgId &&
                          donationRequest.orgId._id
                            ? () =>
                                props.history.push(
                                  `/organization/${donationRequest.orgId._id}`
                                )
                            : () =>
                                props.history.push(
                                  `/${donationRequest.user_info.username}`
                                )
                        }
                        style={{
                          pointerEvents: "auto",
                          padding: "16px 16px 16px 0",
                        }}
                        avatar={
                          <Avatar
                            aria-label="recipe"
                            className="avatar-container"
                          >
                            <figure>
                              <img
                                src={
                                  donationRequest &&
                                  donationRequest.orgId &&
                                  donationRequest.fundsRecipient !== "self"
                                    ? (donationRequest &&
                                        donationRequest.orgId &&
                                        donationRequest.orgId
                                          .defaultImageUrl) ||
                                      "/images/orgLogo.png"
                                    : (donationRequest &&
                                        donationRequest.user_info &&
                                        donationRequest.user_info.image_url) ||
                                      "/images/donor.png"
                                }
                                alt="user-logo"
                                style={{
                                  verticalAlign: "middle",
                                  width: "100%",
                                  height: "100%",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: 0,
                                  left: 0,
                                  top: 0,
                                  bottom: 0,
                                }}
                              />
                            </figure>
                          </Avatar>
                        }
                        title={
                          <span
                            style={{
                              cursor: "pointer",
                              fontWeight: "bold",
                              fontSize: "15px",
                            }}
                          >
                            <Tooltip title="View Organization Public Profile">
                              <Fragment>
                                {donationRequest &&
                                donationRequest.orgId &&
                                donationRequest.fundsRecipient !== "self" &&
                                donationRequest.orgId.orgName
                                  ? donationRequest.orgId.orgName
                                  : donationRequest &&
                                    donationRequest.user_info &&
                                    donationRequest.user_info.name &&
                                    donationRequest.user_info.name.first_name +
                                      " " +
                                      donationRequest.user_info.name.last_name}
                              </Fragment>
                            </Tooltip>
                          </span>
                        }
                        subheader={
                          <span>
                            <Typography component="span" className="location">
                              {donationRequest &&
                                donationRequest.orgId &&
                                donationRequest.fundsRecipient !== "self" &&
                                donationRequest.orgId.orgAddress && (
                                  <>
                                    <LocationOn
                                      style={{
                                        fontSize: 14,
                                      }}
                                    />
                                    {donationRequest &&
                                    donationRequest.orgId &&
                                    donationRequest.orgId.orgAddress &&
                                    donationRequest.orgId.orgAddress.city
                                      ? donationRequest.orgId.orgAddress.city
                                      : ""}
                                    {donationRequest &&
                                    donationRequest.orgId &&
                                    donationRequest.orgId.orgAddress &&
                                    donationRequest.orgId.orgAddress.country
                                      ? "  , " +
                                        donationRequest.orgId.orgAddress.country
                                      : ""}
                                  </>
                                )}
                              {donationRequest &&
                              !donationRequest.orgId &&
                              donationRequest.shipping_address &&
                              donationRequest.shipping_address.city ? (
                                <span>
                                  <LocationOn style={{ fontSize: 14 }} />
                                  {donationRequest.shipping_address.city}{" "}
                                  {donationRequest.shipping_address.country
                                    ? " , " +
                                      donationRequest.shipping_address.country
                                    : ""}
                                </span>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </span>
                        }
                      />
                    </span>
                  </div>
                  <div
                    className={cssstyles.point_details}
                    onClick={() => {
                      props.history.push(
                        `/donationRequest/${donationRequest._id}`
                      );
                    }}
                  >
                    <Typography className={cssstyles.para_line}>
                      {donationRequest.title}
                    </Typography>
                    <span>
                      {donationRequest &&
                        moment(donationRequest.createdAt).format(DATE_FORMAT)}
                    </span>
                    <span> | </span>
                    <span>
                      {donationRequest &&
                        donationRequest.createdAt &&
                        moment(donationRequest.createdAt)
                          .startOf("seconds")
                          .fromNow()}
                    </span>
                  </div>
                </Grid>
              </>
            )}
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {scholarshipRequests && scholarshipRequests.length > 0 && (
                  <TableBody>
                    {stableSort(
                      scholarshipRequests,
                      getComparator(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow>
                            <TableCell align="left">
                              {row.participantName.first_name +
                                " " +
                                row.participantName.last_name}
                            </TableCell>
                            <TableCell align="left">
                              {row.participantEmailAddress}
                            </TableCell>
                            <TableCell align="left">
                              {row.participantPhone}
                            </TableCell>
                            <TableCell align="left">
                              {row.address &&
                                getObjectValuesInStringFormat(row.address)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                )}
                {scholarshipRequests && scholarshipRequests.length === 0 && (
                  <TableCell colSpan={20}>
                    <Typography className="no-req-text">
                      No Applications Found
                    </Typography>
                  </TableCell>
                )}
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={scholarshipRequests && scholarshipRequests.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{
                  position: "sticky",
                  background: "white",
                  bottom: "0",
                  borderTop: "1px solid #eaeaea",
                }}
              />
            </TableContainer>
          </div>
        </div>
      </Container>
      <Loader isOpen={isLoading} onClose={() => setIsLoading(false)}></Loader>
    </div>
  );
};

export default ScholarshipViewApplications;
