import React, { useEffect, useState, useContext, useRef } from "react";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Tabs,
  Tab,
  LinearProgress,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import FormHelperText from "@material-ui/core/FormHelperText";
import NewAddress from "../common/NewAddress";
import { appContext } from "../../App";
import {
  createDonationRequest,
  getUserData,
  updateProfile,
  getDonationRequest,
  updateDonationRequest,
} from "../../utils/api";
import {
  HOME_PAGE,
  STATUS_NEW,
  STATUS_APPROVED,
  STATUS_INCOMPLETE,
} from "../../utils/utils";
import "./CareerHelp.scss";
import "react-phone-number-input/style.css";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import PhoneNumber from "../Phone/PhoneNumber";
import { Box } from "@material-ui/core";
import { KeyboardArrowRight } from "@material-ui/icons";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "var(--tal_primary)",
  },
}))(LinearProgress);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CareerHelp = (props) => {
  const {
    authUser,
    setAuthUser,
    region,
    isSuperAdmin,
    isAdmin,
    isVolunteer,
    userLocaleInfo,
  } = useContext(appContext);
  const [closeSideMenu] = useState(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [checked1, setChecked1] = React.useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [donationRequest, setDonationRequest] = useState("");
  const [id, setId] = useState("");

  const handleToggle = (value) => () => {
    const currentIndex = checked1.indexOf(value);
    const newChecked = [...checked1];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked1(newChecked);
  };

  const [address, setAddress] = useState(null);
  const [step, setStep] = useState(0);
  const headings = [
    "Personal Information",
    "Your Areas of Interest",
    "You Are Almost Done",
  ];
  const values = ["Mentor/Mentee", "College Bound", "Career Pivots"];
  const careerMenu = {
    "Mentor/Mentee": [
      "Resume preparation/review",
      "Interview preparation",
      "Mock Interviews",
      "Strategies for Job Application",
      "Choosing industry and career to pursue",
      "Identifying your brand",
      "Networking and references",
      "Others",
    ],
    "College Bound": [
      "Selecting types of colleges or vocational schools",
      "Career Paths",
      "Essay and/or Application Review",
      "Peer mentoring",
      "Others",
    ],
    "Career Pivots": [
      "Leadership styles",
      "Change Management",
      "Strategic Decision Making",
      "Expand span of influence",
    ],
  };

  const [checked, setChecked] = useState("");
  const [careerOptionsSelected, setCareerOptionsSelected] = useState({});
  const [name, setName] = useState("");
  const [optionsCount] = new useState({});
  useEffect(() => {
    setCareerOptionsSelected(careerOptionsSelected);
    updateCount();
  }, [careerOptionsSelected]);
  const [count, setCount] = useState(0);
  const [isFirst] = useState(true);

  const [optionsErr, setOptionsErr] = useState("");

  const updateCount = () => {
    let count1 = 0;
    for (let group in careerOptionsSelected) {
      optionsCount[group] = careerOptionsSelected[group].length;
      count1 += careerOptionsSelected[group].length;
    }
    if (count === 0 && !isFirst) {
      setOptionsErr("Please select at least one category");
    } else if (count > 0) {
      setOptionsErr("");
    }
    setCount(count1);
  };

  // function handleSelect(value) {
  //   setIsFirst(false);
  //   if (checked === value) {
  //     setChecked("");
  //   } else setChecked(value);
  // }

  // function handleAllSelect(event, value) {
  //   if (event.target.checked) {
  //     setCareerOptionsSelected({
  //       ...careerOptionsSelected,
  //       [value]: [...careerMenu[value]],
  //     });
  //   } else {
  //     setCareerOptionsSelected({
  //       ...careerOptionsSelected,
  //       [value]: [],
  //     });
  //   }
  // }

  const handleToggleSelection = (event, value, item) => {
    const currentIndex = careerOptionsSelected[value]
      ? careerOptionsSelected[value].indexOf(item)
      : -1;
    const newChecked = careerOptionsSelected[value]
      ? [...careerOptionsSelected[value]]
      : [];
    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCareerOptionsSelected({
      ...careerOptionsSelected,
      [value]: [...newChecked],
    });
  };
  // const handleMenuOptions = (event) => {
  //   if (
  //     event.target.value === "Mentor/Mentee" ||
  //     event.target.value === "College Bound" ||
  //     event.target.value === "Career Pivots" ||
  //     event.target.value === "Select Career Help Category"
  //   ) {
  //     setOpen(true);
  //   } else setOpen(!open);
  // };

  const [mentorMentee, setmentorMentee] = useState("");

  const handleMentorMentee = (name) => {
    setmentorMentee(name);
    setStep(step + 1);
  };

  const [occupation, setOccupation] = useState("");
  const [occupationUpdate, setOccupationUpdate] = useState("");
  const handleOccupation = (event) => {
    setOccupationUpdate(event.target.value);
  };
  const [linkedIn, setLinkedIn] = useState("");
  const [linkedInUpdate, setLinkedInUpdate] = useState("");
  const handleLinkedIn = (event) => {
    setLinkedInUpdate(event.target.value);
  };

  const [title, setTitle] = useState("");
  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  const [brief, setBrief] = useState("");
  const handleBrief = (event) => {
    setBrief(event.target.value);
  };
  const [description, setDescription] = useState("");
  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const [policy1, setPolicy1] = useState(true);
  const handlePolicy1 = (event) => {
    if (event.target.checked) {
      setPolicy1(true);
    } else {
      setPolicy1(false);
    }
  };
  const [policy2, setPolicy2] = useState(true);
  const handlePolicy2 = (event) => {
    if (event.target.checked) {
      setPolicy2(true);
    } else {
      setPolicy2(false);
    }
  };
  const [policy3, setPolicy3] = useState(true);
  const handlePolicy3 = (event) => {
    if (event.target.checked) {
      setPolicy3(true);
    } else {
      setPolicy3(false);
    }
  };
  /*tooltip*/
  const [toolTipStatus, setToolTipStatus] = React.useState({
    jobHelpIcon: false,
    phoneHelpIcon: false,
    LinkedInHelpIcon: false,
    locationHelpIcon: false,
    titleHelpIcon: false,
    brieflyHelpIcon: false,
    MentionHelpIcon: false,
    mentorHelpIcon: false,
    ChooseHelpIcon: false,
  });

  const tooTipClose = () => {
    Object.keys(toolTipStatus).forEach((item) => {
      setToolTipStatus((previousValue) => ({
        ...previousValue,
        [item]: false,
      }));
    });
  };

  const handleTooltipClose = (event) => {
    let toolTipId = Object.keys(toolTipStatus).filter(
      (item) => item === event.target.id
    );
    if (!toolTipId.length > 0) {
      tooTipClose();
    }
  };

  const handleTooltipOpen = (e) => {
    e.stopPropagation();
    Object.keys(toolTipStatus).forEach((item) => {
      if (item === e.currentTarget.id) {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: true,
        }));
      } else {
        setToolTipStatus((previousValue) => ({
          ...previousValue,
          [item]: false,
        }));
      }
    });
  };
  /*tooltip*/

  const { regions, setRegion } = useContext(appContext);
  const [tempAddress, setTempAddress] = useState(null);
  const [extraErrors, setExtraErrors] = useState({});
  const [phoneError] = useState("");
  const [phone, setPhone] = useState("");

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const phoneRef = useRef();
  const handleNext = () => {
    let errors = {};
    if (step === 0) {
      setStep(step + 1);
    } else if (step === 1) {
      if (!occupationUpdate || occupationUpdate.length === 0) {
        errors.occupation = "Please enter your occupation";
      }
      if (
        tempAddress &&
        tempAddress.line1 !== "" &&
        tempAddress.line2 !== "" &&
        tempAddress.city !== null &&
        tempAddress.state !== null &&
        tempAddress.country !== null &&
        tempAddress.zip_code !== ""
      ) {
        setAddress(tempAddress);
      } else {
        errors.address = "Please enter complete address";
      }
      if (!phone) {
        errors.phone = "Please enter a phone number ";
      } else if (!isValidPhoneNumber(phone)) {
        errors.phone = "Please enter a valid phone number ";
      }
      setExtraErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        return;
      } else {
        setExtraErrors({});
        setStep(step + 1);
      }
    } else if (step === 2) {
      if (count === 0) {
        setOptionsErr("Please select at least one category");
      } else if (count > 0) {
        setOptionsErr("");
      }
      if (count !== 0) {
        setStep(step + 1);
      }
    } else {
      if (title.length === 0) {
        errors.title = "Please enter title";
      }
      if (brief.length === 0) {
        errors.brief = "Please enter brief";
      }
      if (description.length === 0) {
        errors.description = "Please enter description";
      }
      setExtraErrors(errors);
      if (errors && Object.keys(errors).length > 0) {
        return;
      } else {
        handleSubmitData();
      }
    }
  };
  const handleSubmitData = () => {
    updateUserProfile();

    const finalValues = {};
    finalValues.user_id = authUser.unique_id;
    finalValues.region = region._id;
    finalValues.request_type = "career";
    finalValues.sourceOfCreation = "talscouts";
    finalValues.creatorType = mentorMentee;
    finalValues.phone = phone;
    finalValues.additionalInfo = careerOptionsSelected;
    finalValues.title = title;
    finalValues.brief = brief;
    finalValues.description = description;
    finalValues.quantity = 1;
    finalValues.line1 = address.line1;
    finalValues.line2 = address.line2;
    finalValues.zip_code = address.zip_code;
    finalValues.region = region._id;
    finalValues.city = tempAddress.city;
    finalValues.state = tempAddress.state;
    finalValues.country = tempAddress.country;
    if (id !== "") {
      if (
        authUser &&
        authUser.unique_id &&
        donationRequest &&
        donationRequest.user_info &&
        authUser.unique_id == donationRequest.user_info._id
      ) {
        finalValues.status = 0;
      } else if (!editMode) {
        finalValues.status = 0;
      }
      updateDonationRequest(finalValues, id)
        .then(() => {
          toastr.success(
            "Your Career Help Request is successfully" +
              (props.match.params.id ? " updated" : " created ")
          );
          props.history.push(`/donationRequest/${name}`);
        })
        .catch((error) => {
          toastr.error(
            "Error while setting values for the Career Help request. " +
              error.message
          );
        });
    } else {
      createDonationRequest(finalValues)
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (mentorMentee === "mentor") {
              toastr.success(
                "Thank you for showing your interest in becoming a mentor. You will receive email confirmation. Best wishes."
              );
            } else if (mentorMentee === "mentee") {
              toastr.success(
                "Thank you for showing your interest in getting assistance from our mentors. You will receive email confirmation. Best wishes."
              );
            }
            //Redirect to details page
            props.history.push(`/donationRequest/${resp.data.name}`);
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    if (authUser && authUser.unique_id) {
      loadUserData(authUser.unique_id);
    }
    if (props.match.params.id) {
      loadRequestData(props.match.params.id);
    }
  }, [authUser, props.match.params.id, editMode]);

  const loadUserData = (userId) => {
    getUserData(userId)
      .then((response) => {
        const userData = response;
        setOccupation(userData.occupation);
        setOccupationUpdate(userData.occupation);
        setLinkedIn(userData.linkedInProfileUrl);
        setLinkedInUpdate(userData.linkedInProfileUrl);
        setPhone(userData.phone);
        if (userData.address) {
          //DO NOT DELETE
          // if (
          //   userData.address.hasOwnProperty("country") &&
          //   userData.address.country === "India"
          // ) {
          //   setRegion(regions.find((region) => region.name === "India"));
          // } else {
          //   setRegion(
          //     regions.find((region) => region.name === "United States")
          //   );
          // }
          //=====
          setAddress({
            line1: userData.address.hasOwnProperty("line1")
              ? userData.address.line1
              : "",
            line2: userData.address.hasOwnProperty("line2")
              ? userData.address.line2
              : "",
            city: userData.address.hasOwnProperty("city")
              ? userData.address.city
              : "",
            state: userData.address.hasOwnProperty("state")
              ? userData.address.state
              : "",
            zip_code: userData.address.hasOwnProperty("zip_code")
              ? userData.address.zip_code
              : "",
            country: userData.address.hasOwnProperty("country")
              ? userData.address.country
              : "",
          });
        }
      })
      .catch((error) => {
        toastr.error(error.response.data.message);
      });
  };
  const updateUserProfile = () => {
    const finalValues = {};
    finalValues.name = authUser.name;
    if (linkedInUpdate && linkedIn !== linkedInUpdate)
      finalValues.linkedInProfileUrl = linkedInUpdate;

    if (occupationUpdate && occupation !== occupationUpdate)
      finalValues.occupation = occupationUpdate;
    if (Object.keys(finalValues).length > 0) {
      updateProfile(authUser.unique_id, finalValues)
        .then((response) => {
          setAuthUser(response.data.data);
          if (authUser && authUser.unique_id) {
            localStorage.setItem(
              "authUser",
              JSON.stringify(response.data.data)
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &
                error.response.data.message &
                (error.response.data.errors.length > 0)
            ) {
              toastr.error(error.response.data.errors[0].msg);
            } else {
              toastr.error(error.response.data.message);
            }
          } else {
            toastr.error(error.response.data.message);
          }
        });
    }
  };

  const loadRequestData = () => {
    if (authUser && authUser.unique_id) {
      const uniqueId = authUser && authUser.unique_id;
      getDonationRequest(props.match.params.id, uniqueId)
        .then((response) => {
          setIsLoading(false);
          if (
            isSuperAdmin ||
            isAdmin ||
            isVolunteer ||
            (response &&
              (response.status === Number(STATUS_NEW) ||
                response.status === Number(STATUS_INCOMPLETE) ||
                response.status === Number(STATUS_APPROVED)) &&
              response.user_info &&
              authUser.unique_id === response.user_info._id)
          ) {
            setEditMode(true);
          } else {
            setEditMode(false);
          }
          if (editMode) {
            setDonationRequest(response);
            response.additionalInfo &&
              setCareerOptionsSelected(response.additionalInfo);
            setId(response._id);
            setTitle(response.title);
            setBrief(response.brief);
            setName(response.name);
            setDescription(response.description);
            response.creatorType === "mentor"
              ? setmentorMentee("mentor")
              : setmentorMentee("mentee");
            setStep(1);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
            props.history.push(HOME_PAGE);
          }
        });
    }
  };

  return (
    <div
      className="career-help-container"
      onClick={(event) => handleTooltipClose(event)}
    >
      <Container maxWidth="lg">
        <div className="menu-container career100">
          {closeSideMenu && (
            <Hidden mdUp>
              {" "}
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            </Hidden>
          )}

          <Hidden smDown>
            {" "}
            <Grid md={3} style={{ marginRight: "8px" }}>
              <SideMenu />
            </Grid>
          </Hidden>

          <Grid
            md={9}
            className="container-center drop-box"
            style={{ marginLeft: "8px" }}
          >
            <div className="header">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <img
                    src={
                      step === 0
                        ? "/images/career-help.svg"
                        : mentorMentee === "mentor"
                        ? "/images/mentor.svg"
                        : "/images/mentee.svg"
                    }
                    style={{
                      width: "80px",
                      height: "80px",
                      marginLeft: "20px",
                    }}
                    alt="header img"
                  />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography
                    component="h4"
                    style={{
                      fontWeight: "bold",
                      fontSize: "32px",
                      lineHeight: "37px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    {step === 0
                      ? "Career Help"
                      : mentorMentee === "mentor"
                      ? "TalMentor"
                      : "TalMentee"}
                  </Typography>
                  <Typography
                    component="p"
                    style={{
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#fff",
                    }}
                    gutterBottom
                  >
                    {step === 0
                      ? "Bridging the gap between mentors and mentees to improve the workforce."
                      : mentorMentee === "mentor"
                      ? "Be a mentor"
                      : "Find a mentor"}
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <Grid container>
              <Grid item md={12} xs={12}>
                <div className="form-container career-selection">
                  {step === 0 ? (
                    <div
                      className="mentor-mentee-container"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Grid container spacing={3} md={8}>
                        <Grid item sm={12} xs={12} className="careertitle">
                          Find the Business Help You Need.
                          <br></br>
                          Share the Knowledge You Have.
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography className="crtitle">
                            Be a Mentor
                          </Typography>

                          <div
                            value="mentor"
                            name="mentor"
                            label="Mentor"
                            className={"mentor-mentee-selected"}
                            onClick={() => handleMentorMentee("mentor")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/images/mentor.svg"
                              alt="Mentor"
                              style={{
                                margin: " 0 10px",
                              }}
                            />
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "19px",
                                textAlign: "center",
                              }}
                              gutterBottom
                            >
                              TalMentor
                            </Typography>
                            <KeyboardArrowRight />
                          </div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography className="crtitle">
                            Find a Mentor
                          </Typography>

                          <div
                            className={
                              "mentor-mentee-selected mentor-mentee-selected-last"
                            }
                            value="mentee"
                            name="mentee"
                            label="Mentee"
                            onClick={() => handleMentorMentee("mentee")}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src="/images/mentee.svg"
                              alt="Mentee"
                              style={{
                                margin: " 0 10px",
                              }}
                            />
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "19px",
                                textAlign: "center",
                              }}
                              gutterBottom
                            >
                              TalMentee
                            </Typography>
                            <KeyboardArrowRight />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <>
                      <div className="width60">
                        <Typography variant="h6" gutterBottom>
                          Step {step}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                          {headings[step - 1]}
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={33 * (step - 1)}
                        />
                      </div>
                    </>
                  )}
                  {step === 1 && (
                    <div className="career-bg">
                      <div className="career-width">
                        <div className="text-field-box">
                          <InputLabel
                            style={{ fontWeight: "bold", color: "#000" }}
                          >
                            Phone
                          </InputLabel>
                          <div className="tooltip-css">
                            <div style={{ width: "100%" }}>
                              <PhoneNumber
                                phone={authUser && authUser.phone}
                                isPhoneVerified={
                                  authUser && authUser.phone_verified
                                }
                                ref={phoneRef}
                              />
                              {/* <PhoneInput
                                //maxLength="15"
                                className="phoneBdr"
                                variant="outlined"
                                fullWidth
                                placeholder="Phone Number"
                                name="phoneNumber"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry={
                                  userLocaleInfo && userLocaleInfo.country_code
                                }
                                value={phone}
                                onChange={setPhone}
                                // onKeyPress={(e) => handleEnterClick(e)}
                              /> */}
                              {extraErrors && extraErrors.phone && (
                                <div className="custom-error">
                                  {extraErrors.phone}
                                </div>
                              )}
                            </div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              open={toolTipStatus.phoneHelpIcon}
                              title="Please verify your mobile number"
                              arrow
                              placement="right"
                              className="tooltip-text"
                            >
                              <HelpIcon
                                id="phoneHelpIcon"
                                onClick={(e) => handleTooltipOpen(e)}
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <div className="text-field-box tooltip-css">
                          <FormControl
                            style={{ width: "100%", padding: "10px 0" }}
                            className="recipient"
                            error={extraErrors && extraErrors.occupation}
                          >
                            <TextField
                              id="outlined-error-helper-text"
                              label="Occupation"
                              onChange={handleOccupation}
                              value={occupationUpdate}
                              variant="outlined"
                              className="text-field"
                              style={{ width: "100%" }}
                            />
                            {extraErrors && extraErrors.occupation && (
                              <div className="custom-error">
                                {extraErrors.occupation}
                              </div>
                            )}
                          </FormControl>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.jobHelpIcon}
                            title=" Mention your current job title and position."
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="jobHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </div>

                        <div className="text-field-box tooltip-css">
                          <TextField
                            id="outlined-error-helper-text"
                            label={
                              "LinkedIn profile / other social profiles (Optional)"
                            }
                            value={linkedInUpdate}
                            onChange={handleLinkedIn}
                            variant="outlined"
                            className="text-field"
                          />
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.LinkedInHelpIcon}
                            title="Add your LinkedIn or any other social profile"
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="LinkedInHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </div>
                        <div className="address-field-box  tooltip-css">
                          <FormControl
                            className="text-field"
                            style={{ fontWeight: "bold", color: "#000" }}
                          >
                            <p>
                              Select your location
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                open={toolTipStatus.locationHelpIcon}
                                title="Select your current location"
                                arrow
                                placement="right"
                                className="tooltip-text"
                              >
                                <HelpIcon
                                  id="locationHelpIcon"
                                  onClick={(e) => handleTooltipOpen(e)}
                                />
                              </Tooltip>
                            </p>
                            <NewAddress
                              value={address}
                              onChange={handleChangeAddress}
                              error={extraErrors && extraErrors.address}
                            />
                            {extraErrors && extraErrors.address && (
                              <div className="custom-error">
                                {extraErrors.address}
                              </div>
                            )}
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="width60">
                    {step === 2 && (
                      <>
                        <Box
                          className="tooltip-css"
                          sx={{ borderBottom: 1, borderColor: "divider" }}
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Mentor/Mentee " {...a11yProps(0)} />
                            <Tab label="College Bound" {...a11yProps(1)} />
                            <Tab label="Career Pivots" {...a11yProps(2)} />
                          </Tabs>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.ChooseHelpIcon}
                            title="Choose the areas in which you need guidance from our Mentors. Choose as many as you can."
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="ChooseHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </Box>
                        <TabPanel value={value} index={0}>
                          <List>
                            {careerMenu["Mentor/Mentee"].map((value) => {
                              const labelId = { value };

                              return (
                                <InputLabel
                                  key={value}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(value)}
                                  className="careerCheck"
                                >
                                  <ListItemIcon style={{ minWidth: "0" }}>
                                    <Checkbox
                                      edge="start"
                                      checked={checked1.indexOf(value) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      checked={
                                        careerOptionsSelected[
                                          "Mentor/Mentee"
                                        ] &&
                                        careerOptionsSelected[
                                          "Mentor/Mentee"
                                        ].indexOf(value) > -1
                                      }
                                      onChange={(e) =>
                                        handleToggleSelection(
                                          e,
                                          "Mentor/Mentee",
                                          value
                                        )
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={value}
                                    className="checkTxt"
                                  />
                                </InputLabel>
                              );
                            })}
                          </List>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <List>
                            {careerMenu["College Bound"].map((value) => {
                              const labelId = { value };

                              return (
                                <InputLabel
                                  key={value}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(value)}
                                  className="careerCheck"
                                >
                                  <ListItemIcon style={{ minWidth: "0" }}>
                                    <Checkbox
                                      edge="start"
                                      // checked={checked1.indexOf(value) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      checked={
                                        careerOptionsSelected[
                                          "College Bound"
                                        ] &&
                                        careerOptionsSelected[
                                          "College Bound"
                                        ].indexOf(value) > -1
                                      }
                                      onChange={(e) =>
                                        handleToggleSelection(
                                          e,
                                          "College Bound",
                                          value
                                        )
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={value}
                                    className="checkTxt"
                                  />
                                </InputLabel>
                              );
                            })}
                          </List>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <List>
                            {careerMenu["Career Pivots"].map((value) => {
                              const labelId = { value };

                              return (
                                <InputLabel
                                  key={value}
                                  role={undefined}
                                  dense
                                  button
                                  onClick={handleToggle(value)}
                                  className="careerCheck"
                                >
                                  <ListItemIcon style={{ minWidth: "0" }}>
                                    <Checkbox
                                      edge="start"
                                      // checked={checked1.indexOf(value) !== -1}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                      checked={
                                        careerOptionsSelected[
                                          "Career Pivots"
                                        ] &&
                                        careerOptionsSelected[
                                          "Career Pivots"
                                        ].indexOf(value) > -1
                                      }
                                      onChange={(e) =>
                                        handleToggleSelection(
                                          e,
                                          "Career Pivots",
                                          value
                                        )
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    primary={value}
                                    className="checkTxt"
                                  />
                                </InputLabel>
                              );
                            })}
                          </List>
                        </TabPanel>
                        <FormControl
                          className="text-field"
                          variant="outlined"
                          error={optionsErr.length !== 0}
                        >
                          <FormHelperText>
                            {optionsErr.length === 0
                              ? ""
                              : "Please select at least one category"}
                          </FormHelperText>
                        </FormControl>

                        <div className="text-field-box" style={{}}>
                          {values.map((group) => (
                            <>
                              <b>
                                {careerOptionsSelected[group] &&
                                  careerOptionsSelected[group].length > 0 &&
                                  group}
                              </b>
                              <List>
                                {careerOptionsSelected[group] &&
                                  careerOptionsSelected[group].map(
                                    (element, index) => (
                                      <ListItemText
                                        key={index}
                                        primary={element}
                                        style={{ marginLeft: "10px" }}
                                      />
                                    )
                                  )}
                              </List>
                            </>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="width60">
                    {step === 3 && (
                      <>
                        <div className="text-field-box tooltip-css cov-input">
                          <div style={{ width: "100%" }}>
                            <TextField
                              id="outlined-error-helper-text"
                              label="Title"
                              value={title}
                              variant="outlined"
                              className="text-field"
                              onChange={handleTitle}
                              inputProps={{
                                maxLength: "50",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "15px",
                                marginLeft: "0px",
                                position: "absolute",
                                top: "12px",
                                color: "#999",
                                padding: "9px",
                                background: "#fff",
                                right: "38px",
                              }}
                            >
                              {" "}
                              {title ? title.length : 0}/50
                            </span>
                            {extraErrors && extraErrors.title && (
                              <div className="custom-error">
                                {extraErrors.title}
                              </div>
                            )}
                          </div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.titleHelpIcon}
                            title="Include a title that conveys the guidance you need."
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="titleHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </div>
                        <div className="text-field-box tooltip-css cov-input">
                          <div style={{ width: "100%" }}>
                            <TextField
                              id="outlined-error-helper-text"
                              label="Brief"
                              value={brief}
                              onChange={handleBrief}
                              variant="outlined"
                              className="text-field"
                              inputProps={{
                                maxLength: "120",
                                spellCheck: true,
                              }}
                            />
                            <span
                              style={{
                                fontSize: "15px",
                                marginLeft: "0px",
                                position: "absolute",
                                top: "12px",
                                color: "#999",
                                padding: "9px",
                                background: "#fff",
                                right: "35px",
                                marginRight: "5px",
                              }}
                            >
                              {" "}
                              {brief ? brief.length : 0}/120
                            </span>
                            {extraErrors && extraErrors.brief && (
                              <div className="custom-error">
                                {extraErrors.brief}
                              </div>
                            )}
                          </div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.brieflyHelpIcon}
                            title="Briefly describe your requirement"
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="brieflyHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </div>
                        <div className="text-field-box tooltip-css cov-input">
                          <div style={{ width: "100%" }}>
                            <TextField
                              id="outlined-error-helper-text"
                              label="Description"
                              multiline
                              rows={5}
                              value={description}
                              onChange={handleDescription}
                              variant="outlined"
                              className="text-field"
                              inputProps={{
                                maxLength: "300",
                                spellCheck: true,
                              }}
                            />
                            <span
                              style={{
                                fontSize: "15px",
                                marginLeft: "5px",
                                position: "absolute",
                                top: "12px",
                                color: "#999",
                                padding: "9px",
                                background: "#fff",
                                right: "35px",
                                marginRight: "5px",
                              }}
                            >
                              {" "}
                              {description ? description.length : 0}/300
                            </span>
                            {extraErrors && extraErrors.description && (
                              <div className="custom-error">
                                {extraErrors.description}
                              </div>
                            )}
                          </div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            open={toolTipStatus.MentionHelpIcon}
                            title="Describe in depth your educational background, professional experience, and the support you need."
                            arrow
                            placement="right"
                            className="tooltip-text"
                          >
                            <HelpIcon
                              id="MentionHelpIcon"
                              onClick={(e) => handleTooltipOpen(e)}
                            />
                          </Tooltip>
                        </div>
                        <div>
                          Disclaimers:
                          <div className="checkbox-box">
                            <Checkbox
                              checked={policy1}
                              onChange={handlePolicy1}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              style={{ marginTop: "0px", paddingTop: "0px" }}
                            />
                            <Typography variant="body2" gutterBottom>
                              All registrants of TAL Career Help must
                              participate in a mandatory brief introduction
                              session. These sessions are held every Friday at
                              5:00 PM PST and you will be sent the meeting link
                              details to your registered email.
                            </Typography>
                          </div>
                          <div className="checkbox-box">
                            <Checkbox
                              checked={policy2}
                              onChange={handlePolicy2}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              style={{ marginTop: "0px", paddingTop: "0px" }}
                            />
                            <Typography variant="body2" gutterBottom>
                              I am ok to be contacted by the TAL team or
                              mentors.
                            </Typography>
                          </div>
                          <div className="checkbox-box">
                            <Checkbox
                              checked={policy3}
                              onChange={handlePolicy3}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              style={{ marginTop: "0px", paddingTop: "0px" }}
                            />
                            <Typography variant="body2" gutterBottom>
                              By submitting, I agree to your
                              <a
                                href="https://www.touchalife.org/terms-and-conditions/"
                                target="blank"
                              >
                                Terms of Use
                              </a>{" "}
                              and{" "}
                              <a
                                href="https://www.touchalife.org/privacy-policy/"
                                target="blank"
                              >
                                Privacy Policy
                              </a>
                              .{" "}
                            </Typography>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    {step !== 0 && (
                      <>
                        <div className="width60">
                          <Button
                            style={{
                              color: "var(--tal_primary)",
                            }}
                            className="text-field"
                            onClick={() => setStep(step - 1)}
                          >
                            Back
                          </Button>

                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: !(policy1 && policy2 && policy3)
                                ? "#9D9D9D"
                                : "var(--tal_primary)",
                              color: "white",
                            }}
                            className="text-field"
                            disabled={!(policy1 && policy2 && policy3)}
                            onClick={handleNext}
                          >
                            {step === 3
                              ? editMode
                                ? "Update"
                                : "Submit"
                              : "Next"}
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(CareerHelp);
