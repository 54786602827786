const validateLogin = (values) => {
  let errors = {};
  if (!values.account) {
    errors.account = "Email Address or Username is required";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password should contain at least 6 characters";
  }
  return errors;
};

export default validateLogin;
