import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Box } from "@material-ui/core";
import toastr from "toastr";
import { getJudges } from "../../utils/api";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
// import "./custom-styles.css";
import DownloadIdea from "./downloadIdea";
import firebase from "../../firebase/firebase";
import ReactPlayer from "react-player";
import { isImage } from "../../utils/utils";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    " & > div": {
      "&:first-child": {
        backgroundColor: "rgba(0, 0, 0, 0.15)",
      },
    },
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "#F44234",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const ReadingDownloadIdea = (props) => {
  const classes = useStyles();
  const { document } = props;
  const [judges, setJudges] = useState([]);
  const [images, setImages] = useState([]);
  const [imageDocuments, setImageDocuments] = useState([]);
  const [nonImageDocuments, setNonImageDocuments] = useState([]);
  const {
    requesttypeEntrepreneur,
    requesttypeAdvocacy,
    requesttypePhilanthropy,
  } = props;
  const donationRequestId = document && document._id;

  const loadJudges = () => {
    getJudges(donationRequestId, "")
      .then((response) => {
        if (response) {
          const respData = response.data;
          setJudges([...respData]);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching Judges: " + error.message);
      });
  };

  useEffect(() => {
    if (donationRequestId) {
      loadJudges();
    }
  }, [donationRequestId]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const fetchImages = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch images
      const path = `DonationRequest/${uid}/images`;
      const storageRef = firebase.storageDonationRequests.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            setImages([]);
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: url,
                      thumbnail: url,
                    },
                  ];
                });
              });
            });
            resolve();
          } else if (
            document.request_type !== "cash" &&
            document.request_type !== "social entrepreneurship" &&
            document.request_type !== "social advocacy"
          ) {
            setImages([
              {
                original: `${
                  document && document.defaultImageUrl
                    ? document.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
                thumbnail: `${
                  document && document.defaultImageUrl
                    ? document.defaultImageUrl
                    : "/images/default-donation-request.jpg"
                }`,
              },
            ]);
            resolve();
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  const _renderVideo = (item) => {
    return (
      <ReactPlayer
        url={item.embedUrl}
        playing={false}
        controls={true}
        width="100%"
        height="100%"
      />
    );
  };

  const fetchDocuments = (uid) => {
    return new Promise((resolve, reject) => {
      const path = `DonationRequest/${uid}/documents`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        setImageDocuments([]);
        setNonImageDocuments([]);
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            const itemPathRef = storageRef.child(path + "/" + item.name);
            itemPathRef
              .getMetadata()
              .then((metadata) => {
                if (isImage(metadata.contentType)) {
                  setImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                } else {
                  setNonImageDocuments((prevDocuments) => [
                    ...prevDocuments,
                    {
                      url: url,
                      name: metadata.name,
                      contentType: metadata.contentType,
                      userGivenName: metadata.customMetadata
                        ? metadata.customMetadata.userGivenName
                        : metadata.name,
                      note:
                        metadata.customMetadata && metadata.customMetadata.note,
                    },
                  ]);
                }
              })
              .catch(() => {
                //toastr.error(error.message);
              });
          });
        });
        resolve();
      });
    });
  };

  const fetchVideos = (uid) => {
    return new Promise((resolve, reject) => {
      // Fetch videos
      const path = `DonationRequest/${uid}/videos`;
      const storageRef = firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef
        .listAll()
        .then((result) => {
          if (result.items && result.items.length > 0) {
            result.items.forEach((item) => {
              item.getDownloadURL().then((url) => {
                setImages((prevImages) => {
                  return [
                    ...prevImages,
                    {
                      original: "/images/default-thumbnail.jpeg",
                      thumbnail: "/images/default-thumbnail.jpeg",
                      embedUrl: url,
                      renderItem: _renderVideo.bind(this),
                    },
                  ];
                });
              });
            });
            resolve();
          }
        })
        .catch(() => {});
    });
  };
  useEffect(() => {
    if (document && document._id) {
      fetchVideos(document._id);
      fetchImages(document._id);
      fetchDocuments(document._id);
    }
  }, [document]);

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openOrgModal}
        onClose={props.onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openOrgModal}>
          <div className={classes.paper}>
            <div
              className=" mx-3"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box
                component="Button"
                color="rgb(243, 90, 87)"
                bgcolor="white"
                borderRadius="50px"
                width="40px"
                height="40px"
                border="1px solid rgb(243, 90, 87)"
                margin="20px"
                onClick={handlePrint}
              >
                <PrintIcon />
              </Box>

              <div style={{ margin: "30px", cursor: "pointer" }}>
                <CloseIcon
                  onClick={(e) => {
                    props.onCloseModal();
                  }}
                />
              </div>
            </div>
            <DownloadIdea
              ref={printRef}
              document={document}
              judges={judges}
              requesttypeEntrepreneur={requesttypeEntrepreneur}
              requesttypeAdvocacy={requesttypeAdvocacy}
              requesttypePhilanthropy={requesttypePhilanthropy}
              images={images}
              imageDocuments={imageDocuments}
              nonImageDocuments={nonImageDocuments}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(ReadingDownloadIdea);
