import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import "./Transformers.scss";
import { withRouter } from "react-router-dom";
import { appContext } from "../../App";

const StatusMedia = (props) => {
  const { authUser } = useContext(appContext);
  return (
    <>
      <Grid container className="content" spacing={2}>
        <Grid item sm={12} md={2} className="img100">
          <Typography>
            <img
              src={
                props.thumbnailUrl
                  ? props.thumbnailUrl
                  : "/images/default-thumbnail.jpeg"
              }
              className="img-fluid"
              alt="Mentor"
            />
          </Typography>
        </Grid>
        <Grid item sm={12} md={5}>
          <Typography style={{ marginBottom: "15px" }}>
            <p>
              <strong className=" text-ucase">
                Track: {props.requestType}
              </strong>
              |<strong className="text-ucase">&nbsp;Team: {props.team}</strong>
            </p>
            <p>
              <strong className="text-ucase">
                Submitted for:{" "}
                {props.document.requested_for ===
                "ou-social-entrepreneurship-pitch-fest-2023"
                  ? "Social Entrepreneurship Pitchfest"
                  : props.document.requested_for === "talkindnessday"
                  ? "TAL Kindness Day"
                  : props.document.requested_for}
              </strong>
            </p>
            <Grid item xs={12}>
              {props.document &&
                props.document.supportedSDGs &&
                props.document.supportedSDGs.length > 0 && (
                  <>
                    <Grid container style={{ gap: "5px" }}>
                      {props.document.supportedSDGs.map((data) => {
                        return (
                          <Chip
                            className=" bg-secondary"
                            label={data && data.toString()}
                            variant="outlined"
                            style={{ color: "#fff" }}
                          />
                        );
                      })}
                    </Grid>
                  </>
                )}
            </Grid>{" "}
          </Typography>
        </Grid>
        <Grid md={5} sm={12} item>
          <div className="view-btn edit-btn">
            <Button
              onClick={() =>
                window.open(
                  `/transformers/viewapplication/${props.donationRequestId}`,
                  "_blank"
                )
              }
              // href={`/transformers/viewapplication/${props.donationRequestId}`}
              className="edit-button"
              style={{ textDecoration: "none", marginRight: "10px" }}
            >
              View Application
            </Button>
            {props.document &&
              props.document.assignedJudges &&
              props.document.assignedJudges.length == 0 &&
              props.document.status === -2 &&
              authUser?.unique_id === props.document?.user_info?._id && (
                <Button
                  onClick={() =>
                    props.history.push(
                      `/transformers/SubmitIdea/${props.donationRequestId}`
                    )
                  }
                  className=" edit-button"
                >
                  Edit Application
                </Button>
              )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(StatusMedia);
