import React, { useContext, useState } from "react";
import toastr from "toastr";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import moment from "moment";
import Tab from "@material-ui/core/Tab";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
  ButtonGroup,
  List,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Tooltip,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Sidemenu from "../SideMenu/Sidemenu";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import { appContext } from "../../App";
import ContactModal from "./ContactModal";
import AddContactsModal from "./AddContactsModal";
import {
  deleteContact,
  deleteContactGroup,
  getMyContactGroups,
  getMyContacts,
} from "../../utils/api";
import { DATE_FORMAT, TIME_FORMAT_SECONDS } from "../../utils/utils";
import ContactGroupModal from "../ContactGroups/ContactGroupModal";
import cssstyle from "./Contact.module.scss";
const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const contactCells = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "mobileNumber",
    numeric: true,
    disablePadding: false,
    label: "Mobile Number",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

const groupCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "contactCount",
    numeric: false,
    disablePadding: true,
    label: "No of Contacts",
  },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, value } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = value === 0 ? contactCells : groupCells;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="table-header-text"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MyContactsandGroups(props) {
  const { authUser } = useContext(appContext);
  const [value, setValue] = useState(0);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const buttonClass = styles();
  const { contacts, setContacts, contactGroups, setContactGroups } =
    useContext(appContext);

  const [openModal, setOpenModal] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [openCSVModal, setOpenCSVModal] = useState(false);
  const [multipleContacts, setMultipleContacts] = useState(false);
  const [currentContactGroup, setCurrentContactGroup] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [formData, setFormData] = useState(false);
  const [currentContact, setCurrentContact] = React.useState(null);
  const handleAddContacts = (operation) => {
    if (operation === "addcontact") {
      setMultipleContacts(false);
      setOpenModal(true);
      setFormData(true);
    } else if (operation === "uploadcontacts") {
      setMultipleContacts(false);
      setOpenCSVModal(true);
    }
  };
  const handleEditContact = (contact) => {
    setOpenModal(true);
    setCurrentContact(contact);
    setFormData(false);
  };

  const handleEditContactGroup = (cg) => {
    setCurrentContactGroup(cg);
    setOpenGroupModal(true);
  };
  const handleModalCloseContactGroup = () => {
    setOpenGroupModal(false);
    setCurrentContactGroup(null);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setCurrentContact(null);
  };

  const handleCSVModalClose = () => {
    setOpenCSVModal(false);
  };

  const contactSaveHandler = () => {
    setOpenModal(false);
    //window.location.reload();
  };

  const contactsSaveHandler = () => {
    setOpenCSVModal(false);
    //window.location.reload();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportToCSV = () => {
    const JsonFields = ["First Name", "Last Name", "Email", "Mobile Number"];
    var csvStr = JsonFields.join(",") + "\n";

    contacts.forEach((element) => {
      let { firstName, lastName, email, phoneNumber } = element;

      if (firstName) {
        firstName = firstName;
      }
      if (lastName) {
        lastName = lastName;
      }
      if (email) {
        email = email;
      }
      if (phoneNumber) {
        phoneNumber = phoneNumber;
      }

      csvStr +=
        firstName +
        "," +
        lastName +
        "," +
        email +
        "," +
        phoneNumber +
        "," +
        "\n";
    });
    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
    hiddenElement.target = "_blank";
    hiddenElement.download = `MyContacts-${moment().format(
      `${DATE_FORMAT}_${TIME_FORMAT_SECONDS}`
    )}.csv`;
    hiddenElement.click();
  };

  const deleteContactHandler = async (currentContactGroup) => {
    if (window.confirm("Are you sure you want to delete the contactgroup?")) {
      if (currentContactGroup) {
        let contactGroupsCopy = [...contactGroups];
        try {
          const data = await deleteContactGroup(
            authUser.unique_id,
            currentContactGroup._id
          );
          contactGroupsCopy = contactGroupsCopy.filter(
            (item) => item._id !== currentContactGroup._id
          );
          setContactGroups(contactGroupsCopy);
          toastr.success(data.message);
        } catch (error) {
          toastr.success(error.message);
        }
      }
    }
  };

  const deleteHandler = async (currentContact) => {
    if (window.confirm("Are you sure you want to delete the contact?")) {
      if (currentContact) {
        try {
          const data = await deleteContact(
            authUser.unique_id,
            currentContact._id
          );
          const modifiedContacts = [...contacts].filter(
            (item) => item._id !== currentContact._id
          );
          setContacts(modifiedContacts);
          const groups = await getMyContactGroups(authUser.unique_id);
          setContactGroups(groups);
          toastr.success(data.message);
        } catch (error) {
          toastr.error(error.message);
        }
      }
    }
  };

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <Box
        component="div"
        p={1}
        mt={-3}
        mb={2}
        zIndex="2"
        position="sticky"
        top="86px"
        className="drop-box"
      >
        <Container maxWidth="lg">
          <Box className={classes.livestreamings}>
            <Grid container className={cssstyle.my_group_top_header}>
              <Box display="flex" alignItems="center" gridGap={10}>
                <Button
                  onClick={handleCloseSideMenu}
                  style={{
                    color: "rgb(243,90,87)",
                    border: "2px solid  rgb(243,90,87)",
                    borderRadius: "100%",
                    minWidth: "50px",
                    height: "50px",
                  }}
                >
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
                <h2>My Contacts and Groups</h2>
              </Box>

              <div className={cssstyle.my_group_btns_up}>
                {value === 0 && (
                  <ButtonGroup className={cssstyle.contactadd_btns}>
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        backgroundColor: "rgb(243,90,87)",
                      }}
                      onClick={() => handleAddContacts("addcontact")}
                    >
                      Add contact
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        backgroundColor: "rgb(243,90,87)",
                      }}
                      onClick={() => handleAddContacts("uploadcontacts")}
                    >
                      Import
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        backgroundColor: "rgb(243,90,87)",
                      }}
                      onClick={() => exportToCSV()}
                    >
                      Export
                    </Button>
                  </ButtonGroup>
                )}
                {value === 1 && (
                  <div className={cssstyle.contactadd_btns}>
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        backgroundColor: "rgb(243,90,87)",
                      }}
                      onClick={() => setOpenGroupModal(true)}
                    >
                      Add Contact Group
                    </Button>
                  </div>
                )}
              </div>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box component="div" display="flex">
          {closeSideMenu && (
            <Box marginRight="10px">
              <Sidemenu />
            </Box>
          )}

          <Box component="div" width="100%" style={{ overflowX: "auto" }}>
            <Box
              component="section"
              borderBottom="1px solid #c1c1c1"
              bgcolor="white"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="All Contacts" {...a11yProps(0)} />
                <Tab label="Contact Groups" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0} className="audio_room_ui">
              <div className={classes.listContainer}>
                {contacts && contacts.length > 0 ? (
                  <TableContainer className={classes.container}>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={"medium"}
                      aria-label="sticky table"
                      style={{ background: "#fff" }}
                    >
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        value={value}
                      />
                      <TableBody>
                        {stableSort(contacts, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            return (
                              <TableRow>
                                <TableCell align="left">
                                  {row.firstName + " " + row.lastName}
                                </TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">
                                  {row.phoneNumber}
                                </TableCell>
                                <TableCell align="left">
                                  {row.userId === authUser.unique_id && (
                                    <div>
                                      <Tooltip title="Edit">
                                        <IconButton
                                          className="del-btn"
                                          onClick={() => {
                                            handleEditContact(row);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>{" "}
                                      <Tooltip title="Delete">
                                        <IconButton
                                          className="del-btn"
                                          onClick={() => deleteHandler(row)}
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    {contacts && contacts.length === 0 && (
                      <Typography className="no-req-text">
                        No Contacts Found
                      </Typography>
                    )}
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      component="div"
                      count={contacts.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      style={{
                        position: "sticky",
                        background: "white",
                        bottom: "0",
                        borderTop: "1px solid #eaeaea",
                      }}
                    />
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      padding: "10px 0",
                      textAlign: "center",
                    }}
                  >
                    No Contacts
                  </div>
                )}
              </div>
              <ContactModal
                formData={formData}
                currentContact={currentContact}
                openModal={openModal}
                onModalClose={handleModalClose}
                onContactSave={contactSaveHandler}
              ></ContactModal>
              <AddContactsModal
                openModal={openCSVModal}
                onModalClose={handleCSVModalClose}
                onContactsSave={contactsSaveHandler}
              ></AddContactsModal>
            </TabPanel>
            <TabPanel value={value} index={1} className="audio_room_ui">
              {contactGroups && contactGroups.length > 0 ? (
                <TableContainer className={classes.container}>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="sticky table"
                    style={{ background: "#fff" }}
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      value={value}
                    />
                    <TableBody>
                      {stableSort(contactGroups, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow>
                              <TableCell align="left">{row.name}</TableCell>
                              <TableCell align="left">
                                {row.contactCount}
                              </TableCell>
                              <TableCell align="left">
                                {row.userId === authUser.unique_id && (
                                  <div>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        className="del-btn"
                                        onClick={() => {
                                          handleEditContactGroup(row);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>{" "}
                                    <Tooltip title="Delete">
                                      <IconButton
                                        className="del-btn"
                                        onClick={() =>
                                          deleteContactHandler(row)
                                        }
                                      >
                                        <DeleteForeverIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {contactGroups && contactGroups.length === 0 && (
                    <Typography className="no-req-text">
                      No Contacts Groups
                    </Typography>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    component="div"
                    count={contactGroups.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    style={{
                      position: "sticky",
                      background: "white",
                      bottom: "0",
                      borderTop: "1px solid #eaeaea",
                    }}
                  />
                </TableContainer>
              ) : (
                <div
                  style={{
                    fontSize: "12px",
                    padding: "10px 0",
                    textAlign: "center",
                  }}
                >
                  No Contacts Groups
                </div>
              )}
              <ContactGroupModal
                currentContactGroup={currentContactGroup}
                openModal={openGroupModal}
                onModalClose={handleModalCloseContactGroup}
                onContactGroupSave={() => {
                  setOpenGroupModal(false);
                }}
              ></ContactGroupModal>
            </TabPanel>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
