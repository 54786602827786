import React, { useEffect, useContext, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Link, TextareaAutosize } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import PodcastVideoPlayer from "../common/PodcastVideoPlayer";
import { Tooltip } from "@material-ui/core";
import PublicProfileSideBar from "../sidebars/publicProfileSidebar";
import IconButton from "@material-ui/core/IconButton";
import AddPhotos from "../common/AddPhotos";
import firebase from "../../firebase/firebase";
import StickyBox from "react-sticky-box";
import {
  getUserDonationRequests,
  getUserDataWithUsername,
  updateProfile,
  getTopDonationRequests,
} from "../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { appContext } from "../../App";
import toastr from "toastr";
import PaymentsPopUp from "../donationDetails/PaymentsPopUp";
import Box from "@material-ui/core/Box";
import "./PublicProfile.scss";
import ShareOnSocialNetworks from "../common/ShareOnSocialNetworks";
import DonationRequestCard from "../donationRequest/DonationRequestCard";
import ShareOnSocialMedia from "../share/ShareOnSocialMedia";
import { getShortUrl } from "../../utils/utils";
import Share from "@material-ui/icons/Share";
import ReactHtmlParser from "react-html-parser";
import Loader from "../common/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  // modalpaper: {
  //   backgroundColor: theme.palette.background.paper,
  //   textAlign: "center",
  //   borderRadius: 3,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
  hide: {
    display: "none",
  },
  drawer: {
    width: 260,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 260,
  },
}));

const PublicProfile = (props) => {
  const { authUser, setAuthUser, isSuperAdmin, isAdmin, isVolunteer, region } =
    useContext(appContext);
  const classes = useStyles();
  const defaultRequestTypesToGetUserRequests = JSON.stringify([
    { request_type: "career help", creatorType: "mentee" },
    { request_type: " homework help", creatorType: "student" },
    { request_type: "internship", creatorType: "employer" },
    { request_type: "volunteering", creatorType: "volunteering organization" },
    { request_type: "scholarship" },
  ]);
  const [topDonationRequests, setTopDonationRequests] = useState([]);
  const [donationRequests, setDonationRequests] = useState([]);
  const [showCoverPhoto, setShowCoverPhoto] = useState(false);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [showProfilePhoto, setShowProfilePhoto] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    "https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/company/Ce2a424ef4ceda0e290183969f80b8bf3/projects/M5a82fbbd9de4261e887571c42aefeae91571702605323/images/M2f21aa2c1aae080d85ba324736f6e4d91586935513274"
  );
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [shareIcons, setShareIcons] = useState(false);
  const [selectedOption, setSelectedOption] = useState("timeline");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isPaymentsPopUpOpen, setIsPaymentsPopUpOpen] = useState(false);
  const [noUser, setNoUser] = useState(false);
  const [open, setOpen] = useState(false);
  const [shortUrl, setShortUrl] = useState("");
  const [chipData, setChipData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleClickOpen = () => {
    // Get shortUrl from the API for sharing the donation request
    getShortUrl(
      process.env.REACT_APP_BASE_URL + "/" + userData.username,
      "publicProfile",
      userData.unique_id
    )
      .then((shortUrl) => {
        setShortUrl(shortUrl);
        setOpen(true);
      })
      .catch((errorMessage) => {
        toastr.error(errorMessage);
      });
  };

  const loadTopDonationRequests = () => {
    getTopDonationRequests(region && region._id)
      .then((response) => {
        if (response.data && response.data instanceof Array) {
          setTopDonationRequests(response.data);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  const loadDonationRequests = (userId) => {
    // setIsLoading(true);
    getUserDonationRequests(
      userId,
      "1,2",
      region && region._id,
      "",
      "",
      true,
      defaultRequestTypesToGetUserRequests,
      authUser && authUser.unique_id
    )
      .then((response) => {
        setDonationRequests(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Error getting donation requests:" + error.message);
      });
  };

  useEffect(() => {
    if (props.match.params.username) {
      getUserDataWithUsername(props.match.params.username)
        .then((response) => {
          setUserData(response);
          setCoverImageUrl(response.coverImageUrl);
          setProfileImageUrl(response.profile_image_url);
          loadImages(response.unique_id);
          loadVideos(response.unique_id);
        })
        .catch(() => {
          setNoUser(true);
        });
    }
  }, [props.match.params.username]);

  useEffect(() => {
    if (userData && userData.unique_id) {
      loadDonationRequests(userData.unique_id);
      if (authUser && authUser.unique_id) {
        setEditMode(
          userData.unique_id === authUser.unique_id ||
            isSuperAdmin ||
            isAdmin ||
            isVolunteer
        );
      } else {
        setEditMode(false);
      }
    }
  }, [userData, authUser, region]);

  const loadImages = (uid) => {
    setImages([]);
    const path = `UserPictures/${uid}/images`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          item.getMetadata().then((metadata) => {
            setImages((prevImages) => [
              ...prevImages,
              { url: url, name: metadata.name },
            ]);
          });
        });
      });
    });
  };

  const loadVideos = (uid) => {
    setVideos([]);
    const path = `UserVideos/${uid}/videos`;
    const storageRef = firebase.storage.ref();
    const pathRef = storageRef.child(path);
    pathRef.listAll().then((result) => {
      result.items.forEach((item) => {
        item.getDownloadURL().then((url) => {
          const itemPathRef = storageRef.child(path + "/" + item.name);
          itemPathRef
            .getMetadata()
            .then((metadata) => {
              setVideos((prevVideos) => [
                ...prevVideos,
                {
                  url: url,
                  name: metadata.name,
                  userGivenName: metadata.customMetadata.userGivenName,
                  note: metadata.customMetadata.note,
                },
              ]);
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        });
      });
    });
  };

  useEffect(() => {
    if (userData && userData.unique_id) {
      if (selectedOption === "timeline") {
      } else if (selectedOption === "photos") {
      } else if (selectedOption === "videos") {
      }
    }
  }, [selectedOption]);

  const updateDefaultImageUrl = (url) => {
    setProfileImageUrl(url);
    const finalValues = {
      image_url: url,
    };
    updateProfile(authUser.unique_id, finalValues)
      .then(() => {
        if (authUser.unique_id === userData.unique_id) {
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              profile_image_url: url,
            };
          });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const updateCoverImageUrl = (url) => {
    setCoverImageUrl(url);
    const finalValues = {
      coverImageUrl: url,
    };
    updateProfile(authUser.unique_id, finalValues)
      .then(() => {
        if (authUser.unique_id === userData.unique_id) {
          setAuthUser((currentValues) => {
            return {
              ...currentValues,
              coverImageUrl: url,
            };
          });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const onPlayVideo = () => {
    setIsPlayingVideo(true);
  };

  const onClosePlayVideo = () => {
    setIsPlayingVideo(false);
  };

  const donationRequestClickHandler = (id) => {
    props.history.push(`/donationRequest/${id}`);
  };

  const viewAllPhotosHandler = () => {
    setSelectedOption("photos");
  };

  const viewAllVideosHandler = () => {
    setSelectedOption("videos");
  };

  const handleDonateToMyCauses = () => {
    if (authUser) {
      setIsPaymentsPopUpOpen(true);
    } else {
      props.history.push(
        "/publicPayment?donationfor=individual&touserinfo=" + userData.unique_id
      );
    }
  };

  return (
    <div className="public-profile-container">
      {/* <div class="white drop-box"></div> */}
      <Container maxWidth="lg">
        <div class="public-main">
          {!noUser && (
            <Fragment>
              {/* <div className="public-bg-image"></div> */}

              <div className="public-cover-page">
                {authUser &&
                  userData &&
                  authUser.unique_id === userData.unique_id && (
                    <div className="updateBannerBlk">
                      <CameraAltIcon
                        onClick={() => setShowCoverPhoto(true)}
                        className="updateIcon"
                      />
                    </div>
                  )}

                <Box className="coverpic">
                  <img
                    src={
                      coverImageUrl
                        ? coverImageUrl
                        : "/images/celeb-profile-bg.jpeg"
                    }
                    className="cover-pic"
                    alt="cover-pic"
                    width="100%"
                  />
                </Box>

                <CardHeader
                  className="public-card-header"
                  avatar={
                    <div className="profile-pic-container">
                      <div className="profile-main">
                        {authUser &&
                          userData &&
                          authUser.unique_id === userData.unique_id && (
                            <div className="profileCamIcon">
                              <CameraAltIcon
                                onClick={() => setShowProfilePhoto(true)}
                                className="updateIcon"
                              />
                            </div>
                          )}
                        <div className="pulic-propic">
                          <img
                            src={
                              profileImageUrl
                                ? profileImageUrl
                                : "https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/default/user_male_circle.svg"
                            }
                            alt="cover-pic"
                            // width="130px"
                            // height="130px"
                            className="profile-pic"
                          />
                        </div>
                      </div>
                      <h2>
                        {userData &&
                          userData.name &&
                          userData.name.first_name +
                            " " +
                            userData.name.last_name}{" "}
                        <VerifiedUser
                          style={{ color: "green", fontSize: 18 }}
                        />
                      </h2>
                      {/* DO NOT delete */}
                      {/* <Button
                        className="public-cause-btn"
                        onClick={handleDonateToMyCauses}
                      >
                        Donate To My Causes
                      </Button> */}
                    </div>
                  }
                  title={
                    <div className="borderBottom-nav">
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={`${classes.root} public-page-navbar`}
                      >
                        <ListItem button className="options">
                          <ListItemText
                            primary="Timeline"
                            style={{
                              color:
                                selectedOption === "timeline"
                                  ? "#3C95E8"
                                  : "#000",
                              borderBottom:
                                selectedOption === "timeline"
                                  ? "4px solid #3C95E8"
                                  : "none",
                              textAlign: "center",
                              paddingBottom: 16,
                              margin: 0,
                            }}
                            className="tabname"
                            onClick={() => setSelectedOption("timeline")}
                          />
                        </ListItem>
                        <ListItem button className="options">
                          <ListItemText
                            primary="About"
                            style={{
                              color:
                                selectedOption === "about" ? "#3C95E8" : "#000",
                              borderBottom:
                                selectedOption === "about"
                                  ? "4px solid #3C95E8"
                                  : "none",
                              textAlign: "center",
                              paddingBottom: 15,
                              margin: 0,
                            }}
                            className="tabname"
                            onClick={() => setSelectedOption("about")}
                          />
                        </ListItem>
                        <ListItem button className="options">
                          <ListItemText
                            primary="Photos"
                            style={{
                              textAlign: "center",
                              color:
                                selectedOption === "photos"
                                  ? "#3C95E8"
                                  : "#000",
                              borderBottom:
                                selectedOption === "photos"
                                  ? "4px solid #3C95E8"
                                  : "none",
                              paddingBottom: 15,
                              margin: 0,
                            }}
                            className="tabname"
                            onClick={() => setSelectedOption("photos")}
                          />
                        </ListItem>
                        <ListItem button className="options">
                          <ListItemText
                            primary="Videos"
                            style={{
                              color:
                                selectedOption === "videos"
                                  ? "#3C95E8"
                                  : "#000",
                              borderBottom:
                                selectedOption === "videos"
                                  ? "4px solid #3C95E8"
                                  : "none",
                              textAlign: "center",
                              paddingBottom: 15,
                              margin: 0,
                            }}
                            onClick={() => setSelectedOption("videos")}
                            className="tabname"
                          />
                        </ListItem>
                      </List>
                      <ListItem
                        button
                        className="share-option"
                        style={{ padding: "0", height: "50px" }}
                      >
                        <ListItemText>
                          <div
                            className="share-cont"
                            onClick={() => setShareIcons(true)}
                            // onMouseLeave={() => setShareIcons(false)}
                          >
                            <Tooltip title="Share Public Profile">
                              <IconButton
                                aria-label="share"
                                style={{
                                  padding: 0,
                                  background: "rgb(243, 90, 87)",
                                  color: "#fff",
                                  borderRadius: 4,
                                }}
                                onClick={handleClickOpen}
                              >
                                <Box className="share-mobile-icon">
                                  <Share />
                                </Box>
                                <Box className="share-desk-icon">
                                  <ListItem style={{ fontSize: 14 }} button>
                                    <FontAwesomeIcon
                                      size="1x"
                                      icon={faShare}
                                      style={{ marginRight: 8 }}
                                    />{" "}
                                    Share
                                  </ListItem>
                                </Box>
                              </IconButton>
                            </Tooltip>

                            {shareIcons && userData && userData.username && (
                              <ShareOnSocialMedia
                                open={open}
                                handleClose={() => setOpen(false)}
                                quote={userData && userData.summary}
                                url={shortUrl}
                                subject={`[Touch-A-Life] Have you seen this profile "${userData.username}"?`}
                                content={`Hello,<br /><br />I thought you might be interested in this profile of ${userData.username} profile:<br /> <a href="${shortUrl}">Click Here</a> to view the public profile
                                    <br /><br /> Thanks, <br />Touch-A-Life Foundation`}
                              />
                            )}
                          </div>
                        </ListItemText>
                      </ListItem>
                    </div>
                  }
                />
              </div>
            </Fragment>
          )}
          {selectedOption === "timeline" && (
            <div className="public-page-body">
              {noUser ? (
                <h3 className="no-user-text">
                  No user found with the username...
                </h3>
              ) : (
                <Grid container spacing={2} className="public-body-grid">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className="public-grid-left"
                  >
                    <StickyBox
                      offsetTop={20}
                      offsetBottom={20}
                      className="drop-box"
                      style={{ padding: "15px" }}
                    >
                      <PublicProfileSideBar
                        editMode={editMode}
                        userData={userData}
                        viewAllPhotos={viewAllPhotosHandler}
                        viewAllVideos={viewAllVideosHandler}
                        images={images}
                        videos={videos}
                        onSavingImage={loadImages}
                        onSavingVideo={loadVideos}
                      />
                    </StickyBox>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className="public-grid-right"
                  >
                    <Fragment>
                      {donationRequests && donationRequests.length > 0
                        ? donationRequests.map((donationRequest, index) => {
                            return (
                              <DonationRequestCard
                                donationRequest={donationRequest}
                                setDonationRequests={setDonationRequests}
                                donationRequests={donationRequests}
                                key={index}
                              />
                            );
                          })
                        : !isLoading &&
                          donationRequests &&
                          donationRequests.length == 0 && (
                            <p className="no-req-text">No requests created</p>
                          )}
                    </Fragment>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    className="public-grid-news"
                  >
                    <StickyBox
                      offsetTop={20}
                      offsetBottom={20}
                      className="right-panel"
                    >
                      <div>
                        <div
                          className=" no-pad promoBlk "
                          style={{ padding: "0" }}
                        >
                          <div className="promotion">
                            <Link
                              href={process.env.REACT_APP_TALRADIO_URL}
                              target="blank"
                            >
                              <img src="/images/radio.png" alt="promotions" />
                            </Link>
                          </div>
                        </div>
                        {topDonationRequests &&
                          topDonationRequests.length > 0 && (
                            <div className=" no-pad topReqListBlk drop-box">
                              <div className="topReqList">
                                <Card className={classes.root} elevation={0}>
                                  <CardContent>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      className="topReqListTitle"
                                    >
                                      Top Requests
                                    </Typography>
                                    <ul>
                                      {topDonationRequests.map(
                                        (donationRequest, index) => {
                                          return (
                                            <li key={index}>
                                              <img
                                                src={
                                                  donationRequest.defaultImageUrl
                                                    ? donationRequest.defaultImageUrl
                                                    : "/images/default-donation-request.jpg"
                                                }
                                                alt="Top Request"
                                                onClick={() =>
                                                  donationRequestClickHandler(
                                                    donationRequest._id
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              />{" "}
                                              <span
                                                onClick={() =>
                                                  donationRequestClickHandler(
                                                    donationRequest._id
                                                  )
                                                }
                                                style={{ cursor: "pointer" }}
                                              >
                                                {donationRequest.title}
                                              </span>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </CardContent>
                                </Card>
                              </div>
                            </div>
                          )}
                      </div>
                    </StickyBox>
                  </Grid>
                </Grid>
              )}
            </div>
          )}

          {selectedOption === "about" && (
            <div className="public-page-body about-option">
              <div className="about-section">
                <h2>About</h2>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <Box
                        style={{
                          borderRight: "1px solid #FAFDF4",
                          minHeight: "100%",
                        }}
                      >
                        {userData && userData.facebookProfileUrl && (
                          <p>
                            <img
                              src="/images/public_facebook.png"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.facebookProfileUrl}
                              target="blank"
                            >
                              My Facebook Page
                            </a>
                          </p>
                        )}
                        {userData && userData.instagramProfileUrl && (
                          <p>
                            <img
                              src="/images/public_instagram.png"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.instagramProfileUrl}
                              target="blank"
                            >
                              My Instagram Page
                            </a>
                          </p>
                        )}
                        {userData && userData.twitterProfileUrl && (
                          <p>
                            <img
                              src="/images/public_twitter.png"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.twitterProfileUrl}
                              target="blank"
                            >
                              My Twitter Page
                            </a>
                          </p>
                        )}
                        {userData && userData.linkedInProfileUrl && (
                          <p>
                            <img
                              src="/images/public_linkedin.webp"
                              alt="fb"
                              style={{ width: 24, marginRight: 6 }}
                            />
                            <a
                              href={userData && userData.linkedInProfileUrl}
                              target="blank"
                            >
                              My LinkedIn Page
                            </a>
                          </p>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={9}>
                      <Box style={{ background: "#FAFDF4", padding: "10px" }}>
                        <p
                          style={{
                            color: "rgb(205, 38, 39)",
                            fontWeight: "bold",
                            margin: "0",
                          }}
                        >
                          {userData && userData.title}
                        </p>
                        <p
                          style={{
                            fontWeight: "normal",
                            marginTop: "10px",
                            whiteSpace: "pre-line",
                            lineHeight: "1.5",
                          }}
                        >
                          {ReactHtmlParser(userData && userData.summary)}
                        </p>
                        <p
                          style={{
                            fontWeight: "normal",
                            marginTop: "10px",
                            whiteSpace: "pre-line",
                            lineHeight: "1.5",
                          }}
                        >
                          {ReactHtmlParser(userData && userData.aboutMe)}
                        </p>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>
          )}

          {selectedOption === "photos" && (
            <div className="public-page-body photos-option">
              <div className="photos-section">
                <h2>Photos</h2>
                {images.length > 0 ? (
                  images.map((image) => (
                    <div className="images-container">
                      <img src={image.url} alt={image.name} width="100%" />
                    </div>
                  ))
                ) : (
                  <Box>
                    <p className="no-req-text">No photos uploaded</p>
                  </Box>
                )}
              </div>
            </div>
          )}

          {selectedOption === "videos" && (
            <div className="public-page-body videos-option">
              <div className="videos-section">
                <h2>Videos</h2>
                {videos.length > 0 ? (
                  videos.map((video, index) => (
                    <div className="images-container">
                      <PodcastVideoPlayer
                        key={index}
                        isOpen={isPlayingVideo}
                        onClose={onClosePlayVideo}
                        onPlayVideo={() => onPlayVideo(video)}
                        url={video.url}
                        userGivenName={video.userGivenName}
                        note={video.note}
                      />
                    </div>
                  ))
                ) : (
                  <p className="no-req-text">No videos uploaded</p>
                )}
              </div>
            </div>
          )}

          <AddPhotos
            title="Manage Cover Photo"
            isOpen={showCoverPhoto}
            onClose={() => setShowCoverPhoto(false)}
            uid={authUser && authUser.unique_id}
            collection="CoverPictures"
            profilephotoURL={coverImageUrl}
            onProfilephotoURLChange={updateCoverImageUrl}
            canEdit={true}
          />
          <AddPhotos
            title="Manage Profile Photo"
            isOpen={showProfilePhoto}
            onClose={() => setShowProfilePhoto(false)}
            uid={authUser && authUser.unique_id}
            collection="ProfilePictures"
            profilephotoURL={profileImageUrl}
            onProfilephotoURLChange={updateDefaultImageUrl}
            canEdit={true}
          />
          <PaymentsPopUp
            isOpen={isPaymentsPopUpOpen}
            donationFor="individual"
            toUserInfo={userData && userData.unique_id}
            onClose={() => setIsPaymentsPopUpOpen(false)}
          />
        </div>
      </Container>
      <Loader isOpen={isLoading} onClose={(e) => setIsLoading(false)} />
    </div>
  );
};

export default PublicProfile;
