import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cuid from "cuid";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// import "./Login.scss";
import useFormValidation from "../../../hooks/useFormValidation";
import validateLogin from "../validateLogin";
import { login, resendOTP } from "../../../utils/api";
import { DASHBOARD_PAGE } from "../../../utils/utils";
import { appContext } from "../../../App";
import firebase from "../../../firebase/firebase";
import { Box, OutlinedInput } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import LogDesign from "../Logdesign";

const useStyles = makeStyles((theme) => ({
  Paper: {
    minHeight: "100vh",
  },

  icons: {
    display: "flex",
    gridGap: "25px",
    justifyContent: "center",

    "& img": {
      cursor: "pointer",
    },
  },

  sign_right: {
    padding: "2rem",
    minHeight: "100vh",

    "& p:last-child": {
      textAlign: "left",
      "& strong": {
        cursor: "pointer",
      },
    },
    "& section": {
      padding: "2rem",

      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0 0",
      },

      "& hr": {
        position: "relative",
        top: "20px",
      },

      "& label": {
        display: "block",
        color: "#696F79",
        padding: "1rem 0",
      },

      "& h4": {
        fontWeight: "800",
        // textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

        marginBottom: "20px",
        color: "#273d48",
        fontSize: "32px",
        textAlign: "center",
      },

      "& small": {
        color: "#BABABA",
        display: "block",
        position: "relative",
        backgroundColor: "white",
        padding: "0 5px 0 5px",
        left: "50%",
        top: "-4px",
        width: "20px",
      },

      "& FormControl": {
        display: "block",
      },
    },
  },
}));

const LogIn = (props) => {
  const { setAuthUser, setHideLoginButton } = useContext(appContext);
  const [showPassword, setShowPassword] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const initialState = {
    account: "",
    password: "",
  };

  const classes = useStyles();
  const loginHandler = () => {
    login(values)
      .then((response) => {
        if (response.data.data.account_verified === true) {
          localStorage.setItem("authUser", JSON.stringify(response.data.data));
          localStorage.setItem(
            "stripeCustomerId",
            response.data.data.stripeCustomerId !== ""
              ? response.data.data.stripeCustomerId
              : "null"
          );
          if (rememberMe) {
            localStorage.setItem("rememberMe", "true");
            localStorage.setItem("account", values.account);
            localStorage.setItem("password", values.password);
          } else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("account");
            localStorage.removeItem("password");
          }
          setAuthUser(response.data.data);
          const redirectUri = localStorage.getItem("redirectUri");
          // if (!response.data.data.isProfileCompleted) {
          //   props.history.push(
          //     `/newUserProfile/${response.data.data.unique_id}`
          //   );
          // } else
          if (redirectUri) {
            localStorage.removeItem("redirectUri");
            props.history.push(redirectUri);
          } else if (props.location.state) {
            props.history.push(props.location.state.from.pathname);
          } else {
            props.history.push(DASHBOARD_PAGE);
          }
          firebase.analytics.logEvent("login", { method: "email" });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            const obj = {};
            obj.email = values.account;
            resendOTP(obj)
              .then(() => {
                toastr.error(
                  "Your email is not yet verified. Please complete the email verification."
                );
                props.history.push({
                  pathname: `/otp-validation/${values.account}`,
                  state: { password: values.password },
                });
              })
              .catch((error) => {
                toastr.error(error.message);
              });
          } else if (error.response.data.statusCode === 404) {
            toastr.error(error.response.data.message);
          } else {
            toastr.error(
              error.response.data.message ||
                "Unable to login. Please try again!"
            );
          }
        }
      });
  };

  const googleLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/google/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        '&state={"clientRedirectUrl":"' +
        process.env.REACT_APP_BASE_URL +
        '","provider":"google"}',
      "_self"
    );
  };

  const facebookLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/facebook/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        '&state={"clientRedirectUrl":"' +
        process.env.REACT_APP_BASE_URL +
        '","provider":"facebook"}',
      "_self"
    );
  };

  const linkedInLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/linkedin/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        '&state={"clientRedirectUrl":"' +
        process.env.REACT_APP_BASE_URL +
        '","provider":"linkedin"}',
      "_self"
    );
  };

  const twitterLoginHandler = () => {
    window.open(
      process.env.REACT_APP_API_BASE_URL +
        "/twitter/auth?clientId=" +
        cuid() +
        "&redirectUri=" +
        process.env.REACT_APP_AUTH_REDIRECT_URL +
        '&state={"clientRedirectUrl":"' +
        process.env.REACT_APP_BASE_URL +
        '","provider":"twitter"}',
      "_self"
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      submitHandler(event);
    }
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateLogin, loginHandler);

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe && rememberMe === "true") {
      setValues({
        account: localStorage.getItem("account"),
        password: localStorage.getItem("password"),
      });
      setRememberMe(true);
    }
  }, []);

  const showRegisterScreen = () => {
    props.history.push("/signup");
  };

  const showForgotPassword = () => {
    props.history.push("/forgot-password");
  };

  useEffect(() => {
    const localAuthUser = localStorage.getItem("authUser");
    if (localAuthUser) {
      localStorage.removeItem("authUser");
      localStorage.clear();
      window.location.reload();
    }
    setHideLoginButton(true);
    return () => {
      setHideLoginButton(false);
    };
  }, []);

  return (
    <Box component="main" mt={-4}>
      <Grid container>
        <Grid item md={6} sm={12} xs={12}>
          <LogDesign />
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Paper elevation={0}>
            <Box component="aside" className={classes.sign_right}>
              <Box component="section">
                <Box component="h4">Login to TALTransformers</Box>
                {/* <Box component="div" className={classes.icons}>
                  <img
                    src="/images/google_icon.svg"
                    alt="google"
                    width="25px"
                    onClick={googleLoginHandler}
                  />
                  <img
                    src="/images/facebook_icon.svg"
                    alt="facebook"
                    width="13px"
                    onClick={facebookLoginHandler}
                  />
                  <img
                    src="/images/linkedin_icon.svg"
                    alt="linkedin"
                    width="25px"
                    onClick={linkedInLoginHandler}
                  />
                  <img
                    src="/images/twitter_icon.svg"
                    alt="twitter"
                    width="25px"
                    onClick={twitterLoginHandler}
                  />
                </Box>

                <hr />
                <Box component="small">or</Box> */}

                <Box component="article">
                  <Box component="label">
                    Email address <span style={{ color: "red" }}>*</span>
                  </Box>
                  <TextField
                    name="account"
                    fullWidth
                    placeholder="Email address or Username"
                    value={values.account}
                    onChange={changeHandler}
                    variant="outlined"
                    onKeyPress={(e) => handleEnterClick(e)}
                    className={`${errors.account && "error-text"}`}
                    id="email"
                  />
                  {errors.account ? (
                    <Box component="p" my={1} className="tal_primary_color ">
                      {errors.account}
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box component="label" py={1}>
                    Password <span style={{ color: "red" }}>*</span>
                  </Box>
                  <OutlinedInput
                    className={`${errors.password && "error-text"}`}
                    id="outlined-adornment-confirm-password"
                    name="password"
                    fullWidth
                    placeholder="Password"
                    variant="outlined"
                    type={showPassword ? "password" : "text"}
                    value={values.password}
                    onChange={changeHandler}
                    onKeyPress={(e) => handleEnterClick(e)}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        classNmae={classes.showpassword}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Tooltip title="Click to Show the Password">
                              <VisibilityOff />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Click to hide the Password">
                              <Visibility />
                            </Tooltip>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  {errors && (
                    <Box component="p" my={1} className="tal_primary_color ">
                      {errors.password}
                    </Box>
                  )}
                </Box>

                <Box
                  component="p"
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  <span>
                    <input
                      style={{ cursor: "pointer", marginRight: 4 }}
                      type="checkbox"
                      id="remember_me"
                      name="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    Remember me
                  </span>

                  <span
                    onClick={showForgotPassword}
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password?
                  </span>
                </Box>

                <Button
                  className="tal_primary_bg "
                  variant="contained"
                  fullWidth
                  type="submit"
                  onClick={submitHandler}
                >
                  Login
                </Button>

                <Box component="p" mt={4}>
                  {" "}
                  Don't have an account?{" "}
                  <span onClick={showRegisterScreen}>
                    <strong className="tal_primary_color"> Sign up</strong>
                  </span>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
export default withRouter(LogIn);
