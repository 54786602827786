import React from "react";
import { Grid, Typography, Tooltip, IconButton } from "@material-ui/core";
import { Icon } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const LearningLabsMedia = (props) => {
  return (
    <Grid item md={3} xs={12} className="learn-img">
      <Grid>
        <Tooltip title="Click to open">
          {props.document &&
          (props.document.type == 1 || props.document.type == 2) ? (
            <IconButton className="del-btn" onClick={props.onPlayHandler}>
              <img
                src={
                  props.thumbnailUrl
                    ? props.thumbnailUrl
                    : "/images/default-thumbnail.jpeg"
                }
                alt="podcast"
              />
            </IconButton>
          ) : (
            <a className="del-btn" href={`${props.media}`} target="_blank">
              <img
                src={
                  props.thumbnailUrl
                    ? props.thumbnailUrl
                    : "/images/default-thumbnail.jpeg"
                }
                alt="podcast"
              />
            </a>
          )}
        </Tooltip>
        <div className="podcast-detail-cont">
          <LightTooltip title={props.title} className="learn-tool">
            <Typography component="h2">{props.title}</Typography>
          </LightTooltip>
          {/* <Typography component="p">{props.note}</Typography> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default LearningLabsMedia;
