import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import toastr from "toastr";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  changeUsername,
  addOrganisationUser,
  updateProfile,
  updateTeamMember,
  updateJudge,
  updateMentor,
} from "./../utils/api";
import { appContext } from "./../App";
import { HOME_PAGE } from "../utils/utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    textAlign: "center",
    width: "30%",
    margin: "0 auto",
    height: 520,
    position: "relative",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: 20,
    boxShadow: theme.shadows[5],
    position: "absolute",
    top: "28%",
  },
}));

export default function EditUsername(props) {
  const {
    authUser,
    setAuthUser,
    orgUnRegisteredUser,
    orgUserPayload,
    inviteGeneralUserPayload,
    generalUnRegisteredUser,
    teamUserPayload,
    teamUnRegisteredUser,
    judgePayload,
    judgeUnRegistered,
    mentorPayload,
    mentorUnRegistered,
  } = useContext(appContext);
  const classes = useStyles();
  const [flag, setFlag] = React.useState(false);
  const [editUsername, setUsername] = React.useState("");

  useEffect(() => {
    setUsername(authUser && authUser.username);
  }, [authUser]);

  const handleEditNext = () => {
    if (authUser && orgUserPayload && orgUnRegisteredUser === true) {
      const obj = { ...orgUserPayload };
      obj.user = authUser.unique_id;
      obj.userId = authUser.unique_id;
      addOrganisationUser(obj)
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            props.history.push(HOME_PAGE);
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          props.history.push("/");
        });
    } else if (
      authUser &&
      inviteGeneralUserPayload &&
      generalUnRegisteredUser === true
    ) {
      const obj = { ...inviteGeneralUserPayload };
      updateProfile(authUser.unique_id, {
        roles: [...new Set([...authUser.roles, ...obj.role])],
        languages: obj.languages,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            props.history.push(HOME_PAGE);
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          props.history.push("/");
        });
    } else if (authUser && teamUserPayload && teamUnRegisteredUser === true) {
      const obj = { ...teamUserPayload };
      updateTeamMember(obj.teamMemberId, {
        status: "accepted",
        userId: authUser.unique_id,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            props.history.push("/");
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          props.history.push("/");
        });
    } else if (authUser && judgePayload && judgeUnRegistered === true) {
      const obj = { ...judgePayload };
      updateJudge(obj.judgeId, {
        status: "accepted",
        userId: authUser.unique_id,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            props.history.push("/");
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          props.history.push("/");
        });
    } else if (authUser && mentorPayload && mentorUnRegistered === true) {
      const obj = { ...mentorPayload };
      updateMentor(obj.mentorId, {
        status: "accepted",
        userId: authUser.unique_id,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success(response.data.message);
            props.history.push("/");
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          props.history.push("/");
        });
    } else {
      props.history.push(HOME_PAGE);
    }
  };

  const changeHandler = (e) => {
    let username = e.target.value;
    setUsername(username);
  };

  const handleUpdateUsername = () => {
    if (!editUsername) {
      toastr.error("Username can not be empty");
      return;
    }
    let finalValues = {};
    finalValues.username = editUsername;
    finalValues.account = authUser.email;
    finalValues.access_token = authUser.token_detail.token;
    if (finalValues.username !== "") {
      changeUsername(finalValues)
        .then((response) => {
          setAuthUser(response.data.data);
          toastr.success("User Name Updated Successfully");
          props.history.push(HOME_PAGE);
          if (authUser && orgUserPayload && orgUnRegisteredUser === true) {
            const obj = { ...orgUserPayload };
            obj.userId = authUser.unique_id;
            addOrganisationUser(obj)
              .then((response) => {
                if (response.data.statusCode === 200) {
                  toastr.success(response.data.message);
                }
              })
              .catch((error) => {
                toastr.error(error.response.data.message);
              });
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            toastr.error("That username is taken. Try another");
          } else {
            toastr.error(error.response.data.message);
          }
        });
    } else {
      toastr.error("Please enter a valid username");
    }
  };
  const changeUserName = () => {
    setFlag(true);
  };

  return (
    <div className={classes.container}>
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}> */}
      {flag && flag === true ? (
        <div
          className={classes.paper}
          style={{ width: "484px", height: "197px" }}
        >
          <h2 style={{ font: "600 16px Roboto", textAlign: "center" }}>
            Change User Name
          </h2>
          <TextField
            variant="filled"
            placeholder="edit username"
            name="editUsername"
            className="input-field edit-username"
            style={{ background: "#fff !important" }}
            value={editUsername}
            onChange={changeHandler}
          />
          <br />
          <br />
          <Button
            style={{
              background: "var(--tal_primary) ",
              color: "#fff",
              border: "none",
            }}
            onClick={handleUpdateUsername}
          >
            Change User Name
          </Button>
          <Button
            style={{
              background: "var(--tal_primary) ",
              color: "#fff",
              border: "none",
              margin: "0 10px",
            }}
            onClick={() => props.history.push(HOME_PAGE)}
          >
            Skip
          </Button>
        </div>
      ) : (
        <div
          className={classes.paper}
          style={{ width: "484px", height: "197px" }}
        >
          <h2 style={{ font: "600 16px Roboto", textAlign: "center" }}>
            Welcome to TALTransformers
          </h2>
          <br />
          <h4 style={{ font: "600 16px Roboto", textAlign: "center" }}>
            {editUsername}
          </h4>
          <br />
          <Button
            style={{
              background: "var(--tal_primary) ",
              color: "#fff",
              border: "none",
              width: "36%",
            }}
            onClick={handleEditNext}
          >
            Next
          </Button>
          <br />
          <Button
            style={{
              background: "transparent",
              color: "#1744D6",
              font: "500 14px Roboto",
              margin: "10px 0",
              border: "none",
              textTransform: "capitalize",
            }}
            onClick={changeUserName}
          >
            Change Username
          </Button>
          {/* <span style={{ 'margin-left': '27px', 'font-size': '14px', color: 'blue', 'cursor': 'pointer' }}
            onClick={changeUserName}>Change User Name</span> */}
        </div>
      )}
      {/* </Fade>
      </Modal> */}
    </div>
  );
}
