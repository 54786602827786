import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import getBlobDuration from "get-blob-duration";
import { saveShortUrl } from "./api";
export const DATE_FORMAT = "DD-MMM-YYYY";
export const DATE_FORMAT_FULL = "dddd, Do MMMM YYYY";
export const DATE_TIME_FORMAT = "DD-MMM-YYYY hh:mm A";
export const DATE_FORMAT_FULL_MONTH = "MMMM DD,YYYY";
export const DATE_TIME_FORMAT_FULL = "dddd, Do MMMM YYYY, hh:mm A";
export const DATE_TIME_FORMAT_FULL_PICKER = "dd-MMMM-yyyy, hh:mm a";
export const DATE_TIME_TIMEZONE_FORMAT_FULL = "dddd, Do MMMM YYYY, hh:mm A ZZ";
export const TIME_FORMAT = "hh:mm A";
export const TIME_FORMAT_SECONDS = "HH:mm:ss";
export const HOME_PAGE = "/";
export const DASHBOARD_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const TALMEDIA_PAGE = "/talmedia";
export const AVATAR_DONOR = "Donor";
export const AVATAR_DONEE = "Donee";
export const STATUS_NEW = "0";
export const STATUS_APPROVED = "1";
export const STATUS_COMPLETED = "2";
export const STATUS_ALL = "";
export const STATUS_REJECTED = "-1";
export const STATUS_INCOMPLETE = "-2";
export const STATUS_SPAM = "-3";
export const STATUS_HOLD = "-6";
export const STATUS_EXPRIED = "-4";
export const STATUS_DEACTIVATED = "-5";
export const ROLE_OWNER = "owner";
export const ROLE_ADMIN = "admin";
export const ROLE_MEMBER = "member";
export const REQUEST_TYPE_CASH = "cash";
export const REQUEST_TYPE_PLASMA = "plasma";
export const REGION_ID_INDIA = "IN";
export const REGION_ID_USA = "US";
export const COUNTRY_CODE_ISO3_INDIA = "IND";
export const COUNTRY_CODE_ISO3_USA = "USA";
export const CURRENCY_USD = "USD";
export const CURRENCY_INR = "INR";
export const DONATION_STATUS_INITIATED = 1;
export const DONATION_STATUS_PENDING = 2;
export const DONATION_STATUS_COMPLETED = 3;
export const PAYMENT_METHOD_STRIPE_ACH = "stripe-ach";
export const STRIPE_ACH_CHARGE_STATUS_PENDING = "pending";
export const STRIPE_ACH_CHARGE_STATUS_COMPLETED = "completed";
export const MAX_RATING_PER_FIELD = 5;
export const DONATION_REQUEST_STATUS_MAP = {
  0: {
    shortLabel: "Pending from TAL Admin",
    label: "Pending from TAL Admin",
    bgcolor: "info.main",
    color: "info.contrastText",
    ribbonBgColor: "PENDING",
    ribbonTextColor: "text_white",
  },
  1: {
    shortLabel: "Approved",
    label: "APPROVED",
    bgcolor: "success.main",
    color: "success.contrastText",
    ribbonBgColor: "APPROVED",
    ribbonTextColor: "text_white",
  },
  2: {
    shortLabel: "Fulfilled",
    label: "FULFILLED",
    bgcolor: "success.main",
    color: "success.contrastText",
    ribbonBgColor: "FULFILLED",
    ribbonTextColor: "text_white",
  },
  3: {
    shortLabel: "Pending from Org Admin",
    label: "PENDING FROM ORG ADMIN",
    bgcolor: "info.main",
    color: "info.contrastText",
    ribbonBgColor: "pending-org",
    ribbonTextColor: "text_white",
  },

  "-1": {
    shortLabel: "Rejected",
    label: "REJECTED",
    bgcolor: "error.main",
    color: "error.contrastText",
    ribbonBgColor: "REJECTED",
    ribbonTextColor: "text_white",
  },
  "-2": {
    shortLabel: "Incomplete",
    label: "INCOMPLETE INFORMATION BY DONEE",
    bgcolor: "text.disabled",
    color: "background.paper",
    ribbonBgColor: "INCOMPLETE",
    ribbonTextColor: "text_white",
  },
  "-3": {
    shortLabel: "Spam",
    label: "SPAM",
    bgcolor: "text.secondary",
    color: "background.paper",
    ribbonBgColor: "SPAM",
    ribbonTextColor: "text_white",
  },
  "-4": {
    shortLabel: "Expired",
    label: "EXPIRED",
    bgcolor: "warning.main",
    color: "warning.contrastText",
    ribbonBgColor: "EXPIRED",
    ribbonTextColor: "text_white",
  },
  "-5": {
    shortLabel: "Deactivated",
    label: "DEACTIVATED",
    bgcolor: "warning.main",
    color: "warning.contrastText",
    ribbonBgColor: "DEACTIVATED",
    ribbonTextColor: "text_white",
  },
  "-6": {
    shortLabel: "On Hold",
    label: "ON HOLD",
    bgcolor: "warning.main",
    color: "warning.contrastText",
    ribbonBgColor: "PENDING",
    ribbonTextColor: "text_white",
  },
  "-7": {
    shortLabel: "Rejected by Org Admin",
    label: "REJECTED BY ORG ADMIN",
    bgcolor: "error.main",
    color: "error.contrastText",
    ribbonBgColor: "rejected-org",
    ribbonTextColor: "text_white",
  },
};

export const REQUEST_TYPES = [
  { key: "career", value: "Career Help" },
  { key: "homework help", value: "Homework Help" },
  { key: "internship", value: "Internship" },
  { key: "volunteering", value: "Volunteering" },
  { key: "scholarship", value: "Scholarship" },
];

export const CONTENT_TYPE_EXTENSIONS_MAP = {
  "audio/mpeg": ".mp3",
  "video/mpeg": ".mpeg",
  "audio/ogg": ".oga",
  "video/ogg": ".ogv",
  "audio/opus": ".opus",
  "audio/wav": ".wav",
  "audio/webm": ".weba",
  "video/webm": ".webm",
  "video/3gpp": ".3gp",
  "audio/3gpp": ".3gp",
  "video/3gpp2": ".3g2",
  "audio/3gpp2": ".3g2",
  "audio/aac": ".aac",
  "video/x-msvideo": ".avi",
  "audio/midi": ".mid",
  "audio/x-midi": ".midi",
};

export const GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX = "_776x435";

export const getGoogleCloudStorageSuffixedUrl = (url) => {
  if (url) {
    if (url.indexOf(GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX) < 0) {
      return url.replace(
        "?",
        GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX + "?"
      );
    } else {
      return url;
    }
  } else {
    return url;
  }
};

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const formatScore = (value) => {
  if (value >= 1000000) {
    value = (value / 1000000).toFixed(2) + "M";
  } else if (value >= 1000) {
    value = (value / 1000).toFixed(1) + "K";
  } else if (value >= 1000000000000) {
    value = (value / 1000000000000).toFixed(1) + "B";
  }
  return value;
};

export const formatNumber = (number) => {
  if (isNaN(number)) {
    return number;
  }
  if (number) {
    return Number(number).toLocaleString();
  }
  return number;
};

export const replaceStringUsingRegex = (string) => {
  if (string) {
    return string.replace(/[#',]/gi, "");
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isImage = (contentType) => {
  return (
    contentType === "image/jpeg" ||
    contentType === "image/gif" ||
    contentType === "image/png" ||
    contentType === "image/bmp" ||
    contentType === "image/tiff" ||
    contentType === "image/svg+xml" ||
    contentType === "image/webp"
  );
};

export const isAudio = (contentType) => {
  if (contentType) {
    return (
      contentType.indexOf("audio") >= 0 ||
      contentType.indexOf("application/octet-stream") >= 0
    );
  }
  return false;
};

export const isValidPassword = (password) => {
  let msg = "";
  if (!password) {
    msg += "Password is required. ";
  }

  if (!password.match(/[a-z]/)) {
    msg += "Contains at least one letter. ";
  }

  if (!password.match(/[A-Z]+/)) {
    msg += "Contains at least one capital letter. ";
  }

  if (!password.match(/\d/)) {
    msg += "Contains at least one digit. ";
  }

  if (!password.match(/[^a-zA-Z0-9\-/]/)) {
    msg += "Contains at least one special character. ";
  }

  if (password.length < 6) {
    msg += "Contains at least 6 characters. ";
  }

  return msg;
};

export const areUrlsSame = (url1, url2) => {
  if (url1) {
    url1 = url1.substring(0, url1.indexOf("?"));
  }
  if (url2) {
    url2 = url2.substring(0, url2.indexOf("?"));
  }
  return url1 === url2;
};

export const fetchAndCreateObject = async function (fileUrl) {
  console.log("fetchAndCreateObject URL ==> ", fileUrl);
  if (!fileUrl) {
    return;
  }

  try {
    const fetchFromUrl = await fetch(fileUrl);
    const blobData = fetchFromUrl.blob();
    return blobData;
  } catch (e) {
    throw e.message;
  }
};

export const getBlobFromUrl = async function (fileUrls = []) {
  if (!fileUrls) {
    return [];
  }

  let blobFiles = [];
  let index = 0;
  for (const eachFileUrl of fileUrls) {
    const blobContent = await fetchAndCreateObject(eachFileUrl);
    blobFiles.push(blobContent);
    if (index === fileUrls.length - 1) {
      return blobFiles;
    }
    index++;
  }
};

export const mergeAudioFiles = async (selectedFiles) => {
  const ffmpeg = createFFmpeg({
    log: false,
  });

  //load ffmpeg library
  await ffmpeg.load();

  let concatCmd = [];

  for (let i = 0; i < selectedFiles.length; i++) {
    const fetchedFile = await fetchFile(URL.createObjectURL(selectedFiles[i]));
    ffmpeg.FS("writeFile", `f${i + 1}.mp3`, fetchedFile);
    concatCmd.push(`file f${i + 1}.mp3`);
    if (i === selectedFiles.length - 1) {
      return new Promise(async (resolve, reject) => {
        ffmpeg.FS("writeFile", "concat_list.txt", concatCmd.join("\n"));
        await ffmpeg.run(
          "-f",
          "concat",
          "-safe",
          "0",
          "-i",
          "concat_list.txt",
          "finaloutput.mp3"
        );

        //await ffmpeg.run("-i", concatCmd, "-safe", "0", "finaloutput.mp3");
        const finaloutput = ffmpeg.FS("readFile", "finaloutput.mp3");

        //find file duration
        const fileDuration = await getBlobDuration(
          new Blob([finaloutput.buffer], { type: "audio/mp3" })
        );

        await ffmpeg.run("unlink", "finaloutput.mp3");
        await ffmpeg.run("unlink", "concat_list.txt");

        resolve({
          file: finaloutput,
          duration: fileDuration && parseInt(fileDuration),
        });
      });
    }
  }
};

export const getObjectValuesInStringFormat = (obj, concat = ", ") => {
  if (!obj) {
    return "";
  }
  for (var propName in obj) {
    if (!obj[propName] || obj[propName] === true) {
      delete obj[propName];
    }
  }
  return Object.values(obj).join(concat);
};
export function arraysEqual(a, b) {
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i]._id !== b[i]._id) return false;
  }
  return true;
}
export function getDonationRequestSharedUrl(donationRequest = {}) {
  let url =
    process.env.REACT_APP_BASE_URL +
    "/donationRequest/" +
    "#name#?uniqueId=#_id#&name=#name#&type=donationRequest";
  if (donationRequest) {
    url = url.replaceAll("#name#", donationRequest.name);
    url = url.replace("#_id#", donationRequest._id);
    url = url.replace("#title#", donationRequest.title);
    return encodeURI(url);
  }
}

export function getAudioRoomSessionSharedUrl(chatRoom = {}) {
  let url = process.env.REACT_APP_CHAT_SERVER_BASE_URL + "/join/#_id#";

  if (chatRoom) {
    url = url.replace("#_id#", chatRoom && chatRoom._id);
    return encodeURI(url);
  } else {
    console.log("error occured");
    return;
  }
}

export function convertFirstCharacterToUppercase(stringToConvert) {
  var firstCharacter = stringToConvert.substring(0, 1);
  var restString = stringToConvert.substring(1);

  return firstCharacter.toUpperCase() + restString;
}

export function podcastShareUrl(podcastItem = {}) {
  let url =
    process.env.REACT_APP_BASE_URL +
    "/talmedia/#fileName#?note=#note#&path=/talmedia/#fileName#&contentType=#contentType#&type=podcast&uniqueId=#unique_id#&name=#name#&thumbnailUrl=#thumbnailUrl#";
  if (podcastItem) {
    url = url.replace(/#fileName#/g, podcastItem.file);
    url = url.replace("#note#", podcastItem.note);
    url = url.replace("#contentType#", podcastItem.contentType);
    url = url.replace("#unique_id#", podcastItem.file);
    url = url.replace("#name#", podcastItem.name);
    url = url.replace("#thumbnailUrl#", podcastItem.thumbnailUrl);
    return encodeURI(url);
  } else {
    console.log("error occured");
    return;
  }
}

// Capitalizes first letter of each word of a given string
export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

// get value of a query string from the URL
export function getQueryStringValue(key = null) {
  if (!key) return;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return params.get(key) || null;
}

export function roundToTwoDecimalPlaces(num) {
  return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
}

export const getShortUrl = (url, representsWhat, referenceId) => {
  return new Promise((resolve, reject) => {
    const payload = {
      url,
      representsWhat,
      referenceId,
    };

    saveShortUrl(payload)
      .then((response) => {
        resolve(response.data.data.shortUrl);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getKeyByValue(object, value) {
  return Object.keys(object).filter((key) => object[key] == value);
}
export const PERKS = [
  "Certificate",
  "Flexible Work Hours",
  "Informal Dress Code",
  "Letter Of Recommendation",
  "5 days a week",
  "Free Snacks and Beverages",
];

/**
 *
 * @param {*} url => talgiving.org
 * @returns => http://talgiving.org
 *
 * @param {*} url => www.talgiving.org
 * @returns => http://www.talgiving.org
 */
export function checkUrlWithHttp(url = "") {
  return url && !/^https?:\/\//i.test(url) ? `http://${url}` : url;
}

export function getCountry(result) {
  return result.data.filter(
    (country) => country.sortname === "IN" || country.sortname === "US"
  );
}

export function validateUrl(userURL) {
  try {
    var url = new URL(userURL);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (err) {
    return false;
  }
}
