import React, { useState, useEffect, useContext, Fragment } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableSortLabel,
  Link,
  Typography,
  TablePagination,
} from "@material-ui/core";

import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import { HOME_PAGE } from "../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";

import {
  getVolunteers,
  updateVolunteerApplicationStatus,
} from "../../utils/api";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "username", numeric: false, disablePadding: true, label: "UserName" },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "volunteerApplicationStatus",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  { id: "action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ background: "#000", color: "#fff" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            className="table-header-text"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  container: {
    padding: "0 10px",
    width: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const VolunteerApplications = (props) => {
  const { authUser, isAdmin, isVolunteer, isSuperAdmin } = useContext(
    appContext
  );
  const [volunteers, setVolunteers] = useState([]);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("state");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getStatusLabel = (status) => {
    if (status === -1) {
      return "Rejected";
    } else if (status === 0) {
      return "New";
    } else if (status === 1) {
      return "Approved";
    }
  };

  const loadVolunteers = (userId) => {
    getVolunteers(userId)
      .then((response) => {
        if (response && response instanceof Array && response.length > 0) {
          setVolunteers(response);
        }
      })
      .catch((error) => {
        toastr.error("Problem in fetching donation requests:" + error.message);
      });
  };

  useEffect(() => {
    if (isAdmin || isVolunteer || (isSuperAdmin && authUser)) {
      loadVolunteers(authUser.unique_id);
    } else {
      toastr.warning(
        "You do not have the privilege to access the requested page!"
      );
      props.history.push(HOME_PAGE);
    }
  }, []);

  const approve = (volunteerId) => {
    updateStatus(volunteerId, 1);
  };

  const reject = (volunteerId) => {
    updateStatus(volunteerId, -1);
  };

  const updateStatus = (volunteerId, status) => {
    updateVolunteerApplicationStatus(volunteerId, status)
      .then((response) => {
        setVolunteers((volunteers) => {
          return volunteers.map((volunteer) => {
            if (volunteer._id === volunteerId) {
              return {
                ...volunteer,
                volunteerApplicationStatus: status,
              };
            }
            return volunteer;
          });
        });
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <div className="barnav">
        <Container MaxWidth="lg">
          <div style={{ display: "flex" }}>
            {authUser && (
              <Button onClick={handleCloseSideMenu}>
                {!closeSideMenu && <MenuIcon />}
                {closeSideMenu && <ArrowBackIcon />}
              </Button>
            )}

            <h4>Manage TALTransformers</h4>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg">
        <div className="myDonations-container">
          <div className="menu-container">
            {closeSideMenu && (
              <div style={{ marginRight: "10px" }}>
                <SideMenu />
              </div>
            )}

            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(
                    volunteers && volunteers,
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            <Link
                              href={`/volunteerProfile/${row._id}`}
                              target="blank"
                            >
                              {row.username}
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            {row.name.first_name + " " + row.name.last_name}
                          </TableCell>
                          <TableCell align="left">
                            {getStatusLabel(row.volunteerApplicationStatus)}
                          </TableCell>
                          <TableCell align="left">
                            {row.volunteerApplicationStatus === 0 && (
                              <Fragment>
                                <Tooltip title="Approve the Application">
                                  <Link
                                    onClick={(e) => approve(row._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Approve
                                  </Link>
                                </Tooltip>
                                {" / "}
                                <Tooltip title="Reject the Application">
                                  <Link
                                    onClick={(e) => reject(row._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Reject
                                  </Link>
                                </Tooltip>
                              </Fragment>
                            )}
                            {row.volunteerApplicationStatus === 1 && (
                              <Fragment>
                                <Tooltip title="Reject the Application">
                                  <Link
                                    onClick={(e) => reject(row._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Reject
                                  </Link>
                                </Tooltip>
                              </Fragment>
                            )}
                            {row.volunteerApplicationStatus === -1 && (
                              <Fragment>
                                <Tooltip title="Approve the Application">
                                  <Link
                                    onClick={(e) => approve(row._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Approve
                                  </Link>
                                </Tooltip>
                              </Fragment>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {volunteers && volunteers.length === 0 && (
                <Typography className="no-req-text">
                  No TALScout Found
                </Typography>
              )}
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                component="div"
                count={volunteers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{
                  position: "sticky",
                  background: "white",
                  bottom: "0",
                  borderTop: "1px solid #eaeaea",
                }}
              />
            </TableContainer>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default VolunteerApplications;
