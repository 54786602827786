import React, { useState, useContext, useEffect, Fragment } from "react";
import toastr from "toastr";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { MenuItem, Select, InputLabel } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "./DonationRequestForm.scss";
import { appContext } from "../../App";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import validateDonationRequest from "./validateDonationRequest";
import useFormValidation from "../../hooks/useFormValidation";
import {
  createDonationRequest,
  updateDonationRequest,
  getDonationRequest,
} from "../../utils/api";
import NewAddress from "../common/NewAddress";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { STATUS_INCOMPLETE, STATUS_NEW, titleCase } from "../../utils/utils";

const DonationRequestForm = (props) => {
  const { authUser, isSuperAdmin, isAdmin, isVolunteer, categories } =
    useContext(appContext);
  const initialState = {
    requestType: "cash",
    cashUnit: "USD",
    status: "0",
    timeUnit: "HOURS",
  };
  const [category, setCategory] = React.useState(null);
  const [id, setId] = useState("");
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [dueDate, setDueDate] = useState(moment().add(1, "month"));
  const [address, setAddress] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [description, setDescription] = useState("");
  const [isDonee, setIsDonee] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.donationRequestId) {
      setIsLoading(true);
      setEditMode(true);
      loadDonationRequest(props.donationRequestId);
    }
  }, [props.donationRequestId]);

  const saveDonationRequest = () => {
    const finalValues = values;
    const errors = {};
    if (!description) {
      extraErrors.description = "Please write a story for your fundraiser.";
    } else {
      finalValues.description = description;
    }
    finalValues.request_type = values.requestType;
    finalValues.sourceOfCreation = "talscouts";
    if (values.requestType === "cash") {
      finalValues.quantity = values.cashQuantity;
      finalValues.units = values.cashUnit;
    } else if (values.requestType === "kind") {
      finalValues.quantity = values.kindQuantity;
      finalValues.units = values.kindUnit;
      finalValues.requested_for = values.requestedFor;
    } else if (values.requestType === "time") {
      finalValues.quantity = values.timeQuantity;
      finalValues.units = values.timeUnit;
    } else if (values.requestType === "help") {
      finalValues.requested_for = values.requestedFor;
      finalValues.additionalInfo = values.additionalInfo;
      finalValues.additionalInfo.fullname = values.fullname;
    }
    if (new Date(startDate).getTime() === 0) {
      extraErrors.startDate = "Please select the Start Date";
    } else {
      finalValues.start_date = new Date(startDate).getTime();
    }
    if (new Date(dueDate).getTime() === 0) {
      extraErrors.endDate = "Please select the Expiry Date";
    } else {
      finalValues.due_date = new Date(dueDate).getTime();
    }
    finalValues.user_id = authUser.unique_id;
    finalValues.brief = values.brief;
    finalValues.fundsRecipient = values.fundsRecipient;
    finalValues.fundsRecipientName = values.fundsRecipientName;
    if (category && category._id) {
      finalValues.tags = [category._id];
    } else if (values && values.tags) {
      finalValues.tags = values.tags;
    } else {
      errors.category = "Please select a category";
    }
    finalValues.defaultImageUrl = defaultImageUrl;
    finalValues.whyYouNeedHelp = values.whyYouNeedHelp;
    if (address) {
      const obj = {};
      obj.line1 = address.line1;
      obj.line2 = address.line2;
      obj.city =
        values.city && values.requestType === "plasma"
          ? values.city
          : address.city;
      obj.state = address.state;
      obj.zip_code = address.zip;
      obj.country = address.country;
      finalValues.shipping_address = obj;
    } else {
      errors.shippingAddress = "Please provide an address for this request";
    }
    if (isDonee && finalValues.status === STATUS_INCOMPLETE) {
      finalValues.status = STATUS_NEW;
    }
    if (Object.keys(errors).length > 0) {
      setExtraErrors(errors);
    } else if (editMode) {
      updateDonationRequest(finalValues, id)
        .then((response) => {
          toastr.success(
            `${titleCase(values.requestType)} Request is updated successfully.`
          );
          props.onSave(response);
        })
        .catch((error) => {
          if (error.message === "Request failed with status code 409") {
            toastr.error("Please enter unique values for title and rank");
          }
        });
    } else {
      createDonationRequest(finalValues)
        .then((response) => {
          toastr.success("Donation Request is created successfully.");
          props.history.push(`/donationRequest/${response._id}`);
        })
        .catch((error) => {
          toastr.error(error.response.message);
        });
    }
  };

  const loadDonationRequest = (donationRequestId) => {
    getDonationRequest(donationRequestId)
      .then((response) => {
        setId(response._id);
        setDefaultImageUrl(response.defaultImageUrl);
        setStartDate(response.start_date);
        setDueDate(response.due_date);
        setAddress({
          line1: response.shipping_address.line1,
          line2: response.shipping_address.line2,
          city: response.shipping_address.city,
          state: response.shipping_address.state,
          zip: response.shipping_address.zip_code,
          country: response.shipping_address.country,
        });
        setValues((currentValues) => {
          return {
            ...currentValues,
            requestType: response.request_type,
            fundsRecipient: response.fundsRecipient,
            fundsRecipientName: response.fundsRecipientName,
            title: response.title,
            tags: response.tags,
            status: response.status,
            rank: response.rank,
            brief: response.brief,
            whyYouNeedHelp: response.whyYouNeedHelp,
            city: response.shipping_address.city,
            creatorType: response.creatorType,
          };
        });
        if (response && response.description) {
          setDescription(response.description);
        }
        if (response.request_type === "cash") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              cashQuantity: response.quantity,
              cashUnit: response.units,
            };
          });
        } else if (response.request_type === "help") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              requestedFor: response.requested_for,
              requestedForDetails: response.requestedForDetails,
              additionalInfo: response.additionalInfo,
              fullname:
                response.additionalInfo && response.additionalInfo.fullname,
            };
          });
        } else if (response.request_type === "kind") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              requestedFor: response.requested_for,
              kindQuantity: response.quantity,
              kindUnit: response.units,
            };
          });
        } else if (response.request_type === "time") {
          setValues((currentValues) => {
            return {
              ...currentValues,
              timeQuantity: response.quantity,
              timeUnit: response.units,
            };
          });
        }
        if (authUser && authUser.unique_id === response.user_info._id) {
          setIsDonee(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(error.message);
      });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleChangeAddress = (newAddress) => {
    setAddress(newAddress);
  };

  const onConfirm = () => {
    saveDonationRequest();
  };

  const categoryChangeHandler = (event) => {
    setCategory(
      categories.find((category) => event.target.value === category._id)
    );
  };
  const changeDescriptionHandler = (e, editor) => {
    setDescription(editor.getData());
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateDonationRequest, onConfirm);
  return (
    <div className="make-req-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="make-req-container"
        open={props.open}
        onClose={props.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className="request-container ">
            <div className="req-main-title drop-box">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  align="center"
                  variant="h2"
                  style={{ color: "rgb(205, 38, 39)" }}
                >
                  {titleCase(values && values.requestType) + " Request"}
                </Typography>
                <span
                  className="close-modal drop-box"
                  onClick={props.close}
                  style={{
                    padding: "10px",
                    borderRadius: "100%",
                    height: "28px",
                    lineHeight: "0.4",
                    cursor: "pointer",
                  }}
                >
                  x
                </span>
              </div>
            </div>
            <form
              noValidate
              autoComplete="off"
              className="request-form cash-request-from drif_data"
            >
              {values.requestType === "cash" && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <TextField
                      type="number"
                      required
                      className="input-field cash"
                      variant="outlined"
                      label="Amount"
                      name="cashQuantity"
                      value={values.cashQuantity}
                      onChange={changeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <div>
                      {errors && errors.cashQuantity && (
                        <Typography className="custom-error">
                          {errors.cashQuantity}
                        </Typography>
                      )}
                      <Select
                        name="cashUnit"
                        value={values.cashUnit}
                        className="input-field currency"
                        disabled
                      >
                        {
                          <MenuItem value={values.cashUnit}>
                            {values.cashUnit}
                          </MenuItem>
                        }
                      </Select>
                    </div>
                  </Grid>
                </Grid>
              )}
              {values.requestType === "help" && (
                <Fragment>
                  <div>
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      style={{ top: 8, left: 5 }}
                    >
                      Covid Help Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values && values.requestedFor}
                      onChange={changeHandler}
                      variant="outlined"
                      className={`input-field request-amount ${
                        errors && errors.help
                          ? "bg-red"
                          : values.help
                          ? "bg-green"
                          : "bg-normal"
                      }`}
                      name="requestedFor"
                      label="Covid Help Type *"
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"Oxygen"}>Oxygen</MenuItem>
                      <MenuItem value={"Groceries"}>Groceries</MenuItem>
                      <MenuItem value={"Meal Services"}>Meal Services</MenuItem>
                      <MenuItem value={"Mental Health"}>Mental Health</MenuItem>
                      <MenuItem value={"Medicine"}>Medicine</MenuItem>
                      <MenuItem value={"Hospital Admission"}>
                        Hospital Admission
                      </MenuItem>
                      <MenuItem value={"Doctor help"}>Doctor help</MenuItem>
                      <MenuItem value={"Home ICU setup"}>
                        Home ICU setup
                      </MenuItem>
                      <MenuItem value={"Ambulance Service"}>
                        Ambulance Service
                      </MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                  </div>
                  <div>
                    <TextField
                      variant="outlined"
                      placeholder="Enter additional information about your request"
                      label="Additional information"
                      className="input-field requestedfor"
                      name="requestedForDetails"
                      value={values.requestedForDetails}
                      onChange={changeHandler}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="outlined"
                      placeholder="Patient Full Name"
                      label="Patient Full Name"
                      className="input-field requestedfor"
                      name="fullname"
                      value={values.fullname}
                      onChange={changeHandler}
                    />
                  </div>
                </Fragment>
              )}
              {values.requestType === "kind" && (
                <Fragment>
                  <div>
                    <TextField
                      required
                      variant="outlined"
                      placeholder="What do you need?"
                      label="Requested For"
                      className="input-field requestedfor"
                      value={values.requestedFor}
                      onChange={changeHandler}
                    />
                  </div>

                  <TextField
                    type="number"
                    required
                    variant="outlined"
                    className="input-field quantity"
                    label="How many? (Quantity)"
                    name="kindQuantity"
                    value={values.kindQuantity}
                    onChange={changeHandler}
                  />
                  {errors && errors.kindQuantity && (
                    <Typography className="custom-error">
                      {errors.kindQuantity}
                    </Typography>
                  )}
                  <TextField
                    required
                    variant="outlined"
                    className="input-field size"
                    label="Size (If it applies)"
                    name="kindUnit"
                    value={values.kindUnit}
                    onChange={changeHandler}
                  />
                </Fragment>
              )}
              {values.requestType === "time" && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type="number"
                      required
                      variant="outlined"
                      className="input-field time"
                      label="How many hours?"
                      name="timeQuantity"
                      value={values.timeQuantity}
                      onChange={changeHandler}
                    />
                    {errors && errors.timeQuantity && (
                      <Typography className="custom-error">
                        {errors.timeQuantity}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled
                      required
                      variant="outlined"
                      className="input-field hours"
                      label="Hours"
                      name="timeUnit"
                      value={values.timeUnit}
                      onChange={changeHandler}
                    />
                  </Grid>
                </Grid>
              )}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2} className="placeholder">
                  {editMode && (isSuperAdmin || isAdmin || isVolunteer) && (
                    <Grid item xs={12} sm={6}>
                      <label>When will this request start?</label>
                      <KeyboardDatePicker
                        showTodayButton
                        format="dd-MMM-yyyy"
                        margin="normal"
                        name="startDate"
                        placeholder="When will this request start?"
                        className="input-field startdate"
                        value={startDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      {extraErrors && extraErrors.startDate && (
                        <Typography className="custom-error">
                          {extraErrors.startDate}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {values.requestType === "plasma" &&
                  values.creatorType === "donor" ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={6}>
                      <label>When will this request expire?</label>
                      <KeyboardDatePicker
                        format="dd-MMM-yyyy"
                        style={{ width: "100%" }}
                        margin="normal"
                        name="dueDate"
                        className="input-field duedate"
                        placeholder="When will this request expire?"
                        value={dueDate}
                        onChange={handleDueDateChange}
                        disablePast
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      {extraErrors && extraErrors.endDate && (
                        <Typography className="custom-error">
                          {extraErrors.endDate}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </MuiPickersUtilsProvider>

              <div>
                {props.userEdit && props.userEdit === true ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div style={{ display: "inline-block", width: "100%" }}>
                        <Select
                          name="fundsRecipient"
                          value={values.fundsRecipient}
                          className="input-field"
                          style={{
                            border: "1px solid #ccc",
                            padding: 10,
                            borderBottom: "none",
                          }}
                          onChange={changeHandler}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          // disabled
                        >
                          <MenuItem value={"self"}>Self</MenuItem>
                          <MenuItem value={"Non Profit Organization"}>
                            Non Profit Organization
                          </MenuItem>
                          <MenuItem value={"School"}>School</MenuItem>
                          <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                      </div>
                      {values.fundsRecipient !== "" &&
                        values.fundsRecipient.toLowerCase() !== "self" && (
                          <div style={{ width: "100%" }}>
                            <TextField
                              type="text"
                              required
                              className="input-field title"
                              placeholder={`Enter ${
                                values.fundsRecipient ===
                                "Non Profit Organization"
                                  ? "Organization name"
                                  : values.fundsRecipient === "School"
                                  ? "School name"
                                  : "here"
                              }`}
                              name="fundsRecipientName"
                              value={values.fundsRecipientName}
                              onChange={changeHandler}
                            />
                            {errors && errors.fundsRecipientName && (
                              <div className="custom-error">
                                {errors.fundsRecipientName}
                              </div>
                            )}
                          </div>
                        )}
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </div>
              <div>
                {props.userEdit && props.userEdit === true ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div>
                        <Select
                          value={
                            category && category._id
                              ? category._id
                              : values.tags
                          }
                          className="input-field"
                          onChange={categoryChangeHandler}
                          labelId="demo-simple-select-outlined-label"
                          style={{
                            border: "1px solid #ccc",
                            padding: 10,
                            borderBottom: "none",
                          }}
                          id="demo-simple-select-outlined"
                          // disabled
                        >
                          {categories &&
                            categories.length > 0 &&
                            categories.map((category) => {
                              return (
                                <MenuItem value={category._id}>
                                  {category.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: "relative" }}>
                  <TextField
                    required
                    variant="outlined"
                    label="Give a Title to your request"
                    className="input-field title"
                    name="title"
                    value={values.title}
                    onChange={changeHandler}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  <span className="admin-title-length">
                    {" "}
                    {values.title ? values.title.length : 0}/50
                  </span>
                  {errors && errors.title && (
                    <Typography className="custom-error">
                      {errors.title}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {values &&
                  values.requestType !== "internship" &&
                  values.requestType !== "volunteering" && (
                    <Grid item xs={12} style={{ position: "relative" }}>
                      <TextField
                        multiline
                        rows={2}
                        required
                        variant="outlined"
                        label="Give a brief to your request"
                        fullWidth
                        name="brief"
                        value={values.brief}
                        onChange={changeHandler}
                        inputProps={{
                          maxLength: "120",
                          spellCheck: true,
                        }}
                      />
                      <span className="admin-title-length">
                        {" "}
                        {values.brief ? values.brief.length : 0}/120
                      </span>
                      {errors && errors.brief && (
                        <Typography className="custom-error">
                          {errors.brief}
                        </Typography>
                      )}
                    </Grid>
                  )}
              </Grid>
              {values && values.requestType === "cash" && (
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ position: "relative" }}>
                    <label
                      className="justi-label"
                      style={{
                        position: "absolute",
                        top: "5px",
                        left: "25px",
                        fontSize: "15px !important",
                      }}
                    >
                      Justify why you need help from others?
                    </label>
                    <TextareaAutosize
                      name="whyYouNeedHelp"
                      variant="outlined"
                      multiline
                      rows="4"
                      style={{ padding: 10, border: "1px solid #ccc" }}
                      className={"input-field request-title justifyToOthers"}
                      label="Justify why you need help from others"
                      defaultValue={values && values.whyYouNeedHelp}
                      onChange={changeHandler}
                    />
                    {errors && errors.whyYouNeedHelp && (
                      <Typography className="custom-error">
                        {errors.whyYouNeedHelp}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
              {(values.requestType === "plasma" &&
                values.creatorType === "donor") ||
              (values.requestType === "help" &&
                values.creatorType === "donee") ? (
                ""
              ) : (
                <div>
                  <p style={{ paddingTop: "25px", marginLeft: "5px" }}>
                    Description
                  </p>
                  <CKEditor
                    placeholder="Enter Description"
                    data={description}
                    editor={ClassicEditor}
                    onChange={changeDescriptionHandler}
                    config={{
                      toolbar: [
                        "Heading",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "Link",
                      ],
                    }}
                  />
                  {extraErrors && extraErrors.description && (
                    <Typography className="custom-error">
                      {extraErrors.description}
                    </Typography>
                  )}
                </div>
              )}
              {values && values.requestType === "plasma" ? (
                <div style={{ position: "relative" }}>
                  <TextField
                    required
                    variant="outlined"
                    label="Location"
                    className="input-field city"
                    name="city"
                    value={values.city}
                    onChange={changeHandler}
                  />
                  {errors && errors.city && (
                    <Typography className="custom-error">
                      {errors.city}
                    </Typography>
                  )}
                </div>
              ) : (
                <div className="address">
                  <NewAddress value={address} onChange={handleChangeAddress} />
                  {extraErrors && extraErrors.shippingAddress && (
                    <Typography className="custom-error">
                      {extraErrors.shippingAddress}
                    </Typography>
                  )}
                </div>
              )}
              {editMode && (isSuperAdmin || isAdmin || isVolunteer) && (
                <Fragment>
                  <div>
                    <Typography>Status</Typography>
                    <Select
                      name="status"
                      value={values.status}
                      className="input-field currency"
                      style={{ width: "100%" }}
                      onChange={changeHandler}
                    >
                      <MenuItem value="1">Approved</MenuItem>
                      <MenuItem value="0">New</MenuItem>
                      <MenuItem value="2">Completed</MenuItem>
                      <MenuItem value="-1">Rejected</MenuItem>
                      <MenuItem value="-2">Incomplete</MenuItem>
                      <MenuItem value="-3">Spam</MenuItem>
                      <MenuItem value="-4">Expired</MenuItem>
                    </Select>
                  </div>
                  <div>
                    <TextField
                      type="number"
                      required
                      variant="outlined"
                      className="input-field time"
                      style={{ width: "100%" }}
                      label="Rank"
                      name="rank"
                      value={values.rank}
                      onChange={changeHandler}
                    />
                    {errors && errors.rank && (
                      <Typography className="custom-error">
                        {errors.rank}
                      </Typography>
                    )}
                  </div>
                </Fragment>
              )}
              <div style={{ display: "grid", placeContent: "center" }}>
                <Button
                  style={{ width: "200px" }}
                  className="submit-btn"
                  onClick={submitHandler}
                  disabled={isLoading}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(DonationRequestForm);
