import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import "./styles.scss";

const DropzoneInput = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!props.disabled) {
        props.setFiles(
          acceptedFiles.map((acceptedFile) =>
            Object.assign(acceptedFile, {
              preview: URL.createObjectURL(acceptedFile),
            })
          )
        );
      }
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: !!props.multiple,
    accept: props.accept,
  });

  return (
    <div
      {...getRootProps()}
      className={"dropzone " + (isDragActive && "dropzone--isActive")}
    >
      <input {...getInputProps()} disabled={props.disabled} />
      <div className="dropzone-container">
        <IconButton
          style={props.disabled ? { cursor: "default" } : { cursor: "pointer" }}
        >
          <CloudUploadIcon fontSize="large" />
        </IconButton>
        <Typography>Drag & Drop Files</Typography>
        <Typography> or click to select</Typography>
      </div>
    </div>
  );
};

export default DropzoneInput;
