import React, { useRef, useContext, useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import toastr from "toastr";
import PrintIcon from "@material-ui/icons/Print";
import "./custom-styles.css";
import { appContext } from "../../App";
import PrintCertificate from "./printCertificate";
import { updateProfile } from "../../utils/api";
import Headers from "../Transformers/Header";
import SideMenu from "../Transformers/SideMenu";

const ReadingEventCertificate = () => {
  const { authUser, setAuthUser, configuration } = useContext(appContext);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      updateProfile(authUser.unique_id, { isCertificationDownloaded: true })
        .then((response) => {
          setAuthUser(response.data.data);
          toastr.success("Certificate downloaded successfully");
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    },
  });

  useEffect(() => {}, [configuration && configuration.enableViewCertificate]);

  return (
    <>
      <Headers />
      <div className="desktop-menu">
        <SideMenu />
      </div>
      <Container maxWidth="xl" className="tal-container">
        {configuration && !configuration.enableViewCertificate && (
          <div style={{ marginTop: "40px" }} className="text-center mb-3">
            Certificate View is disabled
          </div>
        )}
        <div className="text-center mb-3">
          {configuration && configuration.enableViewCertificate && (
            <Box
              component="Button"
              color="rgb(243, 90, 87)"
              bgcolor="white"
              borderRadius="50px"
              width="40px"
              height="40px"
              border="1px solid rgb(243, 90, 87)"
              marginBottom="20px"
              onClick={handlePrint}
            >
              {configuration && configuration.enableViewCertificate && (
                <PrintIcon />
              )}
            </Box>
          )}
          {configuration && configuration.enableViewCertificate && (
            <PrintCertificate ref={printRef} authUser={authUser} />
          )}
        </div>
      </Container>
    </>
  );
};

export default ReadingEventCertificate;
