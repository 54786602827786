import React, { useState, useEffect, Fragment } from "react";

import toastr from "toastr";
import cuid from "cuid";
import firebase from "../../firebase/firebase";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import DropzoneInput from "./DropzoneInput";
import CropperInput from "./CropperInput";
import { Dialog, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Loader from "./Loader";
import "./AddPhotos.scss";

import { checkImage } from "../../utils/api";
import {
  getGoogleCloudStorageSuffixedUrl,
  GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX,
  areUrlsSame,
} from "../../utils/utils";
import { Edit } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddPhotos = (props) => {
  const { profileRequired = true, multiple = false } = props;
  const [files, setFiles] = useState([]);
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [blob, setBlob] = useState(null);
  const [profilephotoURL, setProfilephotoURL] = useState("");
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [croppedImageIndex, setCroppedImageIndex] = useState(-1);
  useEffect(() => {
    if (props.collection && props.uid) {
      let path = `${props.collection}/${props.uid}`;
      if (props.subCollection && props.subCollectionUid) {
        path = path + "/" + props.subCollection + "/" + props.subCollectionUid;
      }
      path = path + "/" + (props.folder ? props.folder : "images");
      const storageRef =
        props.collection === "DonationRequest"
          ? firebase.storageDonationRequests.ref()
          : firebase.storage.ref();
      const pathRef = storageRef.child(path);
      pathRef.listAll().then((result) => {
        result.items.forEach((item) => {
          item.getDownloadURL().then((url) => {
            item.getMetadata().then((metadata) => {
              setImages((prevImages) => [
                ...prevImages,
                { url: url, name: metadata.name },
              ]);
            });
          });
        });
      });
    }
  }, [props.collection, props.uid]);
  useEffect(() => {
    setProfilephotoURL(props.profilephotoURL);
  }, [props.profilephotoURL]);

  function checkAndUploadImage() {
    setIsLoading(true);
    const path = `temp`;
    const name = cuid();
    const fullPath = path + "/" + name;
    const storageRef =
      props.collection === "DonationRequest"
        ? firebase.storageDonationRequests.ref()
        : firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .put(files[0])
      .then((snapshot) => {
        storageRef
          .child(fullPath)
          .getDownloadURL()
          .then((url) => {
            const values = {
              imageUrl: url,
            };
            checkImage(values)
              .then((response) => {
                if (response.data === true) {
                  alert(
                    "The image you are trying to upload is violating the terms and conditions of Touch-A-Life Foundation. Please try with a different image."
                  );
                  URL.revokeObjectURL(files[0].preview);
                  setFiles([]);
                  setCroppedImageUrl("");
                  setIsLoading(false);
                } else {
                  uploadImage(name);
                }
                deleteTempImage(name);
              })
              .catch((error) => {
                uploadImage(name);
                deleteTempImage(name);
              });
          });
      })
      .catch((error) => {
        toastr.error(error.message);
        setIsLoading(false);
      });
  }

  async function uploadImage() {
    setError("");
    if (files.length === 0) {
      setError("Please select atleast one image");
      return;
    }
    setIsLoading(true);
    const promises = [];
    files.forEach((file, index) => {
      let name = cuid();
      let path = `${props.collection}/${props.uid}`;
      if (props.subCollection && props.subCollectionUid) {
        path = path + "/" + props.subCollection + "/" + props.subCollectionUid;
      }
      path = path + "/" + (props.folder ? props.folder : "images");
      const fullPath = path + "/" + name;
      const storageRef =
        props.collection === "DonationRequest"
          ? firebase.storageDonationRequests.ref()
          : firebase.storage.ref();
      const pathRef = storageRef.child(fullPath);

      var uploadTask = storageRef.child(fullPath).put(file);
      promises.push(uploadTask);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          // Handle unsuccessful uploads
          toastr.error(error.message);
          setIsLoading(false);
        },
        async () => {
          await uploadTask.snapshot.ref.getDownloadURL().then((url) => {
            if (props.collection === "DonationRequest") {
              setTimeout(() => {
                url = getGoogleCloudStorageSuffixedUrl(url);
                name = name + GOOGLE_CLOUD_STORAGE_DONATION_REQUEST_SUFFIX;
                setImages((prevImages) => [
                  ...prevImages,
                  { url: url, name: name },
                ]);
                if (!profilephotoURL && index === 0) {
                  updateProfilePhoto(url);
                }
                if (props.onSavingPhoto) {
                  props.onSavingPhoto(url, name);
                }
              }, 7000);
            } else {
              setImages((prevImages) => [
                ...prevImages,
                { url: url, name: name },
              ]);
              if (!profilephotoURL && index === 0) {
                updateProfilePhoto(url);
              }
              if (props.onSavingPhoto) {
                props.onSavingPhoto(url, name);
              }
            }
            if (files.length === 1) {
              URL.revokeObjectURL(files[0].preview);
              setCroppedImageUrl("");
            }
          });
        }
      );
    });
    Promise.all(promises)
      .then(() => {
        if (files.length === 1) {
          toastr.success("Image is uploaded successfully");
        } else if (files.length > 1) {
          toastr.success(files.length + " images are uploaded successfully");
        }
        setFiles([]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error(error);
      });
  }

  const deleteImage = (name) => {
    let path = `${props.collection}/${props.uid}`;
    if (props.subCollection && props.subCollectionUid) {
      path = path + "/" + props.subCollection + "/" + props.subCollectionUid;
    }
    path = path + "/" + (props.folder ? props.folder : "images");
    const fullPath = path + "/" + name;
    const storageRef =
      props.collection === "DonationRequest"
        ? firebase.storageDonationRequests.ref()
        : firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .delete()
      .then(() => {
        setImages(images.filter((image) => image.name !== name));
        toastr.success("The image is successfully deleted from the database");
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const deleteTempImage = (name) => {
    const fullPath = "temp/" + name;
    const storageRef =
      props.collection === "DonationRequest"
        ? firebase.storageDonationRequests.ref()
        : firebase.storage.ref();
    const pathRef = storageRef.child(fullPath);
    pathRef
      .delete()
      .then(() => {})
      .catch(() => {});
  };

  const updateProfilePhoto = (url) => {
    if (props.onProfilephotoURLChange) {
      props.onProfilephotoURLChange(url);
    }
    setProfilephotoURL(url);
  };
  const deleteLocalImage = (name) => {
    const local = files.filter((image) => image.name !== name);
    setFiles(local);
    setCroppedImageUrl("");
  };

  const updateCroppedImage = () => {
    if (croppedImageIndex !== -1) {
      files[croppedImageIndex] = new File([blob], blob.name, {
        type: blob.type,
      });
      files[croppedImageIndex].preview = URL.createObjectURL(blob);
      setFiles(files);
      setCroppedImageIndex(-1);
      setCroppedImageUrl("");
      setBlob(null);
    }
  };
  const cancelChanges = () => {
    setCroppedImageIndex(-1);
    setCroppedImageUrl("");
    setBlob(null);
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={Transition}
      className="upload-cntnr"
    >
      <div className="addphoto">
        <div className="dialog-box">
          <h2 className="image-title">{props.title}</h2>
          <div className="close-image-popup">
            <IconButton onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
          {props.note && <h4>{props.note}</h4>}
        </div>

        <div>
          {props.canEdit === true && (
            <div className="add-image-container">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <div className="part-1">
                    <Typography variant="h4">Add Image(s)</Typography>
                    {props &&
                      props.path &&
                      props.path.includes("/transformers/SubmitIdea") && (
                        <p style={{ color: "red" }}>
                          Please upload only .JPG, .JPEG and .PNG files
                        </p>
                      )}
                    <DropzoneInput
                      setFiles={setFiles}
                      multiple={multiple}
                      accept="image/*"
                    />
                  </div>
                </Grid>
                {croppedImageIndex !== -1 && (
                  <>
                    <Grid item xs={12} md={4}>
                      <div className="part-2">
                        <Typography variant="h4">
                          Step 2 - Resize Image
                        </Typography>
                        {files[croppedImageIndex] && (
                          <CropperInput
                            file={files[croppedImageIndex]}
                            setCroppedImageUrl={setCroppedImageUrl}
                            setBlob={setBlob}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <div className="part-3">
                        <Typography variant="h4">
                          Step 3 - Preview and Upload
                        </Typography>
                        {croppedImageUrl && (
                          <div>
                            <figure>
                              <img src={croppedImageUrl} alt="preview" />
                            </figure>
                            <div
                              style={{
                                paddingTop: "10px",
                                display: "flex",
                                flexDirection: "row",
                                gridGap: "10px",
                                background: "#fff",
                              }}
                            >
                              {props.canEdit && (
                                <Fragment>
                                  <Tooltip title="Delete This Image">
                                    <Button
                                      onClick={cancelChanges}
                                      style={{
                                        width: "50%",
                                        background: "red",
                                        color: "#fff",
                                        padding: "12px 0",
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Save Changes">
                                    <Button
                                      onClick={updateCroppedImage}
                                      style={{
                                        background: "green",
                                        color: "white",
                                        width: "50%",
                                        padding: "12px 0",
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </Tooltip>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </>
                )}
                {files.length > 0 && (
                  <>
                    <Grid item xs={12} md={12}>
                      <div className="part-2">
                        <div className="upload-img-btn">
                          <Typography variant="h4">Selected Images</Typography>

                          <Button
                            onClick={uploadImage}
                            style={{
                              background: "green",
                              color: "white",
                              width: "15%",
                              padding: "12px 0",
                            }}
                          >
                            Upload Image(s)
                            {isLoading && (
                              <CircularProgress
                                size={24}
                                className="buttonProgress"
                              />
                            )}
                          </Button>
                        </div>
                        <div className="selected-image-container">
                          {files.map((image, index) => {
                            return (
                              <div key={index} className="selected-image-child">
                                <figure>
                                  <img src={image.preview} alt="display" />
                                </figure>
                                <div
                                  style={{
                                    paddingTop: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {props.canEdit && (
                                    <Fragment>
                                      <Tooltip title="Edit This Image">
                                        <IconButton
                                          className="del-btn"
                                          onClick={() =>
                                            setCroppedImageIndex(index)
                                          }
                                        >
                                          <Edit />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete This Image">
                                        <IconButton
                                          className="del-btn"
                                          onClick={(e) =>
                                            deleteLocalImage(image.name)
                                          }
                                        >
                                          <DeleteForeverIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          )}

          {images && images.length > 0 && (
            <div className="selected-image-container">
              {images.map((image, index) => {
                return (
                  <div key={index} className="selected-image-child">
                    <figure>
                      <img src={image.url} alt="display" />
                    </figure>
                    {props.canEdit && (
                      <Fragment>
                        {areUrlsSame(image.url, profilephotoURL) ? (
                          <div>
                            {profileRequired && (
                              <Typography variant="h4">
                                This is the profile image
                              </Typography>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              paddingTop: "10px",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {profileRequired && (
                              <Button
                                className="profile-btn"
                                onClick={(e) => updateProfilePhoto(image.url)}
                              >
                                Make This Profile Image
                              </Button>
                            )}

                            <IconButton
                              className="del-btn"
                              onClick={(e) => deleteImage(image.name)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <Loader isOpen={isLoading} onClose={() => setIsLoading(false)} />
    </Dialog>
  );
};

export default AddPhotos;
