import React, { createContext, useState, useEffect } from "react";
import toastr from "toastr";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import "./App.css";
import Routes from "./routes";
import { blue, indigo } from "@material-ui/core/colors";
import {
  getRegions,
  getUserLocaleInfo,
  getUserSummary,
  getUserNotifications,
  getOrganizationList,
  getMyContacts,
  getTALDonorsContacts,
  getMyContactGroups,
  addPushNotificationDeviceToken,
  getCurrentTime,
  getConfigurations,
} from "./utils/api";
import axios from "axios";
import Loader from "./components/common/Loader";
import { AVATAR_DONOR, AVATAR_DONEE } from "./utils/utils";
import firebase from "./firebase/firebase";

export const appContext = createContext();

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[900],
    },
    primary: {
      main: indigo[700],
    },
  },
  typography: {
    fontFamily: ['"Roboto"', "sans-serif"].join(","),
  },
});

const App = () => {
  const [authUser, setAuthUser] = useState(null);
  const [hideLoginButton, setHideLoginButton] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isJudge, setIsJudge] = useState(false);
  const [isFinAdmin, setIsFinAdmin] = useState(false);
  const [isTALRadioAdmin, setIsTALRadioAdmin] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isVolunteer, setIsVolunteer] = useState(false);
  const [userLocaleInfo, setUserLocaleInfo] = useState(null);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(null);
  const [paymentRegion, setPaymentRegion] = useState(null);
  const [avatars, setAvatars] = useState([]);
  const [avatar, setAvatar] = useState(AVATAR_DONOR);
  const [donationDetails, setDonationDetails] = useState(null);
  const [categories, setCategories] = useState([]);
  const [userSummary, setUserSummary] = React.useState(null);
  const [isVolunteerApplicationFlow, setIsVolunteerApplicationFlow] =
    useState(false);
  const [rjPayload, setRJPayload] = useState(null);
  const [orgUserPayload, setOrgUserPayload] = useState(null);
  const [orgUnRegisteredUser, setOrgUnRegisteredUser] = useState(false);
  const [unRegisteredRJ, setUnRegisteredRJ] = useState(false);

  const [teamUserPayload, setTeamUserPayload] = useState(null);
  const [teamUnRegisteredUser, setTeamUnRegisteredUser] = useState(false);
  const [judgePayload, setJudgePayload] = useState(null);
  const [judgeUnRegistered, setJudgeUnRegistered] = useState(false);
  const [inviteGeneralUserPayload, setInviteGeneralUserPayload] =
    useState(null);
  const [generalUnRegisteredUser, setGeneralUnRegisteredUser] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [talmediaLanguage, setTalmediaLanguage] = useState("english");
  const [currentDonationRequest, setCurrentDonationRequest] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [talContacts, setTalContacts] = useState([]);
  const [emailContactType, setEmailContactType] = useState("mycontacts");
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [currentDonationRequestName, setCurrentDonationRequestName] =
    useState(null);
  const [contactGroups, setContactGroups] = useState([]);
  const [
    selectedContactsForEmailCampaign,
    setSelectedContactsForEmailCampaign,
  ] = useState([]);
  const [selectedUplodingFileContacts, setSelectedUplodingFileContacts] =
    useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedContactGroups, setSelectedContactGroups] = useState([]);
  const [contactsByGroupName, setContactsByGroupName] = useState({});
  const [contactsCountByGroupName, setContactsCountByGroupName] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [pushNotificationToken, setPushNotificationToken] = useState("");
  const [chats, setChats] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channel, setChannel] = useState("");
  const [playlistItems, setPlaylistItems] = useState([]);
  const [isTalRadioDataLoaded, setIsTalRadioDataLoaded] = useState(false);
  const [currentYear, setCurrentYear] = useState(2023);
  const [configuration, setConfiguration] = useState({
    eventId: null,
    enableViewCertificate: false,
    enableTeamCreation: false,
    enableIdeaSubmission: false,
    enableIdeaCreation: false,
  });
  const [configurationId, setConfigurationId] = useState("");
  const [isMentor, setIsMentor] = useState(false);
  const [mentorPayload, setMentorPayload] = useState(null);
  const [mentorUnRegistered, setMentorUnRegistered] = useState(false);
  const [userScholarshipRequests, setUserScholarshipRequests] = useState([]);
  const [requestsResponses, setRequestsResponses] = useState([]);
  const defaultRequestTypes = JSON.stringify([
    { request_type: "career", creatorType: "mentee" },
    { request_type: "homework help", creatorType: "student" },
    { request_type: "internship", creatorType: "employer" },
    { request_type: "volunteering", creatorType: "volunteering organization" },
    { request_type: "scholarship" },
  ]);
  const updateMessagingToken = () => {
    if (firebase.messaging) {
      firebase.messaging
        .getToken({
          vapidKey: process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATION_VAPID_KEY,
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log("current token for client: ", currentToken);
            setPushNotificationToken(currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );

            // shows on the UI that permission is required
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // catch error while creating client token
        });

      firebase.messaging.onMessage = (payload) => {
        console.log("push notification received", payload);
      };
    }
  };

  useEffect(() => {
    if (pushNotificationToken) {
      let userUniqueId = "";
      const localAuthUser = localStorage.getItem("authUser");
      if (localAuthUser) {
        let parsedAuthUser = JSON.parse(localAuthUser);
        userUniqueId = parsedAuthUser.unique_id;
      }
      let saveTokenInfo = {
        token: pushNotificationToken,
        device: "web",
        userId: userUniqueId,
      };
      addPushNotificationDeviceToken(saveTokenInfo)
        .then((resp) => {
          console.log("token updated");
        })
        .catch((error) => {
          console.log("token update error", error.message);
        });
    }
  }, [pushNotificationToken]);

  useEffect(() => {
    const authUserInfo = localStorage.getItem("authUser");

    let search = window.location.search;
    let params = new URLSearchParams(search);
    const audioRoomId = params.get("audioRoomId");

    if (authUserInfo && audioRoomId) {
      const userData = JSON.parse(authUserInfo);

      window.location.replace(
        process.env.REACT_APP_CHAT_SERVER_BASE_URL +
          "/join/" +
          audioRoomId +
          "?userId=" +
          userData.unique_id
      );
    } else {
      axios.interceptors.response.use(
        (response) => {
          setIsWaitingForResponse(false);
          return response;
        },
        (error) => {
          setIsWaitingForResponse(false);
          if (error.response.status === 401) {
            if (
              error.response.hasOwnProperty("config") &&
              error.response.config.hasOwnProperty("url") &&
              (error.response.config.url.indexOf("/login") >= 0 ||
                error.response.config.url.indexOf("/change/user/password") >=
                  0 ||
                error.response.config.url.indexOf("/verifyotp") >= 0 ||
                error.response.config.url.indexOf("/forgotpassword") >= 0 ||
                error.response.config.url.indexOf("/user/feedback") >= 0 ||
                error.response.config.url.indexOf("/resendotp") >= 0)
            ) {
              return Promise.reject(error);
            }
            //localStorage.removeItem("authUser");
            //setAuthUser(null);
            toastr.warning("Please signup or login to complete this action.");
            //window.location = "/login";
          } else {
            return Promise.reject(error);
          }
          return Promise.reject(error);
        }
      );
    }

    getRegions()
      .then((response) => {
        setRegions(response.data.data);
      })
      .catch((error) => {
        toastr.error("Error fetching regions:" + error.message);
      });

    getCurrentTime()
      .then((resp) => {
        const year = new Date(resp).getFullYear();
        setCurrentYear(year);
      })
      .catch((e) => {
        console.log(e.message);
      });

    setAvatars([AVATAR_DONOR, AVATAR_DONEE]);
    setAvatar(AVATAR_DONOR);
  }, []);

  useEffect(() => {
    getUserLocaleInfo()
      .then((response) => {
        const userLocaleInfoLocal = response;
        setUserLocaleInfo(response);
        let tempRegion;
        regions.forEach((region) => {
          if (region._id === userLocaleInfoLocal.country_code) {
            tempRegion = region;
          } else if (region.isDefault) {
            tempRegion = region;
          }
        });
        setRegion(tempRegion);
      })
      .catch(() => {
        regions.forEach((region) => {
          if (region.isDefault) {
            setRegion(region);
          }
        });
      });
  }, [regions]);

  useEffect(() => {
    var parsedAuthUser;
    if (!authUser) {
      const localAuthUser = localStorage.getItem("authUser");
      if (localAuthUser) {
        parsedAuthUser = JSON.parse(localAuthUser);
        setAuthUser(parsedAuthUser);
      }
    } else {
      parsedAuthUser = authUser;
    }
    if (parsedAuthUser && parsedAuthUser.roles) {
      parsedAuthUser.roles.forEach((role) => {
        if (role === "admin") {
          setIsAdmin(true);
        } else if (role === "volunteerAdmin") {
          setIsVolunteer(true);
        } else if (role === "superAdmin") {
          setIsSuperAdmin(true);
        } else if (role === "finAdmin") {
          setIsFinAdmin(true);
        } else if (role === "talradioAdmin") {
          setIsTALRadioAdmin(true);
        }
      });
    }
    if (
      parsedAuthUser &&
      parsedAuthUser.institutionalRole &&
      parsedAuthUser.institutionalRole === "mentor"
    ) {
      setIsMentor(true);
    }
    if (parsedAuthUser && parsedAuthUser.institutionalRole) {
      if (parsedAuthUser.institutionalRole === "judge") {
        setIsJudge(true);
      } else if (parsedAuthUser.institutionalRole === "student") {
        setIsStudent(true);
      }
    }
    axios.interceptors.request.use(
      (config) => {
        if (config) {
          if (config.data) {
            if (config.data.dontShowLoader) {
            } else {
              setIsWaitingForResponse(true);
            }
          } else if (config.params) {
            if (config.params.dontShowLoader) {
            } else {
              setIsWaitingForResponse(true);
            }
          } else {
            setIsWaitingForResponse(false);
          }
        }
        if (config) {
          if (config.data) {
            if (config.data.dontSendToken) {
            } else if (parsedAuthUser) {
              config.headers["Authorization"] =
                "Bearer " + parsedAuthUser.token_detail.token;
            }
          } else if (config.params) {
            if (config.params.dontSendToken) {
            } else if (parsedAuthUser) {
              config.headers["Authorization"] =
                "Bearer " + parsedAuthUser.token_detail.token;
            }
          } else {
            if (parsedAuthUser) {
              config.headers["Authorization"] =
                "Bearer " + parsedAuthUser.token_detail.token;
            }
          }
        }
        return config;
      },
      (error) => {
        setIsWaitingForResponse(false);
        return Promise.reject(error);
      }
    );
    loadSummaryData();
    if (authUser !== null && authUser) {
      getUserNotifications(authUser.unique_id)
        .then((response) => {
          setUserNotifications(response);
        })
        .catch((error) => {
          toastr.error(error && error.data && error.data.message);
        });
    }
    if (authUser && authUser.unique_id) {
      getOrganizationList(authUser.unique_id)
        .then((resp) => {
          setOrgList(resp);
        })
        .catch((error) => {
          toastr.error(error.message);
        });

      getMyContacts(authUser.unique_id)
        .then((resp) => {
          setContacts(resp);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
      // TODO: Check if the following commented block of code is really required. If not, clean it up.
      // getTALDonorsContacts(
      //   authUser.unique_id,
      //   selectedOrganization && selectedOrganization._id
      // )
      //   .then((resp) => {
      //     setTalContacts(resp);
      //   })
      //   .catch((error) => {
      //     toastr.error(error.message);
      //   });
      getMyContactGroups(authUser.unique_id)
        .then((resp) => {
          setContactGroups(resp);
        })
        .catch((error) => {
          toastr.error(error.message);
        });
    }
    updateMessagingToken();
  }, [authUser]);

  useEffect(() => {
    getConfigurations()
      .then((response) => {
        if (response && response.data) {
          configuration.eventId = response.data[0].eventId;
          configuration.enableViewCertificate =
            response.data[0].enableViewCertificate;
          configuration.enableTeamCreation =
            response.data[0].enableTeamCreation;
          configuration.enableIdeaSubmission =
            response.data[0].enableIdeaSubmission;
          configuration.enableIdeaCreation =
            response.data[0].enableIdeaCreation;
          setConfiguration(configuration);
          setConfigurationId(response.data[0]._id);
        }
      })
      .catch((error) => {
        toastr.error("Problem in Configuration " + error.message);
      });
  }, [configuration]);

  const loadSummaryData = () => {
    if (authUser && authUser.unique_id) {
      getUserSummary(authUser.unique_id, defaultRequestTypes)
        .then((response) => {
          setUserSummary(response);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  return (
    <div style={{ background: "#f6f7fb" }}>
      <ThemeProvider theme={theme}>
        <appContext.Provider
          value={{
            authUser,
            setAuthUser,
            hideLoginButton,
            setHideLoginButton,
            userLocaleInfo,
            isSuperAdmin,
            isAdmin,
            isJudge,
            isStudent,
            isFinAdmin,
            isTALRadioAdmin,
            isVolunteer,
            regions,
            region,
            setRegion,
            paymentRegion,
            setPaymentRegion,
            avatars,
            avatar,
            setAvatar,
            donationDetails,
            setDonationDetails,
            categories,
            setCategories,
            userSummary,
            isVolunteerApplicationFlow,
            setIsVolunteerApplicationFlow,
            orgUserPayload,
            setOrgUserPayload,
            teamUserPayload,
            setTeamUserPayload,
            judgePayload,
            setJudgePayload,
            rjPayload,
            setRJPayload,
            orgUnRegisteredUser,
            setOrgUnRegisteredUser,
            teamUnRegisteredUser,
            setTeamUnRegisteredUser,
            judgeUnRegistered,
            setJudgeUnRegistered,
            unRegisteredRJ,
            setUnRegisteredRJ,
            generalUnRegisteredUser,
            setGeneralUnRegisteredUser,
            inviteGeneralUserPayload,
            setInviteGeneralUserPayload,
            userNotifications,
            setUserNotifications,
            orgList,
            setOrgList,
            talmediaLanguage,
            setTalmediaLanguage,
            currentDonationRequest,
            setCurrentDonationRequest,
            isWaitingForResponse,
            contacts,
            setContacts,
            talContacts,
            setTalContacts,
            emailContactType,
            setEmailContactType,
            selectedOrganization,
            setSelectedOrganization,
            contactGroups,
            setContactGroups,
            selectedContactsForEmailCampaign,
            setSelectedContactsForEmailCampaign,
            selectedTemplate,
            setSelectedTemplate,
            selectedContactGroups,
            setSelectedContactGroups,
            selectedGroups,
            chats,
            setChats,
            setSelectedGroups,
            contactsByGroupName,
            setContactsByGroupName,
            contactsCountByGroupName,
            setContactsCountByGroupName,
            currentDonationRequestName,
            setCurrentDonationRequestName,
            selectedUplodingFileContacts,
            setSelectedUplodingFileContacts,
            isPlaying,
            setIsPlaying,
            playlists,
            setPlaylists,
            channels,
            setChannels,
            channel,
            setChannel,
            playlistItems,
            setPlaylistItems,
            isTalRadioDataLoaded,
            setIsTalRadioDataLoaded,
            currentYear,
            setCurrentYear,
            configuration,
            setConfiguration,
            mentorPayload,
            setMentorPayload,
            mentorUnRegistered,
            setMentorUnRegistered,
            isMentor,
            setIsMentor,
            configurationId,
            setConfigurationId,
            userScholarshipRequests,
            setUserScholarshipRequests,
            requestsResponses,
            setRequestsResponses,
          }}
        >
          <Routes />
          <Loader
            isOpen={isWaitingForResponse}
            onClose={() => setIsWaitingForResponse(false)}
          />
        </appContext.Provider>
      </ThemeProvider>
    </div>
  );
};

export default App;
