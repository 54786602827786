import React, { useEffect, useContext, useState } from "react";
import Loader from "../common/Loader";
import jwt_decode from "jwt-decode";
import { Grid } from "@material-ui/core";
import { appContext } from "../../App";
import toastr from "toastr";
import { updateMentor } from "../../utils/api";
import Container from "@material-ui/core/Container";

const InviteMentor = (props) => {
  const { setMentorPayload, setMentorUnRegistered, authUser } =
    useContext(appContext);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  const validateMentor = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");
    const decoded = jwt_decode(token);
    setMentorPayload(decoded);
    if (decoded && decoded.mentor) {
      const { ideaId, user, email, mentorId } = decoded;
      updateMentor(mentorId, {
        status: "accepted",
        mentorId: decoded.mentor,
      })
        .then((response) => {
          if (response.data.statusCode === 200) {
            toastr.success("Mentor verified successfully and assigned to idea");
            if (authUser !== null && authUser.unique_id === decoded.mentor) {
              props.history.push("/");
            } else {
              props.history.push("/login");
            }
          }
        })
        .catch((error) => {
          toastr.error(
            error.response && error.response.data && error.response.data.message
          );
          if (authUser !== null && authUser.unique_id === decoded.judge) {
            props.history.push("/");
          } else {
            props.history.push("/login");
          }
        });
    } else {
      toastr.warning("Please complete the signup process to become a mentor.");
      props.history.push("/signup");
      setMentorUnRegistered(true);
    }
  };

  useEffect(() => {
    setIsComponentLoaded(true);
  }, []);

  useEffect(() => {
    isComponentLoaded && validateMentor();
  }, [isComponentLoaded]);

  return (
    <div className="myDonations-container">
      <Container maxWidth="lg">
        <Grid container>
          <Grid xs={9}>
            <Loader isOpen={true} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default InviteMentor;
