import React, { useContext, useEffect } from "react";
import moment from "moment";
import { Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Paper, Typography } from "@material-ui/core";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import "./Transformers.scss";
import { appContext } from "../../App";

function Challanges(props) {
  const { configuration, authUser } = useContext(appContext);
  useEffect(() => {
    if (authUser && !authUser.isProfileCompleted) {
      props.history.push("/transformers/ParticipantProfile");
    }
  }, [authUser]);
  return (
    <>
      <Header />
      <div className="desktop-menu">
        <SideMenu />
      </div>
      <Container maxWidth="xl" className="tal-container">
        <Box component="h3" mt={2} mb={2}>
          Challenge Page
        </Box>
        <Box component="h3" mt={2} mb={2}>
          {/* TALTransformers OTBI Social Entrepreneurship Pitchfest 2023 last date
          to submit ideas is Monday 25th December, 2023. */}
          {/* {moment(Date.parse(process.env.REACT_APP_FINAL_SUBMISSION_DATE))
            .endOf("day")
            .format(DATE_TIME_FORMAT_FULL)} */}
        </Box>

        <Container maxWidth="xl" className="bg-header">
          <Grid container>
            <Grid item xs={12}>
              <img src="../images/challenges-banner-2024.jpg" alt="" />
            </Grid>
            {/* /*<Grid item md={6}>
              <h1>TRANSFORMERS 2023</h1>
              <p>
                Global Youth Social Innovation Challenge
                <br />
                US | India | UK | Canada | Australia | World-wide
                <br />
                (Ages Upto 22 Years)
              </p>
            </Grid> */}
          </Grid>
        </Container>
        {authUser?.registeredForEvent ===
        "ATA-TALTransformers Entrepreneurship Pitch Fest 2024" ? (
          <Container className="chl-content">
            <Paper>
              <Grid className="page">
                <h4>
                  Calling all Changemakers, Innovators to participate in the
                  entrepreneurship Pitch Fest in collaboration with American
                  Telugu Association.
                </h4>
                <p>Evolve | Engage | Empower</p>

                {/* <h5>Evolve | Engage | Empower</h5> */}
                <ul>
                  <li>
                    Sign up your team at
                    <a
                      href="https://app.taltransformers.org/signup"
                      target="blank"
                    >
                      {" "}
                      www.taltransformers.org
                    </a>
                  </li>
                  <li>Ideate, Inspire and Implement</li>
                  <li>Last date to submit your ideas is on 31st May 2024.</li>
                </ul>
                <Grid className="talc-button">
                  <Typography component="p">
                    Get started with online videos, ebooks, and tutorials
                  </Typography>
                  <button className="labsbutton" color="#ffff">
                    <Link to="/transformers/LearningLabs">Learning Labs</Link>
                  </button>
                  {/* <Typography component="p">
                  Feeling ready to put together your social innovation idea?
                </Typography>
                <Button
                  className={
                    configuration.enableIdeaSubmission
                      ? "labsbutton"
                      : "labsbutton_disable"
                  }
                  disabled={
                    configuration && !configuration.enableIdeaSubmission
                  }
                >
                  <Link to="/transformers/StudentBlog"> Submit Blog</Link>
                </Button> */}
                  <Typography component="p">
                    Feeling ready to put together your social innovation idea?
                  </Typography>
                  <Button
                    className={
                      configuration.enableIdeaSubmission
                        ? "labsbutton"
                        : "labsbutton_disable"
                    }
                    disabled={
                      configuration && !configuration.enableIdeaSubmission
                    }
                  >
                    <Link to="/transformers/SubmitIdea"> Submit Idea</Link>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        ) : authUser?.registeredForEvent ===
          "TALTransformers Global Youth Social Innovation Challenge 2024 - Climate Tech" ? (
          <Container className="chl-content">
            <Paper>
              <Grid className="page">
                <h4>
                  Calling all Young Changemakers, Innovators (age between 12-21
                  years) to participate in the youth driven Global Youth Social
                  Innovation Challenge 2024.
                </h4>
                <p>Evolve | Engage | Empower</p>
                <ul>
                  <li>
                    Sign up your team at
                    <a
                      href="https://app.taltransformers.org/signup"
                      target="blank"
                    >
                      {" "}
                      www.taltransformers.org
                    </a>
                  </li>
                  <li>Ideate, Inspire and Implement</li>
                  <li>
                    Theme is on Climate Action. (Can submit the ideas on Zero
                    Waste, Biological diversity, Environment, save the oceans
                    etc.)
                  </li>
                  <li>Last date to submit your ideas is on 30th April 2024.</li>
                </ul>
                <Grid className="talc-button">
                  <Typography component="p">
                    Get started with online videos, ebooks, and tutorials
                  </Typography>
                  <button className="labsbutton" color="#ffff">
                    <Link to="/transformers/LearningLabs">Learning Labs</Link>
                  </button>
                  {/* <Typography component="p">
                  Feeling ready to put together your social innovation idea?
                </Typography>
                <Button
                  className={
                    configuration.enableIdeaSubmission
                      ? "labsbutton"
                      : "labsbutton_disable"
                  }
                  disabled={
                    configuration && !configuration.enableIdeaSubmission
                  }
                >
                  <Link to="/transformers/StudentBlog"> Submit Blog</Link>
                </Button> */}
                  <Typography component="p">
                    Feeling ready to put together your social innovation idea?
                  </Typography>
                  <Button
                    className={
                      configuration.enableIdeaSubmission
                        ? "labsbutton"
                        : "labsbutton_disable"
                    }
                    disabled={
                      configuration && !configuration.enableIdeaSubmission
                    }
                  >
                    <Link to="/transformers/SubmitIdea"> Submit Idea</Link>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        ) : authUser?.registeredForEvent ===
          "TALTransformers Global Youth Social Innovation Challenge on Health Tech" ? (
          <Container className="chl-content">
            <Paper>
              <Grid className="page">
                <h4>
                  Calling all Young Changemakers, Innovators (age between 12-21
                  years) to participate in the youth driven Global Youth Social
                  Innovation Challenge 2024 on Health Tech.
                </h4>
                <p>Evolve | Engage | Empower</p>
                <ul>
                  <li>
                    Sign up your team at
                    <a
                      href="https://app.taltransformers.org/signup"
                      target="blank"
                    >
                      {" "}
                      www.taltransformers.org
                    </a>
                  </li>
                  <li>Ideate, Inspire and Implement</li>
                  <li>
                    Theme of the month is Health Tech: Can submit the ideas on
                    Telemedicine and Remote Healthcare Solutions; Digital Health
                    Platforms and Apps; Medical Wearables and IoT in Healthcare;
                    Health Data Analytics & AI in Medicine; Health Monitoring
                    and Diagnostics; Any other ideas related to Healthcare.
                  </li>
                  <li>Last date to submit your ideas is on 31st May 2024.</li>
                </ul>
                <Grid className="talc-button">
                  <Typography component="p">
                    Get started with online videos, ebooks, and tutorials
                  </Typography>
                  <button className="labsbutton" color="#ffff">
                    <Link to="/transformers/LearningLabs">Learning Labs</Link>
                  </button>
                  {/* <Typography component="p">
                Feeling ready to put together your social innovation idea?
              </Typography>
              <Button
                className={
                  configuration.enableIdeaSubmission
                    ? "labsbutton"
                    : "labsbutton_disable"
                }
                disabled={
                  configuration && !configuration.enableIdeaSubmission
                }
              >
                <Link to="/transformers/StudentBlog"> Submit Blog</Link>
              </Button> */}
                  <Typography component="p">
                    Feeling ready to put together your social innovation idea?
                  </Typography>
                  <Button
                    className={
                      configuration.enableIdeaSubmission
                        ? "labsbutton"
                        : "labsbutton_disable"
                    }
                    disabled={
                      configuration && !configuration.enableIdeaSubmission
                    }
                  >
                    <Link to="/transformers/SubmitIdea"> Submit Idea</Link>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        ) : authUser?.registeredForEvent ===
          "TTA TALTransformers Youth Social Entrepreneurship Pitch Fest 2024" ? (
          <Container className="chl-content">
            <Paper>
              <Grid className="page">
                <h4>
                  Calling all Young Changemakers, Innovators (age between 14-21
                  years) to participate in the youth driven social
                  entrepreneurship Pitch Fest in collaboration with Telangana
                  American Telugu Association (TTA).
                </h4>
                <p>Evolve | Engage | Empower</p>
                <ul>
                  <li>
                    Sign up your team at
                    <a
                      href="https://app.taltransformers.org/signup"
                      target="blank"
                    >
                      {" "}
                      www.taltransformers.org
                    </a>
                  </li>
                  <li>Ideate, Inspire and Implement</li>
                  <li>Last date to submit your ideas is on 30th April 2024.</li>
                </ul>
                <Grid className="talc-button">
                  <Typography component="p">
                    Get started with online videos, ebooks, and tutorials
                  </Typography>
                  <button className="labsbutton" color="#ffff">
                    <Link to="/transformers/LearningLabs">Learning Labs</Link>
                  </button>
                  {/* <Typography component="p">
                    Feeling ready to put together your social innovation idea?
                  </Typography>
                  <Button
                    className={
                      configuration.enableIdeaSubmission
                        ? "labsbutton"
                        : "labsbutton_disable"
                    }
                    disabled={
                      configuration && !configuration.enableIdeaSubmission
                    }
                  >
                    <Link to="/transformers/StudentBlog"> Submit Blog</Link>
                  </Button> */}
                  <Typography component="p">
                    Feeling ready to put together your social innovation idea?
                  </Typography>
                  <Button
                    className={
                      configuration.enableIdeaSubmission
                        ? "labsbutton"
                        : "labsbutton_disable"
                    }
                    disabled={
                      configuration && !configuration.enableIdeaSubmission
                    }
                  >
                    <Link to="/transformers/SubmitIdea"> Submit Idea</Link>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        ) : authUser?.registeredForEvent ===
          "TALTransformers Global Youth Social Innovation Challenge on Agri Tech" ? (
          <Container className="chl-content">
            <Paper>
              <Grid className="page">
                <h4>
                  Calling all Young Changemakers, Innovators (age between 12-21
                  years) to participate in the youth driven Global Youth Social
                  Innovation Challenge 2024 on Agri Tech.
                </h4>
                <p>Evolve | Engage | Empower</p>
                <ul>
                  <li>
                    Sign up your team at
                    <a
                      href="https://app.taltransformers.org/signup"
                      target="blank"
                    >
                      {" "}
                      www.taltransformers.org
                    </a>
                  </li>
                  <li>Ideate, Inspire and Implement</li>
                  <li>
                    Theme of the month is Agri Tech: Can submit the ideas
                    onPrecision Farming Technologies, Smart Irrigation, Water
                    Management, Sustainable Agriculture Practices and any other
                    ideas related to Agriculture.
                  </li>
                  <li>Last date to submit your ideas is on 30th June 2024.</li>
                </ul>
                <Grid className="talc-button">
                  <Typography component="p">
                    Get started with online videos, ebooks, and tutorials
                  </Typography>
                  <button className="labsbutton" color="#ffff">
                    <Link to="/transformers/LearningLabs">Learning Labs</Link>
                  </button>
                  {/* <Typography component="p">
                  Feeling ready to put together your social innovation idea?
                </Typography>
                <Button
                  className={
                    configuration.enableIdeaSubmission
                      ? "labsbutton"
                      : "labsbutton_disable"
                  }
                  disabled={
                    configuration && !configuration.enableIdeaSubmission
                  }
                >
                  <Link to="/transformers/StudentBlog"> Submit Blog</Link>
                </Button> */}
                  <Typography component="p">
                    Feeling ready to put together your social innovation idea?
                  </Typography>
                  <Button
                    className={
                      configuration.enableIdeaSubmission
                        ? "labsbutton"
                        : "labsbutton_disable"
                    }
                    disabled={
                      configuration && !configuration.enableIdeaSubmission
                    }
                  >
                    <Link to="/transformers/SubmitIdea"> Submit Idea</Link>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        ) : (
          <Container className="chl-content">
            <Paper>
              <Grid className="page">
                <h4>
                  We are calling all young innovators to participate in the
                  youth social innovation for sustainability Challenge.
                </h4>
                <p>Evolve | Engage | Empower</p>
                <ul>
                  <li>
                    Sign up your team at
                    <a
                      href="https://app.taltransformers.org/signup"
                      target="blank"
                    >
                      {" "}
                      www.taltransformers.org
                    </a>
                  </li>
                  {/* <li>Ideate, Innovate and Inspire</li> */}
                  <li>Ideate, Inspire and Implement</li>
                  <li>
                    Save your ideas on the platform and get mentor help if
                    needed to fine tune your ideas
                  </li>
                </ul>
                <Grid className="oderlist">
                  <p>
                    To believe in change is to commit to action. The
                    Transformers challenge provides a space for action.
                  </p>
                </Grid>
                <Grid className="talc-button">
                  <Typography component="p">
                    Get started with online videos, ebooks, and tutorials
                  </Typography>
                  <button className="labsbutton" color="#ffff">
                    <Link to="/transformers/LearningLabs">Learning Labs</Link>
                  </button>
                  {/* <Typography component="p">
                  Feeling ready to put together your social innovation idea?
                </Typography>
                <Button
                  className={
                    configuration.enableIdeaSubmission
                      ? "labsbutton"
                      : "labsbutton_disable"
                  }
                  disabled={
                    configuration && !configuration.enableIdeaSubmission
                  }
                >
                  <Link to="/transformers/StudentBlog"> Submit Blog</Link>
                </Button> */}
                  <Typography component="p">
                    Feeling ready to put together your social innovation idea?
                  </Typography>
                  <Button
                    className={
                      configuration.enableIdeaSubmission
                        ? "labsbutton"
                        : "labsbutton_disable"
                    }
                    disabled={
                      configuration && !configuration.enableIdeaSubmission
                    }
                  >
                    <Link to="/transformers/SubmitIdea"> Submit Idea</Link>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        )}
      </Container>
    </>
  );
}
export default Challanges;
